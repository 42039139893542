import {
    IonButton,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonText,
    IonCard,
    IonCardContent,
} from "@ionic/react";
import React from "react";
import "./styles.scss";
import { useHistory } from "react-router";
import ToolBar from "../../../../components/ToolBar";
import { chevronForwardOutline, personCircleOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import {
    selectAuthenticatedUserId,
    selectCanManageGroups,
    selectDependencyUser,
    selectGobUser,
} from "../../../../redux/slices/auth";
import { selectUsersById } from "../../../../redux/slices/entities/users";
import grupsicon from "../../../../assets/icons/GroupMmber.svg";
import { selectMeGroupsForIncidents } from "../../../../redux/slices/entities/groups";
import RedDot from "./../../../../assets/icons/dot_red.svg";
import CheckUserIncidents from "./../../../../assets/icons/CheckUserIncidents.svg";

import { selectIncidentsByGroup } from "../../../../redux/slices/entities/incidents";
import { selectGroupDetails } from "../MembersMap/selectors";
const Profile: React.FC = () => {
    /**
     * hooks
     */
    const history = useHistory();

    /**
     * shared state
     */
    const userId = useSelector(selectAuthenticatedUserId);
    const userInfo = useSelector(selectUsersById(userId));
    const group = useSelector(selectMeGroupsForIncidents);
    const canManageGroups = useSelector(selectCanManageGroups);
    const meGroups = useSelector(selectGroupDetails);
    const isGovernor = useSelector(selectGobUser);
    const isDependency = useSelector(selectDependencyUser);
    const incidents = useSelector(
        selectIncidentsByGroup(group.length ? group[0].group_id : null)
    ).filter((incident) => incident.user_id === userId);

    const hasGroup = group.length > 0;

    const incidentsSolved = incidents.filter(
        (incident) => incident.solved === true
    );
    const incidentsUnsolved = incidents.filter(
        (incident) => incident.solved === false
    );

    return (
        <IonPage>
            <ToolBar title="Mi perfil"></ToolBar>
            <IonContent className="porfile-content ion-padding-top">
                <IonGrid>
                    <IonRow className="ion-text-center">
                        <IonCol size="12">
                            <IonIcon
                                icon={personCircleOutline}
                                className="iconPorfile"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-text-center">
                        <IonCol size="12" className="center">
                            <IonText className="name">
                                {userInfo
                                    ? `${userInfo.name} ${userInfo.last_name}  
                                    ${
                                        userInfo.second_last_name
                                            ? `${userInfo.second_last_name}`
                                            : ""
                                    }`
                                    : "Información de usuario no disponible"}
                                <br />
                                <span className="infoSeccion">
                                    {userInfo ? (
                                        <>
                                            Tel:{" "}
                                            <span className="tel">
                                                {" "}
                                                {userInfo.cellphone} •&nbsp;
                                            </span>
                                            {userInfo.email ? (
                                                <span className="email">
                                                    {userInfo.email}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            <p className="direction">
                                                {userInfo.street}{" "}
                                                {userInfo.inside_number}{" "}
                                                {userInfo.zipcode}
                                            </p>
                                        </>
                                    ) : (
                                        "Información de contacto no disponible"
                                    )}
                                </span>
                            </IonText>
                        </IonCol>
                        <IonCol
                            size={`${
                                !isGovernor &&
                                canManageGroups &&
                                !isDependency &&
                                meGroups.length > 0
                                    ? "6"
                                    : "12"
                            }`}
                            className={`${
                                !isGovernor &&
                                !isDependency &&
                                canManageGroups &&
                                meGroups.length > 0
                                    ? "flex-row ion-justify-content-end ion-align-items-end"
                                    : ""
                            }`}>
                            <IonButton
                                mode="ios"
                                size="small"
                                onClick={() =>
                                    history.push("/app/profile/edit")
                                }
                                className="editPorfile">
                                Editar Mi Perfil
                            </IonButton>
                        </IonCol>
                        {!isGovernor &&
                            !isDependency &&
                            canManageGroups &&
                            meGroups.length > 0 && (
                                <IonCol className="flex-row ion-justify-content-start ion-align-items-start">
                                    <IonButton
                                        mode="ios"
                                        size="small"
                                        onClick={() =>
                                            history.push(
                                                "/app/profile/members-map"
                                            )
                                        }
                                        className="membersMap">
                                        Miembros en Mapa
                                    </IonButton>
                                </IonCol>
                            )}
                    </IonRow>

                    {!isDependency && !isGovernor && hasGroup ? (
                        <>
                            <IonRow className="ReportsList ion-text-center">
                                <IonCol>
                                    <IonLabel>Mis Reportes</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonLabel className="flex-row ion-justify-content-center ion-align-items-center">
                                        <IonIcon src={CheckUserIncidents} />
                                        <span>
                                            &nbsp; {incidentsSolved.length}
                                            &nbsp;
                                        </span>
                                        Atendidos
                                    </IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonLabel className="flex-row ion-justify-content-center ion-align-items-center">
                                        <IonIcon src={RedDot} />
                                        <span>
                                            &nbsp;
                                            {incidentsUnsolved.length}&nbsp;
                                        </span>
                                        Pendientes
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                            <div className="divider"></div>
                            <IonRow>
                                <IonCol
                                    size="12"
                                    className="iconGroup flex-row ion-justify-content-center ion-align-items-center">
                                    <IonIcon
                                        className="memerIcon "
                                        src={grupsicon}></IonIcon>

                                    <IonText className="TextsIconGroup">
                                        Grupo asignado
                                        <br />
                                        <span className="TextName">
                                            {group[0].name}
                                        </span>
                                        <br />
                                        <span>
                                            {group[0].total_members}{" "}
                                            Participantes
                                        </span>
                                    </IonText>
                                </IonCol>
                            </IonRow>

                            <div className="flex-row ion-justify-content-center ion-align-items-center">
                                <IonCard className="box-indicator served">
                                    <IonCardContent class="flex-column ion-justify-content-center ion-align-items-center">
                                        <IonText className="subtitle">
                                            Reportes
                                        </IonText>
                                        <IonText className="title">
                                            Atendidos
                                        </IonText>
                                        <IonText class="indicator">
                                            {incidentsSolved.length}
                                        </IonText>
                                        <IonButton
                                            className="buttonIncident"
                                            mode="ios"
                                            size="small"
                                            onClick={() =>
                                                history.push(
                                                    "/app/profile/incidets-Solved"
                                                )
                                            }>
                                            Ver en el mapa
                                        </IonButton>
                                    </IonCardContent>
                                </IonCard>

                                <IonCard className="box-indicator pedding">
                                    <IonCardContent class="flex-column ion-justify-content-center ion-align-items-center">
                                        <IonText className="subtitle">
                                            Reportes
                                        </IonText>
                                        <IonText className="title">
                                            Pendientes
                                        </IonText>
                                        <IonText class="indicator">
                                            {incidentsUnsolved.length}
                                        </IonText>
                                        <IonButton
                                            className="buttonIncident"
                                            mode="ios"
                                            size="small"
                                            onClick={() =>
                                                history.push(
                                                    "/app/profile/incidets-Unsolved"
                                                )
                                            }>
                                            Ver en el mapa
                                        </IonButton>
                                    </IonCardContent>
                                </IonCard>
                            </div>
                        </>
                    ) : (
                        <>
                            {isDependency && (
                                <IonCol className="flex-row ion-justify-content-center ion-align-items-start">
                                    <IonButton
                                        mode="ios"
                                        size="small"
                                        onClick={() =>
                                            history.push(
                                                "/app/profile/Create-Admin"
                                            )
                                        }
                                        className="CreateSS">
                                        Crear Super Administradores
                                    </IonButton>
                                </IonCol>
                            )}
                            <div className="flex-row ion-justify-content-center ion-align-items-center">
                                <IonLabel className="TextDanger ion-padding">
                                    Si no estás actualmente en un grupo o
                                    sientes que deberías estar en uno, te
                                    recomendamos que te pongas en contacto con
                                    el administrador.
                                </IonLabel>
                            </div>
                        </>
                    )}
                    <IonRow>
                        <IonCol size="12" className="list">
                            <IonItem
                                lines="none"
                                className="listInfo1"></IonItem>

                            <IonItem
                                mode="md"
                                lines="none"
                                onClick={() =>
                                    history.push("/app/profile/ChangePassword")
                                }
                                className="listInfo"
                                button>
                                <IonLabel className="label-text">
                                    Cambiar Contraseña
                                </IonLabel>
                                <IonIcon
                                    className="icon-color-change"
                                    icon={chevronForwardOutline}></IonIcon>
                            </IonItem>
                            <IonItem
                                mode="md"
                                lines="none"
                                onClick={() =>
                                    history.push("/app/profile/ChangePhone")
                                }
                                className="listInfo"
                                button>
                                <IonLabel className="label-text">
                                    Cambiar número de teléfono
                                </IonLabel>
                                <IonIcon
                                    className="icon-color-change"
                                    icon={chevronForwardOutline}></IonIcon>
                            </IonItem>

                            {isGovernor || isDependency ? (
                                <></>
                            ) : (
                                <IonItem
                                    mode="md"
                                    lines="none"
                                    onClick={() =>
                                        history.push(
                                            "/app/profile/delete-account"
                                        )
                                    }
                                    className="delete-item"
                                    button>
                                    <IonLabel>Eliminar Mi Cuenta</IonLabel>
                                    <IonIcon
                                        className="icon-color"
                                        icon={chevronForwardOutline}></IonIcon>
                                </IonItem>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="footer"></div>
            </IonContent>
        </IonPage>
    );
};

export default Profile;
