import { useEffect, useState } from "react";
import permissionContext from "./PermissionContext";
import { useHistory } from "react-router";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { PushNotifications } from "@capacitor/push-notifications";
import GlobalModal from "../../components/Modal/GlobalModal";
/**
 * Componente pide los permisoso de la applicacion
 */
const PermissionProvider = (props) => {
    ////////// HOOKS //////////
    const history = useHistory();
    ////////// SHARED STATE //////////
    const { children } = props;
    ////////// LOCAL STATE //////////
    const [LocationColor, setLocationColor] = useState("");
    const [NotificationColor, setNotificationColor] = useState("");
    const [showPermissionModal, setShowPermissionModal] = useState(false);

    /**
     *  Accion para checar los permisos de ubicacion y notificacion
     */
    useEffect(() => {
        const checkPermissions = async () => {
            let initialBootstrapping = localStorage.getItem(
                "initialBootstrapping"
            );
            if (!initialBootstrapping || initialBootstrapping === "false") {
                const locationPermissions =
                    await Geolocation.checkPermissions();

                if (Capacitor.getPlatform() === "web") {
                    setLocationColor(
                        locationPermissions.location === "granted"
                            ? "granted"
                            : "denied"
                    );
                } else {
                    let permisionRequest =
                        await Geolocation.requestPermissions();
                    let { location } = permisionRequest;
                    setLocationColor(
                        location === "granted"
                            ? "granted"
                            : location === "prompt"
                            ? "prompt"
                            : "denied"
                    );
                    const notificationResult =
                        await PushNotifications.requestPermissions();
                    setNotificationColor(
                        notificationResult.receive === "granted"
                            ? "granted"
                            : "denied"
                    );
                }
            }
            localStorage.setItem("initialBootstrapping", "true");
        };

        checkPermissions();
    }, []);

    ////////// HANDLERS //////////////

    /**
     *  Accion para ir a los permissos
     */
    const onGoPermissions = () => {
        ///////// LOGIC ///////////
        history.push("/app/permissions");
        setShowPermissionModal(false);
    };
    /**
     *  accion para cerrar
     */
    const onClose = () => {
        ///////// LOGIC ///////////
        setShowPermissionModal(false);
    };

    return (
        <permissionContext.Provider
            value={{
                showPermissionModal,
                setShowPermissionModal,
                LocationColor,
                NotificationColor,
            }}>
            <GlobalModal
                isOpen={showPermissionModal}
                title="Advertencia"
                header="Error al crear el incidente"
                text="Se han denegado los permisos necesarios para que la aplicación funcione correctamente. Por favor, active los permisos necesarios en la configuración de la aplicación."
                onGoPermissions={onGoPermissions}
                onClose={onClose}
            />
            {children}
        </permissionContext.Provider>
    );
};

export default PermissionProvider;
