import { combineReducers } from "@reduxjs/toolkit";
/*
import backgroundSlice from './slices/background';
import calendarSlice from './slices/calendar';
import auth from './slices/auth';
import materias from './slices/materias';
import groupsUI from './slices/groupsUI'
import reports from './slices/reports';
import professorModule from './slices/professorModule';
import noticesModule from './slices/noticesModule';
*/
import { entities } from "./slices/entities";
import auth from "./slices/auth";
import app from "./slices/app";
/*import settingsUISlice from './slices/settingsUI'
import usersUISlice from './slices/usersUI'

import noticesModalUISlice from './slices/noticesModalUI'
import studentsUI from './slices/studentsUI'
import scoresUI from './slices/scoresUI'
*/
export default combineReducers<any>({
    entities: entities,
    app: app,
    //settingsUI: settingsUISlice,
    //usersUI: usersUISlice,
    auth: auth,
    /*professors,
    professorModule: professorModule,
    background: backgroundSlice,
    calendar: calendarSlice,
    subjectsUI: materias,
    groupsUI,
    reportsUI: reports,
    noticesModule: noticesModule,
    noticesModalUI: noticesModalUISlice,
    studentsUI,
    scoresUI*/
});
