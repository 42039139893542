import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";


/**
 * create adapter for the states entity
 */
const statesAdapter = createEntityAdapter<any>({
    selectId: (states) => states.states_id,
    sortComparer: (statesA, statesB) => statesA.states_id - statesB.states_id,
})

/**
 * states slice definitions
 */
export const statesSlice = createSlice({
    name: "states",
    initialState: statesAdapter.getInitialState(),
    reducers: {
        addManystates: statesAdapter.addMany,
        addOnestates: statesAdapter.addOne,
        upsertOnestates: statesAdapter.upsertOne,
        upsertManystates: statesAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return statesAdapter.getInitialState()
        })
    }
})

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManystates, addOnestates, upsertOnestates, upsertManystates
} = statesSlice.actions

/**
 * export reducer autegenerated by toolkit
 */
export default statesSlice.reducer

//////////////////// SELECTORES //////////////////

const globalizedSelectors = statesAdapter.getSelectors((state: any) => state.entities.settings)

/** 
 * select for recover all states entities
 */
export const selectstatesEntities = (state: any) => globalizedSelectors.selectEntities(state)

/**
 * select for recover all states 
 */
export const selectAllstates = (state: any) => globalizedSelectors.selectAll(state)

/**
 * select for recover one states by id
 */
export const selectstatesById = (id: any) => (state :any ) => globalizedSelectors.selectById(state, id)
