import { createSelector } from "reselect";
import { selectAllUsers } from "./users";
import { selectMembershipsByGroup } from "./memberships";
import { selectAuthRole, selectAuthenticatedUserId } from "../auth";
import { selectMeGroups, selectMeGroupsForIncidents } from "./groups";
import { selectIncidentsWithCatalog } from "./incidents";
import { MEMBERSHIPS, ROLES } from "../../../constants";

/**
 * Colocar todos los selectores que usen mas un selector del slice de entities
 * para evitar referencias circulares
 */

/**
 * selector para recuperar usuarios con membrecías en un grupo
 */
export const selectUsersWithMembershipsByGroup = (groupId: number) =>
    createSelector(
        selectAllUsers,
        selectMembershipsByGroup(groupId),
        selectAuthRole,
        (allusers, membershipsOfGroup, authUser) => {
            if (authUser?.user_type == ROLES.ADMIN) {
                return membershipsOfGroup
                    .filter(
                        (membership: any) =>
                            membership.type !== MEMBERSHIPS.CREATOR
                    )
                    .map((membership: any) => {
                        return {
                            membership: membership,
                            user: allusers.find(
                                (user: any) =>
                                    user.user_id == membership.user_id
                            ),
                        };
                    });
            }
            return membershipsOfGroup.map((membership: any) => {
                return {
                    membership: membership,
                    user: allusers.find(
                        (user: any) => user.user_id == membership.user_id
                    ),
                };
            });
        }
    );

/**
 * selector para recuperar todos incidentes creado por el usuario logeado
 * en todos sus grupos activos
 */
export const selectIncidentsCreatedByMe = createSelector(
    selectAuthenticatedUserId,
    selectMeGroupsForIncidents,
    selectIncidentsWithCatalog,
    (userId, groupsForIncidents, allIncidentsWithCatalog) => {
        const incidentersCreatedByMe = allIncidentsWithCatalog.filter(
            (incidentWithCatalog) =>
                incidentWithCatalog.incident.user_id == userId
        );

        const meIncidents = incidentersCreatedByMe.filter(
            (incidentWithCatalog) =>
                groupsForIncidents.filter(
                    (group: any) =>
                        incidentWithCatalog.incident.group_id == group.group_id
                ).length > 0
        );

        return meIncidents;
    }
);

/**
 * selector para recuperar todos los incidentes de mis grupos
 */
export const selectIncidentsInMeGroups = createSelector(
    selectMeGroups,
    selectIncidentsWithCatalog,
    (meGroups, allIncidentsWithCatalog) => {
        const meIncidents = allIncidentsWithCatalog.filter(
            (incidentWithCatalog) =>
                meGroups.filter(
                    (group: any) =>
                        incidentWithCatalog.incident.group_id == group.group_id
                ).length > 0
        );

        return meIncidents;
    }
);

/**
 * Selector no terminado
 */
/*export const selectIncidentsForBottomSheet = (userId: number) => createSelector(
    selectAllIncidents,
    selectAllCatalogIncidents,
    (allIncidents, allIncidentsCatalog) => {

        let myincidents = allIncidents.filter(item => item.user_id == userId)

        return myincidents.map(incident => {

            return  {
                ...incident,
                catalog: allIncidentsCatalog.find((catalog: any) => incident.catalog_incident_id == catalog.catalog_incident_id)  
            }
        })
    }
)*/
