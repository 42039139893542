import { IonContent, IonPage, useIonAlert, useIonLoading } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import Map from "ol/Map";
import { useSelector } from "react-redux";
import { selectAuthenticatedUserId } from "../../../../../../redux/slices/auth";
import ToolBar from "../../../alerts/components/ToolBar";
import { selectIncidentsWithCatalogByGroup } from "../../../../../../redux/slices/entities/incidents";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { DateTime } from "luxon";
import Popup from "ol-popup";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { OSM } from "ol/source";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import CircleStyle from "ol/style/Circle";
import { Fill, Icon, Style } from "ol/style";
import RedDot from "./../../../../../../assets/icons/dot_red.svg";
import GreenDot from "./../../../../../../assets/icons/dot_green.svg";
import { useParams } from "react-router";
import { selectUsersWithMembershipsByGroup } from "../../../../../../redux/slices/entities/selectors";

const MapSolvedGroup: React.FC = () => {
    /**
     * Local state
     */
    const mapRef = useRef<HTMLDivElement | null>(null);
    const [location, setLocation] = useState({
        latitude: 0,
        longitude: 0,
    });
    const [popupState, setPopupState] = useState({
        isVisible: false,
        incidentData: null,
    });
    let updateIncident = null;
    /**
     * shared state
     */
    const userId = useSelector(selectAuthenticatedUserId);
    const { id }: any = useParams();
    const catalogIncidents = useSelector(selectIncidentsWithCatalogByGroup(id));
    const members = useSelector(selectUsersWithMembershipsByGroup(id));
    /**
     * Filter solved
     */
    const filteredIncidentsWithMembers = catalogIncidents
        .map((incident) => {
            const creatorMembership = members.find(
                (member) => member.user.user_id === incident.incident.user_id
            );
            if (creatorMembership) {
                return {
                    ...incident,
                    creator: creatorMembership.user,
                };
            } else {
                return {
                    ...incident,
                    creator: null,
                };
            }
        })
        .filter((incident) => incident.incident.solved === true);

    useEffect(() => {
        const getLocaion = async () => {
            const locationPermissions = await Geolocation.checkPermissions();

            if (Capacitor.getPlatform() == "web") {
                if (locationPermissions.location == "denied") {
                    return;
                }
            } else {
                if (locationPermissions.location != "granted") {
                    let permisionReuqest =
                        await Geolocation.requestPermissions();

                    let { location } = permisionReuqest;

                    if (location == "denied") {
                        return;
                    }
                }
            }
        };

        getLocaion();
    }, []);
    /**
     * cordenadas de durango
     */
    const durangoCoordinates = {
        latitude: 24.0228,
        longitude: -104.6676,
    };

    const handlerClickMap = (event: any, map: any, popup: any) => {
        if (!popupState.isVisible) {
            popup.hide();
            setPopupState({ isVisible: false, incidentData: null });
        }
        const features = map.getFeaturesAtPixel(event.pixel);
        if (features && features.length > 0) {
            const feature = features[0];
            if (feature.get("data")) {
                const coordinate = event.coordinate;
                const incidentData = feature.get("data");
                const content = `
                <div style="background: #fff; border-radius:20px; width:350px">
                    <div style="text-align:center">
                        <div
                            style="background: ${
                                incidentData.catalog.color
                            }; color: #fff;     border-top-right-radius: 20px;
                            border-top-left-radius: 20px;"
                        >
                            <ion-text style="font-weight: 800">
                                ${incidentData.catalog.title.charAt(0)}
                                ${incidentData.incident.incident_id} •
                            </ion-text>
                            <ion-text class="name-user">
                                 ${incidentData.creator?.name}  ${
                    incidentData.creator?.last_name
                }
                            </ion-text>
                        </div>
                    </div>
                    <div style="display: flex; margin-top: 10px; margin-bottom: 10px">
                        <div style="    flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
                        width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
                        max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%); display: flex; align-items:center;
                        justify-content: center; margin-left: 10px; border-radius: 100%; margin-right: 10px;">
                        <div style="border-radius: 100%; width: 85px; height: 85px;
                        display: flex; align-items:center;
                        justify-content: center;
                        background:${incidentData.catalog.color} 
                        ">
                            <ion-icon
                                style="width: 55px;
                                height: 55px;                      
                                "
                                src=${
                                    "assets/icon/" +
                                    incidentData.catalog.icon +
                                    "_disabled.svg"
                                }
                            ></ion-icon>
                            </div>
                        </div>
                        <div style="    flex: 0 0 calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
                        width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
                        max-width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%); margin-bottom: 10px">
                            <ion-label style="color: #8D82B7;
                            font-family: Noto Sans;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;">
                                ${DateTime.fromSQL(
                                    incidentData.incident.created_at
                                )
                                    .setLocale("es")
                                    .toLocaleString(DateTime.DATE_MED)}
                            </ion-label>
                            <ion-label style="color: #8D82B7;
                            font-family: Noto Sans;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;">
                                
                                •
                                ${DateTime.fromSQL(
                                    incidentData.incident.created_at
                                )
                                    .setLocale("es")
                                    .toLocaleString({
                                        hour: "numeric",
                                        minute: "2-digit",
                                        hour12: true,
                                    })}
                            </ion-label>
                            <div>
                                <ion-label
                                    style="color: ${
                                        incidentData.catalog.color
                                    }; font-family: Noto Sans;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 700;"
                                >
                                    ${incidentData.catalog.title}
                                </ion-label>
                            </div>
                            <div>
                                <ion-label style="color: #8D82B7;
                                font-family: Noto Sans;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;">
                                    ${incidentData.incident?.address}
                                </ion-label>
                            </div>
                        </div>
                    </div>
    
                <div class="ion-row">
                    <div  style="flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                    width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                    max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%); text-align: center; color: #8D82B7;
                    font-family: Noto Sans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400; margin-bottom: 10px">
                        ${
                            incidentData.incident.description
                                ? incidentData.incident.description
                                : ""
                        }
                    </div>
    
                </div>
                <div class="ion-row flex-row ion-justify-content-center">
                    ${
                        !incidentData.incident.solved &&
                        userId === incidentData.user.user_id
                            ? `
                            <div style="flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                            width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                            max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);     align-items: center !important;     display: flex;
                            flex-direction: row;     justify-content: center !important;     margin-bottom: 15px;">
                            <button
                            type="button"
                            style="width: 130px; height: 34px; border: solid; border-radius: 6px; border-color: #00c483; color: #00c483; background: transparent;"
                            id="atendidoButton${incidentData.incident.incident_id}"
                        >
                            Atendido
                        </button>
    
                    </div>
                    
                    </div>
    
                    `
                            : ""
                    }
                    <div class="ion-row flex-row ion-justify-content-center" style=" width: 100%;">
                    <div style="flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                    width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                    max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);     align-items: center !important;     display: flex;
                    flex-direction: row;justify-content: center !important;
                    background: ${
                        incidentData.incident.solved ? "#8AD44F" : "#FF1F8A"
                    };
                    color: #fff;
                    font-family: Noto-Sans;
                    font-size: 18px;
                    font-weight: 700;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;">         
                    ${
                        incidentData.incident.solved
                            ? "Incidente Atendido"
                            : "Incidente Pendiente"
                    }          
                    </div>
                </div>
                
                `;

                if (!popupState.isVisible) {
                    popup.show(coordinate, content);
                    setPopupState({ isVisible: true, incidentData });

                    const atendidoButton = document.getElementById(
                        "atendidoButton" + incidentData.incident.incident_id
                    ) as HTMLButtonElement;

                    atendidoButton?.addEventListener("click", () => {
                        popup.hide();
                        setPopupState({
                            isVisible: false,
                            incidentData: null,
                        });
                        updateIncident = feature.get("data");
                        updateIncident = {
                            ...updateIncident,
                            incident: {
                                ...updateIncident.incident,
                                solved: true,
                            },
                        };
                        feature.set("data", updateIncident);
                        feature.changed();
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (mapRef.current) {
            const vectorSource = new VectorSource();
            const vectorLayer = new VectorLayer({
                source: vectorSource,
            });
            const map = new Map({
                target: mapRef.current,
                layers: [
                    new TileLayer({
                        source: new OSM({
                            attributions: [],
                        }),
                    }),
                    vectorLayer,
                ],
                view: new View({
                    center: fromLonLat([
                        durangoCoordinates.longitude,
                        durangoCoordinates.latitude,
                    ]),
                    zoom: 7,
                }),
            });
            const popup = new Popup();
            map.removeOverlay(popup);
            map.addOverlay(popup);

            vectorSource.clear();
            vectorLayer.getSource()?.clear();
            map.render();
            map.renderSync();

            filteredIncidentsWithMembers.forEach((item) => {
                const iconFeature = new Feature({
                    geometry: new Point(
                        fromLonLat([
                            item.incident.longitude,
                            item.incident.latitude,
                        ])
                    ),
                    data: item,
                });

                const circleStyle = new Style({
                    image: new CircleStyle({
                        radius: 30,
                        fill: new Fill({
                            color: item.catalog.color,
                        }),
                        displacement: [0, 17.5],
                    }),
                });
                const iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        anchorXUnits: "fraction",
                        anchorYUnits: "fraction",
                        width: 35,
                        height: 35,
                        src:
                            "assets/icon/" +
                            item.catalog.icon +
                            "_disabled.svg",
                    }),
                });
                const combinedStyle = [circleStyle, iconStyle];

                iconFeature.setStyle(combinedStyle);
                vectorSource.addFeature(iconFeature);

                const iconStateFeature = new Feature({
                    geometry: new Point(
                        fromLonLat([
                            item.incident.longitude,
                            item.incident.latitude,
                        ])
                    ),
                });
                const iconStateStyle = new Style({
                    image: new Icon({
                        anchor: [-8, 28],
                        anchorXUnits: "pixels",
                        anchorYUnits: "pixels",
                        width: 18,
                        height: 18,
                        src: item.incident.solved ? GreenDot : RedDot,
                    }),
                });
                iconStateFeature.setStyle(iconStateStyle);
                vectorSource.addFeature(iconStateFeature);
            });
            const clickHandler = (event: any) =>
                handlerClickMap(event, map, popup);

            map.un("click", clickHandler);

            map.on("click", clickHandler);
        }
    }, [location, filteredIncidentsWithMembers, popupState.isVisible]);

    return (
        <IonPage>
            <ToolBar title="Atendidos" />
            <IonContent>
                <div
                    ref={mapRef}
                    id="map"
                    className="map"
                    style={{ width: "100%", height: "100%" }}
                />
            </IonContent>
        </IonPage>
    );
};

export default MapSolvedGroup;
