import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";


/**
 * create adapter for the countries entity
 */
const countriesAdapter = createEntityAdapter<any>({
    selectId: (countries) => countries.countries_id,
    sortComparer: (countriesA, countriesB) => countriesA.countries_id - countriesB.countries_id,
})

/**
 * countries slice definitions
 */
export const countriesSlice = createSlice({
    name: "countries",
    initialState: countriesAdapter.getInitialState(),
    reducers: {
        addManycountries: countriesAdapter.addMany,
        addOnecountries: countriesAdapter.addOne,
        upsertOnecountries: countriesAdapter.upsertOne,
        upsertManycountries: countriesAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return countriesAdapter.getInitialState()
        })
    }
})

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManycountries, addOnecountries, upsertOnecountries, upsertManycountries
} = countriesSlice.actions

/**
 * export reducer autegenerated by toolkit
 */
export default countriesSlice.reducer

//////////////////// SELECTORES //////////////////

const globalizedSelectors = countriesAdapter.getSelectors((state: any) => state.entities.settings)

/** 
 * select for recover all countries entities
 */
export const selectcountriesEntities = (state: any) => globalizedSelectors.selectEntities(state)

/**
 * select for recover all countries 
 */
export const selectAllcountries = (state: any) => globalizedSelectors.selectAll(state)

/**
 * select for recover one countries by id
 */
export const selectcountriesById = (id: any) => (state :any ) => globalizedSelectors.selectById(state, id)
