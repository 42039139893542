import { createSelector } from "@reduxjs/toolkit";
import { selectMeGroups } from "../../../../../../redux/slices/entities/groups";
import { selectIncidentsInMeGroups } from "../../../../../../redux/slices/entities/selectors";
import { selectAllUsers } from "../../../../../../redux/slices/entities/users";
import { selectAuthenticatedUserId } from "../../../../../../redux/slices/auth";

export const selectViewModel = createSelector(
    selectIncidentsInMeGroups,
    selectAllUsers,
    selectMeGroups,
    selectAuthenticatedUserId,
    (incidentsWithCatalog, allUsers, userGroups, authenticatedUserId) => {
        return incidentsWithCatalog
            .filter((incidentWithCatalog) => {
                return (
                    incidentWithCatalog.incident.user_id ===
                        authenticatedUserId &&
                    incidentWithCatalog.incident.solved === true
                );
            })
            .map((incidentWithCatalog) => {
                return {
                    ...incidentWithCatalog,
                    user: allUsers.find(
                        (user: any) =>
                            user.user_id == incidentWithCatalog.incident.user_id
                    ),
                };
            });
    }
);
