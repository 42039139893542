/**
 * Enum que contiene los links a las diferentes stores
 */
export enum STORES_LINKS {
    PLAY_STORE = "https://play.google.com/store/apps/details?id=com.escudo.movil",
    APPEL_sTORE = "https://apps.apple.com/mx/app/escudo-vecinal/id6471300684",
    APP_GALLERY_STORE = "https://appgallery.huawei.com/app/C109271959",
    WEB_STORE = "https://play.google.com/store/apps/details?id=com.escudo.movil",
}

/**
 * Dias extra despues del dia fin del parcial
 */
export const APP_EXPIRE_TIME = 12;

/**
 * Roles del usuarios en el sistema
 */
export enum ROLES {
    SUPERADMIN = 1, //GOBER
    ADMIN = 2, //DEPENDENCY
    SUPERSHIELD = 3,
    SHIELD = 4,
}

/**
 * tipos de membrecias en un grupo
 */
export enum MEMBERSHIPS {
    ADMINISTRATOR = 2,
    GUEST = 3,
    CREATOR = 1,
}

/**
 * tipos de avisos
 */
export enum NOTICE_TYPE {
    SYSTEM = 1,
    NOTICE = 2,
    INCIDENT = 3,
}

/**
 * tipos de avisos
 */
export enum INCIDENT_PRIORITY {
    HIGH = 1,
    MEDIUM = 2,
    LOW = 3,
}
