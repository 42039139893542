import { useEffect, useState } from "react";
import Map from "ol/Map";
import View from "ol/View";
import OSM from "ol/source/OSM";
import { fromLonLat, toLonLat } from "ol/proj";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Icon, Style } from "ol/style.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import image from "./marker.png";
import { IonCard, IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import "./MapStyles.scss";

const MapComponent: React.FC<any> = ({
    latitude,
    longitude,
    onChangeLocation,
}) => {
    const [address, setAddress] = useState([]);

    useEffect(() => {
        const user = new Feature({
            geometry: new Point(fromLonLat([longitude, latitude])),
        });

        user.setStyle(
            new Style({
                image: new Icon({
                    anchor: [0.5, 1],
                    anchorXUnits: "fraction",
                    anchorYUnits: "fraction",
                    width: 40,
                    height: 40,
                    src: image,
                }),
            })
        );

        const vectorSource = new VectorSource({
            features: [user],
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        const map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM({
                        attributions: [],
                    }),
                }),
                vectorLayer,
            ],

            target: "map",
            view: new View({
                center: fromLonLat([longitude, latitude]),
                zoom: 15,
            }),
        });
        map.on("click", (event) => {
            const clickedCoord = map.getEventCoordinate(event.originalEvent);
            const lonLat = toLonLat(clickedCoord);
            user.setGeometry(new Point(fromLonLat(lonLat)));

            onChangeLocation({
                latitude: lonLat[1],
                longitude: lonLat[0],
            });

            getNominatimAddress(lonLat[1], lonLat[0]);
        });

        getNominatimAddress(latitude, longitude);
    }, []);

    const getNominatimAddress = (latitude: any, longitude: any) => {
        const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                const address = data.display_name;
                setAddress(address);
            });
    };

    return (
        <IonCard className="flex-row ion-text-center map-class">
            <IonGrid className="grid-map">
                <IonRow>
                    <IonCol className="map-col">
                        <IonText className="map-title">{address}</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <div
                        id="map"
                        style={{ width: "100%", height: "400px" }}></div>
                </IonRow>
            </IonGrid>
        </IonCard>
    );
};

export default MapComponent;
