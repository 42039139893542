import React from "react";
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
} from "@ionic/react";

import arrowBackButton from "../../../../../../assets/icons/arrow-back-button.svg";
import userProfile from "../../../../../../assets/icons/user-profile.svg";
import bell from "../../../../../../assets/icons/bell.svg";
import { selectViewModel } from "./../../../Notifications/selectors";

import "./style.scss";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

const ToolBar: React.FC<any> = ({ title, onBackButton, backbuttonIcon }) => {
    const history = useHistory();

    const allNotices = useSelector(selectViewModel);

    const ActiveNotices = allNotices.filter(
        (item) => item.notification.aware === false
    ).length;

    /**
     * handler backbutton
     */
    const handlerBackButton = () => {
        if (onBackButton) {
            onBackButton();
        } else {
            history.goBack();
        }
    };

    return (
        <IonHeader className="shield-tool-bar ion-no-border">
            <IonToolbar className="ion-text-center header">
                <IonButtons slot="start">
                    <IonButton
                        className="back-button"
                        onClick={handlerBackButton}>
                        <IonIcon
                            className="toolbar-icon"
                            slot="icon-only"
                            icon={
                                backbuttonIcon
                                    ? backbuttonIcon
                                    : arrowBackButton
                            }></IonIcon>
                    </IonButton>
                </IonButtons>
                <IonTitle className="title">{title}</IonTitle>
                <IonButtons slot="end">
                    <IonButton>
                        <IonIcon slot="icon-only" icon={bell}></IonIcon>
                        {ActiveNotices > 0 && (
                            <IonBadge class="cart-badge icon-label">
                                {ActiveNotices}
                            </IonBadge>
                        )}
                    </IonButton>
                    <IonButton>
                        <IonIcon slot="icon-only" icon={userProfile}></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};

export default ToolBar;
