import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import Map from "ol/Map";
import View from "ol/View";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Icon, Style, Text, Fill } from "ol/style.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { useSelector } from "react-redux";
import Popup from "ol-popup";
import { selectAuthenticatedUserId } from "../../../../redux/slices/auth";
import { MEMBERSHIPS } from "../../../../constants";
import ToolBar from "../../../../components/ToolBar";
import { useParams } from "react-router";
import { selectgroupsById } from "../../../../redux/slices/entities/groups";
import { selectUsersWithMembershipsByGroup } from "../../../../redux/slices/entities/selectors";

const MembersGroupMap: React.FC = () => {
    const userId = useSelector(selectAuthenticatedUserId);
    const { id }: any = useParams();
    const group = useSelector(selectgroupsById(id));
    const members = useSelector(selectUsersWithMembershipsByGroup(id));
    const adminGroup = members.find(
        (admin: any) => admin.membership.type == MEMBERSHIPS.ADMINISTRATOR
    );

    const mapRef = useRef<HTMLDivElement | null>(null);
    const [popupState, setPopupState] = useState({
        isVisible: false,
    });

    const UserCoordinates = {
        latitude: adminGroup?.user.latitude,
        longitude: adminGroup?.user.longitude,
    };

    const handlerClickMap = (event: any, map: any, popup: any) => {
        if (!popupState.isVisible) {
            popup.hide();
            setPopupState({ isVisible: false });
        }
        const features = map.getFeaturesAtPixel(event.pixel);

        if (features && features.length > 0) {
            const feature = features[0];
            if (feature.get("data")) {
                const coordinate = event.coordinate;
                const group = feature.get("group");
                const member = feature.get("data");
                const colorUser = feature.get("color");
                const imgUser = feature.get("image");
                const userType = feature.get("userT");

                const content = `
            <div style="background: #fff; border-radius:20px; width:350px">
                <div style="text-align:center">
                    <div
                        style="background:${colorUser};  color: #fff;   
                        border-top-right-radius: 20px;
                        border-top-left-radius: 20px;"
                    >
                        <ion-text style="font-weight: 800">
                            ${group}
                        </ion-text>
                    </div>
                </div>
                <div style="display: flex; margin-top: 10px; margin-bottom: 10px">
                    <div style="    flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
                    width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
                    max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);">
                        <ion-icon
                            style="width: 70px;
                            height: 70px;
                            margin-left: 10px
                            "
                            src=${imgUser}
                        ></ion-icon>
                    </div>

                    <div style="    flex: 0 0 calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
                    width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
                    max-width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%); margin-bottom: 10px;">
                        
                    <div>
                        <ion-label
                            style="color: ${colorUser}; font-family: Noto Sans;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;"
                        >
                                ${member.user?.name} ${
                    member.user?.last_name
                }  ${
                    member.user?.second_last_name == null
                        ? ""
                        : member.user?.second_last_name
                }
                        </ion-label>
                        <div>
                        <ion-label
                        style="color: #8D82B7;
                        font-family: Noto Sans;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;"
                    >
                        ${userType}
                    </ion-label>
                    </div>
                        </div>
                        <ion-label style="color: #8D82B7;
                        font-family: Noto Sans;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;">
                        Tel.:  ${member.user.cellphone}
                        <div style="margin-right: 10px" >
                            <ion-label style="color: #8D82B7;
                            font-family: Noto Sans;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;">
                            Domicilio:  Calle  ${member.user.street} 
                                        No. ${member.user?.outside_number}
                                        ${
                                            member.user?.inside_number == null
                                                ? ""
                                                : member.user?.inside_number
                                        }
                                        ,  Colonia ${member.user?.colony}, 
                                        C.p. ${member.user?.zipcode} 
                            </ion-label>
                        </div>
                    </div>
                </div>

            `;

                if (!popupState.isVisible) {
                    popup.show(coordinate, content);
                    setPopupState({ isVisible: true });
                }
            }
        }
    };

    useEffect(() => {
        if (mapRef.current) {
            const vectorSource = new VectorSource();
            const vectorLayer = new VectorLayer({
                source: vectorSource,
            });

            const map = new Map({
                target: mapRef.current,
                layers: [
                    new TileLayer({
                        source: new OSM({
                            attributions: [],
                        }),
                    }),
                    vectorLayer,
                ],
                view: new View({
                    center: fromLonLat([
                        UserCoordinates.longitude,
                        UserCoordinates.latitude,
                    ]),
                    zoom: 17,
                }),
            });

            const popup = new Popup();
            map.removeOverlay(popup);
            map.addOverlay(popup);

            vectorSource.clear();
            vectorLayer.getSource()?.clear();
            map.render();
            map.renderSync();

            members.forEach((item) => {
                const itsMe = item.user.user_id == userId;
                let imgUser = null;
                let colorUser = null;
                let nameGroup = null;
                let userType = null;

                if (itsMe) {
                    imgUser = "assets/icon/me.svg";
                    colorUser = "#32D29D";
                    nameGroup = "Super Administrador";
                    userType = "Creador";
                } else {
                    nameGroup = group.name;
                    switch (item.membership.type) {
                        case MEMBERSHIPS.ADMINISTRATOR:
                            imgUser = "assets/icon/admin.svg";
                            colorUser = "#FF1F8A";
                            userType = "Administrador";
                            break;
                        case MEMBERSHIPS.GUEST:
                            imgUser = "assets/icon/user.svg";
                            colorUser = "#8400E6";
                            userType = "Invitado";
                            break;
                        default:
                            imgUser = "assets/icon/user.svg";
                            colorUser = "#8400E6";
                            userType = "Invitado";
                            break;
                    }
                }
                const iconFeature = new Feature({
                    geometry: new Point(
                        fromLonLat([item.user.longitude, item.user.latitude])
                    ),
                    group: nameGroup,
                    data: item,
                    color: colorUser,
                    image: imgUser,
                    userT: userType,
                });

                const iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        anchorXUnits: "fraction",
                        anchorYUnits: "fraction",
                        width: 40,
                        height: 40,
                        src: imgUser,
                    }),
                    text: new Text({
                        text: nameGroup,
                        offsetY: -45,
                        fill: new Fill({
                            color: colorUser,
                        }),
                        font: "bold 12px Noto Sans",
                    }),
                });
                iconFeature.setStyle(iconStyle);
                vectorSource.addFeature(iconFeature);
            });

            const clickHandler = (event: any) =>
                handlerClickMap(event, map, popup);

            map.un("click", clickHandler);
            map.on("click", clickHandler);
        }
    }, [members, group, userId, popupState.isVisible]);

    return (
        <IonPage>
            <ToolBar title="Miembros del Grupo" />
            <IonContent>
                <div
                    ref={mapRef}
                    className="map"
                    style={{ width: "100%", height: "100%" }}
                />
            </IonContent>
        </IonPage>
    );
};

export default MembersGroupMap;
