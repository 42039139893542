import {
    IonButton,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonPage,
    useIonLoading,
    IonRouterLink,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    useIonRouter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./Login.scss";
import { eyeOffOutline, eyeOutline, callOutline } from "ionicons/icons";
import { useDispatch } from "react-redux";
import {
    login,
    selectIsUserLoged,
    selectLoginOperation,
} from "../../redux/slices/auth";
import { useSelector } from "react-redux";
import { useAppInfo, useFeedback } from "../../hooks";
import { useMaskito } from "@maskito/react";
import { Browser } from "@capacitor/browser";
import loginLogo from "./../../assets/icons/login-logo.svg";
import { useHistory, useLocation } from "react-router";
import { App } from "@capacitor/app";

const Login: React.FC = () => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    const history = useHistory();
    const { mode, version } = useAppInfo();
    const [present, disminss] = useIonLoading();

    /**
     * local state
     */
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    /**
     * Shared state
     */
    const loginStatus = useSelector(selectLoginOperation);
    const IsUserLoged = useSelector(selectIsUserLoged);

    /**
     * Determina si el usuario esta autenticado para
     * redirigirlo al home
     */
    useEffect(() => {
        if (IsUserLoged) {
            history.replace("/app/alerts");
        }
    }, []);

    /**
     * Muestra el la contraseña
     */
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    /**
     * proceso de login usando enter
     */
    const onHandlerLoginenter = (e: any) => {
        if (e.key === "Enter" && !isInValid()) {
            onHandlerLogin();
        }
    };

    /**
     * Proceso de login usando el boton
     */
    const onHandlerLogin = () => {
        present({
            message: "Por favor espere",
        });

        dispatch<any>(login({ username, password }))
            .unwrap()
            .then(() => {
                history.replace("/app/alerts");
            })
            .catch(({ feedback }: any) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            })
            .then(() => {
                setTimeout(() => {
                    disminss();
                }, 50);
            });
    };

    /**
     * halder the username changes
     */
    const onChangeUsername = (event: CustomEvent): void => {
        setUsername(event.detail.value);
    };

    /**
     * halder the password changes
     */
    const onInputPassword = (event: CustomEvent): void => {
        setPassword(event.detail.value);
    };

    /**
     * validacion del nombre de usuario
     */
    const validateUsername = () => {
        let isValid = username != "";

        return {
            isValid,
            message: "El nombre de usuarios es requerido",
        };
    };

    /**
     * validacion de la contraseña
     */
    const validatePassword = () => {
        let isValid = password != "";

        return {
            isValid,
            message: "La contraseña es requerido",
        };
    };

    /**
     * accion para recuperar cuenta
     */
    const onGoForgetAccount = () => {
        const openCapacitorSite = async () => {
            await Browser.open({
                url: process.env.REACT_APP_WEB_API + "/recuperar-cuenta",
            });
        };

        openCapacitorSite();
    };

    /**
     * accion para ir a terminos y condiciones
     */
    const onGoTerms = () => {
        const openCapacitorSite = async () => {
            await Browser.open({
                url: process.env.REACT_APP_WEB_API + "/terminos",
            });
        };

        openCapacitorSite();
    };

    /**
     * accion para ir a soporte
     */
    const onGoSup = () => {
        const openCapacitorSite = async () => {
            await Browser.open({
                url: process.env.REACT_APP_WEB_API + "/soporte",
            });
        };

        openCapacitorSite();
    };

    /**
     * determina si se puede precionar el boton de ingreso
     */
    const isInValid = () => {
        return !validateUsername().isValid || !validatePassword().isValid;
    };

    /**
     * mascks
     */
    const phoneMask = useMaskito({
        options: {
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        },
    });

    const ionRouter = useIonRouter();
    const location = useLocation();

    /**
     * Permite salir de la aplicacion al precionar el boton atras en android
     */
    useEffect(() => {
        document.addEventListener("ionBackButton", (ev: any) => {
            ev.detail.register(-1, () => {
                if (!ionRouter.canGoBack() || location?.pathname == "/login") {
                    App.exitApp();
                }
            });
        });
    }, [location]);

    return (
        <IonPage>
            <IonContent className="page-login">
                <div className="heading ion-padding-bottom">
                    <IonIcon src={loginLogo} className="brand-logo" />
                </div>
                <div className="content ion-padding-horizontal">
                    <div className="title ion-text-center">
                        ¡Bienvenido a la plataforma
                        <br />
                        que
                        <span className="titlespan">
                            {" "}
                            conecta a tu comunidad!
                        </span>
                    </div>
                    <div className="form-login">
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonItem className="CellInp">
                                        <IonInput
                                            onKeyUp={onHandlerLoginenter}
                                            labelPlacement="stacked"
                                            type="tel"
                                            placeholder="Ingresa tú número celular"
                                            name="username"
                                            value={username}
                                            label="Teléfono"
                                            onIonInput={onChangeUsername}
                                            disabled={loginStatus === "pending"}
                                            ref={async (phoneInput) => {
                                                if (phoneInput) {
                                                    const input =
                                                        await phoneInput.getInputElement();
                                                    phoneMask(input);
                                                }
                                            }}
                                        ></IonInput>
                                        <IonIcon
                                            className="icon"
                                            slot="end"
                                            icon={callOutline}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem className="passInp">
                                        <IonInput
                                            onKeyUp={onHandlerLoginenter}
                                            label="Contraseña"
                                            labelPlacement="stacked"
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder="Ingresa tú contraseña"
                                            name="password"
                                            value={password}
                                            onIonInput={onInputPassword}
                                        />
                                        <IonIcon
                                            className="icon"
                                            slot="end"
                                            icon={
                                                showPassword
                                                    ? eyeOutline
                                                    : eyeOffOutline
                                            }
                                            onClick={togglePasswordVisibility}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <div className=" ion-text-center">
                            <IonText
                                className="textForget ion-padding-top"
                                onClick={onGoForgetAccount}
                            >
                                Olvidé mi contraseña
                            </IonText>
                        </div>
                        <div className="ion-padding-top ion-text-center">
                            <IonButton
                                className="login-button center ion-text-capitalize"
                                onClick={onHandlerLogin}
                                disabled={isInValid()}
                            >
                                Ingresar
                            </IonButton>
                        </div>
                        <div className="textRegister ion-padding-top">
                            <IonText>
                                ¿No tienes una cuenta?{" "}
                                <IonRouterLink routerLink="/register">
                                    <span>Regístrate Aquí</span>
                                </IonRouterLink>
                            </IonText>
                        </div>
                        <div className="flex-column footerText ion-justify-content-center ion-align-items-center">
                            <IonText className="textSup ion-padding-top">
                                ¿Tienes algun ploblema?{" "}
                                <div
                                    className="textSupportLink ion-padding-top"
                                    onClick={onGoSup}
                                >
                                    Obtén ayuda aquí
                                </div>
                            </IonText>
                            <IonText
                                className="terms ion-margin-top"
                                onClick={onGoTerms}
                            >
                                Términos de uso y Acuerdo de confidencialidad
                            </IonText>

                            <IonText className="version ion-margin-top">
                                {mode} {version}
                            </IonText>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Login;
