import {
    IonCard,
    IonCardContent,
    IonContent,
    IonFab,
    IonFabButton,
    IonFabList,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonPage,
    IonSearchbar,
    IonText,
} from "@ionic/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import HomeToolBar from "../../../../../../components/HomeToolBar";
import { add, alertCircle, people, personAdd, trashBin } from "ionicons/icons";
import { selectHaveIGroups } from "../../../../../../redux/slices/entities/groups";
import "./style.scss";
import groupIcon from "./../../../../../../assets/icons/group_icon.svg";
import { useHistory } from "react-router";
import anime from "animejs";
import {
    selectDependencyUser,
    selectGobUser,
} from "../../../../../../redux/slices/auth";
import userIcon from "./../../../../../../assets/icons/user-profile.svg";
import { selectAllMembreshipsActived } from "../../../../../../redux/slices/entities/memberships";
import { MEMBERSHIPS } from "../../../../../../constants";
import { selectGroupsWithCreator } from "./selectors";

const MeGroups: React.FC = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    /**
     * localstate
     */
    const [searchText, setSearchText] = useState("");
    /**
     * Shared state
     */
    const haveIGroups = useSelector(selectHaveIGroups);
    const isGovernor = useSelector(selectGobUser);
    const isDependency = useSelector(selectDependencyUser);
    const allMemberships = useSelector(selectAllMembreshipsActived);
    const groupsWithCreator = useSelector(selectGroupsWithCreator);
    /**
     * Accion para sacar todos los miembros
     */
    const totalMembers = groupsWithCreator.reduce(
        (total: any, group: any) => total + group.total_members,
        0
    );
    /**
     * Filtro de las membresias de creador
     */
    const creatorMemberships = allMemberships.filter(
        (membership) => membership.type === MEMBERSHIPS.CREATOR
    );
    /**
     * total de las membresias sin contar la del creador
     */
    const totalMembersWithoutCreators =
        totalMembers - creatorMemberships.length;
    /**
     * ir a la pagina para crear el grupo
     */
    const onGoCreateGroup = () => {
        history.push("/app/groups/create");
    };
    /**
     * ir a la pagina para crear el grupo con un invitado
     */
    const onGoCreateGroupExist = () => {
        history.push("/app/groups/create/user-exist");
    };

    /**
     * ir a la pagina de detalle de grupo
     */
    const onGoGroupDetail = (group: any) => {
        history.push("/app/groups/details/" + group.group_id);
    };

    /**
     * filtrar ls grupos por la barra de busqueda y por orden desendente
     */
    const filteredGroups = groupsWithCreator
        .filter((group: any) =>
            group.name.toLowerCase().includes(searchText.toLowerCase())
        )
        .sort((a: any, b: any) => {
            const dateA = new Date(a.created_at).getTime();
            const dateB = new Date(b.created_at).getTime();
            return dateB - dateA;
        });

    // Animación con animejs al cambiar el valor de la barra de búsqueda
    const animateSearchInput = (value: string) => {
        anime({
            targets: ".group_list",
            translateY: [0, 50],
            opacity: [1, 0],
            easing: "easeInOutQuad",
            duration: 500,
            complete: () => {
                setSearchText(value);
                anime({
                    targets: ".group_list",
                    translateY: [50, 0],
                    opacity: [0, 1],
                    easing: "easeInOutQuad",
                    duration: 500,
                });
            },
        });
    };

    // Función para animar el botón de añadir
    const animateAddButton = () => {
        anime({
            targets: ".fab-my-group",
            translateY: [0, -10],
            opacity: [0, 1],
            easing: "easeInOutSine",
            duration: 500,
        });
    };

    return (
        <IonPage className="me-groups-page">
            <HomeToolBar title="Grupos Creados"></HomeToolBar>
            <IonContent className="my-group">
                <IonSearchbar
                    debounce={1000}
                    className="search-bar"
                    mode="ios"
                    clearIcon={trashBin}
                    animated={true}
                    placeholder="Buscar grupo"
                    value={searchText}
                    onIonInput={(e) =>
                        animateSearchInput(e.detail.value!)
                    }></IonSearchbar>
                {haveIGroups && groupsWithCreator.length > 0 && (
                    <>
                        <IonCard mode="ios" className="Card-numbers">
                            <IonCardContent className="flex-row ion-justify-content-center">
                                <IonItem lines="none">
                                    <IonIcon
                                        slot="start"
                                        aria-hidden="true"
                                        icon={groupIcon}
                                    />
                                    <IonText className="Count-Members">
                                        Total de Grupos:{" "}
                                        <p>{groupsWithCreator.length}</p>
                                    </IonText>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonIcon
                                        slot="start"
                                        aria-hidden="true"
                                        src={userIcon}
                                    />
                                    {isGovernor ? (
                                        <IonText className="Count-Members">
                                            Total de Miembros:{" "}
                                            <p>
                                                {groupsWithCreator.reduce(
                                                    (total: any, group: any) =>
                                                        total +
                                                        group.total_members,
                                                    0
                                                )}
                                            </p>
                                        </IonText>
                                    ) : (
                                        <IonText className="Count-Members">
                                            Total de Miembros:{" "}
                                            <p>{totalMembersWithoutCreators}</p>
                                        </IonText>
                                    )}
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                    </>
                )}
                <IonList className="group_list" lines="none">
                    {!haveIGroups ? (
                        <div className="ion-margin-top ion-text-center flex-column not-group-container ion-justify-content-center ion-align-items-center">
                            <IonIcon
                                className="no-group-icon"
                                src={alertCircle}
                            />
                            <IonText className="ion-margin-top">
                                Aún no cuentas con ningún grupo
                            </IonText>
                        </div>
                    ) : filteredGroups.length > 0 ? (
                        filteredGroups.map((group: any) => (
                            <IonItem
                                detail={true}
                                onClick={() => onGoGroupDetail(group)}
                                key={group.group_id}
                                className="item-my-group">
                                <IonIcon
                                    aria-hidden="true"
                                    src={groupIcon}
                                    slot="start"
                                    className="icon-my-group"></IonIcon>
                                <IonLabel>
                                    <IonText className="text-my-group-asign">
                                        Grupo asignado
                                    </IonText>
                                    <IonText className="text-my-group-name">
                                        • {group.name}
                                    </IonText>
                                    <br />

                                    {isGovernor ? (
                                        <IonNote className="ion-text-wrap text-my-group-members">
                                            {group.total_members} participantes
                                        </IonNote>
                                    ) : (
                                        <IonNote className="ion-text-wrap text-my-group-members">
                                            {group.total_members -
                                                group.creator.length}{" "}
                                            participantes
                                        </IonNote>
                                    )}
                                </IonLabel>
                            </IonItem>
                        ))
                    ) : (
                        <div className="text-notfound-group ion-text-center">
                            <IonText className="notfound-message">
                                No se encontraron grupos.
                            </IonText>
                            <br />
                            <IonText className="notfound-subtext">
                                Intenta buscar con un término diferente.
                            </IonText>
                        </div>
                    )}
                </IonList>
                {!isGovernor && !isDependency ? (
                    <div className="fab-container">
                        <IonFab slot="fixed" vertical="bottom" horizontal="end">
                            <IonFabButton onClick={animateAddButton}>
                                <IonIcon icon={add} />
                            </IonFabButton>
                            <IonFabList side="top">
                                <IonFabButton
                                    mode="ios"
                                    onClick={onGoCreateGroup}
                                    className="fab-my-group"
                                    data-desc="Crea un grupo y administrador nuevo">
                                    <IonIcon icon={personAdd} />
                                </IonFabButton>
                                <IonFabButton
                                    mode="ios"
                                    onClick={onGoCreateGroupExist}
                                    className="fab-my-group"
                                    data-desc="Crea un grupo con un administrador de Escudo">
                                    <IonIcon icon={people} />
                                </IonFabButton>
                            </IonFabList>
                        </IonFab>
                    </div>
                ) : null}

                <div className="footer-my-group"></div>
            </IonContent>
        </IonPage>
    );
};

export default MeGroups;
