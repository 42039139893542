import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonItem,
    IonPage,
    IonRow,
    IonIcon,
    IonText,
    IonLabel,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
} from "@ionic/react";

import { locationOutline, notificationsOffOutline } from "ionicons/icons";
import "./index.scss";
import arrowBackButton from "./../../assets/icons/arrow-back-button.svg";

import {
    NativeSettings,
    AndroidSettings,
    IOSSettings,
} from "capacitor-native-settings";
import permissionContext from "../../providers/Permissions/PermissionContext";

const PermissionsPage: React.FC = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    const PemissionStatus = useContext(permissionContext);

    /**
     * local state
     */
    const [showMessage, setShowMessage] = useState(
        PemissionStatus.LocationColor === "disabled"
    );

    /**
     * local state
     */
    const onBackButton = () => {
        history.goBack();
    };

    /**
     * timer para mostrar el mensaje
     */
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    /**
     * accion para mostrar el mensaje
     */
    const handleshowMessage = async () => {
        if (PemissionStatus.LocationColor === "disabled") {
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 3000);
        }
    };

    const handleActivatePermissions = async () => {
        const platform = (window as any)?.Capacitor?.platform;

        if (platform === "android") {
            await NativeSettings.openAndroid({
                option: AndroidSettings.ApplicationDetails,
            });
        } else if (platform === "ios") {
            await NativeSettings.openIOS({
                option: IOSSettings.App,
            });
        } else {
            console.error("Sistema operativo no compatible");
        }
    };

    return (
        <IonPage className="permission-page">
            <IonHeader className="permission-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={onBackButton}>
                            <IonIcon slot="icon-only" src={arrowBackButton} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle className="container-title">
                        <div className="flex-row ion-justify-content-center ion-align-items-center">
                            <span className="titles">Permisos</span>
                        </div>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="permission-content">
                <IonGrid>
                    <IonRow>
                        <IonCol
                            size="12"
                            className="ion-text-center ion-padding-top"></IonCol>
                        <IonCol
                            size="12"
                            className="ion-text-center ion-padding-top">
                            <IonText className="ion-padding-top permission-Title">
                                Página de permisos
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding" size="12">
                            <IonText className="permission-Text">
                                En esta pantalla podrás ver los permisos
                                necesarios de la aplicación
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        {showMessage && (
                            <div
                                className={`message-container ${
                                    showMessage ? "" : "hide"
                                }`}>
                                <p className="message-text">
                                    Por favor, conecta tu GPS
                                </p>
                            </div>
                        )}
                        <IonCol size="12">
                            <IonItem
                                mode="md"
                                lines="none"
                                className="listPermissions"
                                button
                                onClick={handleActivatePermissions}>
                                <IonLabel className="label-text">
                                    Permiso de ubicación
                                </IonLabel>
                                <IonIcon
                                    slot="end"
                                    onClick={handleshowMessage}
                                    className={PemissionStatus.LocationColor}
                                    icon={locationOutline}></IonIcon>
                            </IonItem>
                        </IonCol>
                        <IonCol size="12">
                            <IonItem
                                mode="md"
                                lines="none"
                                className="listPermissions"
                                button
                                onClick={handleActivatePermissions}>
                                <IonLabel className="label-text">
                                    Permiso de Notificación
                                </IonLabel>
                                <IonIcon
                                    className={
                                        PemissionStatus.NotificationColor
                                    }
                                    icon={notificationsOffOutline}></IonIcon>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="footerpermission"></div>
            </IonContent>
        </IonPage>
    );
};

export default PermissionsPage;
