import { Capacitor } from "@capacitor/core";

import OneSignal from "onesignal-cordova-plugin";
import WebAPI from "./escudoWebAPI";

/**
 * Initialize OneSignalService
 */
const OneSignalInit = (): void => {
    /**
     * enable debug in development mode
     */
    if (false) {
        /**
         *  Uncomment to set OneSignal device logging to VERBOSE
         */
        OneSignal.Debug.setLogLevel(6);

        /**
         * Uncomment to set OneSignal visual logging to VERBOSE
         */
        OneSignal.Debug.setAlertLevel(6);
    }

    let appId: string | undefined = getOnesignalAppId();

    OneSignal.initialize(appId);

    //OneSignal.Notifications.addEventListener("click", handlerNotificationOpened);

    OneSignal.Notifications.requestPermission(true).then(requestPermission);
};

/**
 * recover id and tokes from onesingal for update user in cura services
 */
export const updateUserPushSubscription = (userId: number): Promise<any> => {
    if (
        !OneSignal.User.pushSubscription.id ||
        !OneSignal.User.pushSubscription.token
    ) {
        alert("No se pueden recuperar los valores de OneSignal.");
    }

    return WebAPI.updateUserPushSubscription({
        userId: OneSignal.User.pushSubscription.id,
        pushToken: OneSignal.User.pushSubscription.token,
    });
};

/**
 * Recover the onesignal app id
 */
const getOnesignalAppId = (): string => {
    return process.env.REACT_APP_ONESIGNAL_ID
        ? process.env.REACT_APP_ONESIGNAL_ID
        : "";
};

/**
 * here request permissions for recibe notifications
 */
const requestPermission = (accepted: boolean) => {};

/**
 * Establece que se prodra realziar cuando se reciba la notificacion
 */
export const setHandlerNotificationsEventListener = (
    event: any,
    callback: any
) => {
    OneSignal.Notifications.addEventListener(event, callback);
};

/**
 * allow to connect with notification service
 */
export const isSureNotificationService = () => {
    return Capacitor.isNativePlatform();
};

/**
 * Export notifications service boostraper
 */
export const bootstrapNotificationsService = (): void => {
    if (Capacitor.isNativePlatform()) {
        OneSignalInit();
    } else {
    }
};
