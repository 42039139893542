import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { Alert } from "../plugins/Alert";
import { useSelector } from "react-redux";
import {
    selectorAlertCurrent,
    selectorIsAlarmActive,
    setAlertCurrent,
    setIsAlarmActive,
} from "../redux/slices/app";
import { useDispatch } from "react-redux";
import { selectUsersById } from "../redux/slices/entities/users";

/**
 * Hook para desactivar el incidente
 */
let useAlertIncident = () => {
    /**
     * Hooks
     */
    //const history = useHistory()
    const dispatch = useDispatch();

    /**
     * Local State
     */
    const [isAlertActiveByBackground, setIsAlertActiveByBackground] =
        useState(false);

    /**
     * shared state
     */
    const isAlarmAtiveByStore = useSelector(selectorIsAlarmActive);
    const isAlertCurrentByStore = useSelector(selectorAlertCurrent);
    const userAlertCurrent = useSelector(
        selectUsersById(isAlertCurrentByStore?.incident.user_id)
    );

    /**
     * la alarma sera activada si es que se recibe una notificacion en blackground usando el plugin de android o si
     * se recibe una notificacion en background usando la store
     */
    const isAlertActive = isAlertActiveByBackground || isAlarmAtiveByStore;

    useEffect(() => {
        const fun = async () => {
            if (Capacitor.getPlatform() == "android") {
                const { isActive, additionalData } = await Alert.isAlertActive(
                    {}
                );
                if (isActive) {
                    dispatch(
                        setAlertCurrent({
                            sound: additionalData?.sound,
                            notice: additionalData?.notice,
                            category_incident:
                                additionalData?.category_incident,
                            incident: additionalData?.incident,
                        })
                    );
                }

                setIsAlertActiveByBackground(isActive);
            }
        };

        fun();
    }, []);

    /**
     * detiene el sonido de la alarma en android
     */
    const complete = async () => {
        if (Capacitor.getPlatform() == "android") {
            const a = await Alert.complete({});
            setIsAlertActiveByBackground(false);
        }

        dispatch(setIsAlarmActive(false));
    };

    return { isAlertActive, complete, isAlertCurrentByStore, userAlertCurrent };
};

export default useAlertIncident;
