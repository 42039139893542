import { createSelector } from "@reduxjs/toolkit";
import { selectAllUsers } from "../../../../redux/slices/entities/users";
import { selectMeGroups } from "../../../../redux/slices/entities/groups";
import { selectIncidentsInMeGroups } from "../../../../redux/slices/entities/selectors";

/**
 * Selector para la vista
 */
export const selectViewModel = createSelector(
    selectIncidentsInMeGroups,
    selectAllUsers,
    selectMeGroups,
    (incidentsWithCatalog, allUsers) => {

        return incidentsWithCatalog.map(incidentWithCatalog => {
            return {
                ...incidentWithCatalog,
                user: allUsers.find((user: any) => user.user_id == incidentWithCatalog.incident.user_id)
            }
        })
    }
)