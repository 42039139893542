import { useEffect, useState } from "react";
import {
    isSureNotificationService,
    setHandlerNotificationsEventListener,
    updateUserPushSubscription,
} from "../services/notifications";
import { useSelector } from "react-redux";
import { selectAuthenticatedUserId } from "../redux/slices/auth";
import { useDispatch } from "react-redux";
import {
    NotificationClickEvent,
    NotificationWillDisplayEvent,
    OSNotification,
} from "onesignal-cordova-plugin";
import { setAlertCurrent, setIsAlarmActive } from "../redux/slices/app";
import { INCIDENT_PRIORITY, NOTICE_TYPE } from "../constants";
import { Browser } from "@capacitor/browser";

interface NotificationAction {
    actionId: string;
    handler: any;
}
/**
 * Hook for register user in onesingla
 */
let useNotificationService = () => {
    /**
     * hooks
     */
    const dispatch = useDispatch();

    /**
     * shared state
     */
    let userId = useSelector(selectAuthenticatedUserId);

    let notificationsActions: NotificationAction[] = [
        {
            actionId: "recover-account",
            handler: (additionalData: any) => {
                const openCapacitorSite = async () => {
                    await Browser.open({ url: additionalData.fulllink });
                };

                openCapacitorSite();
            },
        },
    ];

    useEffect(() => {
        if (isSureNotificationService()) {
            /**
             * gestiona las acciones
             */
            const manageActions = (notification: OSNotification) => {
                let additionalData: any = notification?.additionalData;

                let notificationAction = notificationsActions.find(
                    (i: NotificationAction) =>
                        i.actionId == additionalData?.action
                );

                if (additionalData && notificationAction) {
                    notificationAction.handler(additionalData);
                }
            };

            setHandlerNotificationsEventListener(
                "foregroundWillDisplay",
                (event: NotificationWillDisplayEvent) => {
                    let notification = event.getNotification();
                    let additionalData: any = notification?.additionalData;

                    let notice = additionalData?.notice;

                    if (notice?.type == NOTICE_TYPE.INCIDENT) {
                        let categoryIncident =
                            additionalData?.category_incident;

                        if (
                            categoryIncident?.priority == INCIDENT_PRIORITY.HIGH
                        ) {
                            dispatch(
                                setAlertCurrent({
                                    sound: additionalData?.sound,
                                    notice: additionalData?.notice,
                                    category_incident:
                                        additionalData?.category_incident,
                                    incident: additionalData?.incident,
                                })
                            );
                            dispatch(setIsAlarmActive(true));
                        }
                    }

                    if (additionalData?.action) {
                        manageActions(notification);
                    }
                }
            );

            setHandlerNotificationsEventListener(
                "click",
                (event: NotificationClickEvent) => {
                    let notification = event.notification;
                    let additionalData: any = notification?.additionalData;

                    let notice = additionalData?.notice;

                    if (notice?.type == NOTICE_TYPE.INCIDENT) {
                        let categoryIncident =
                            additionalData?.category_incident;

                        if (
                            categoryIncident?.priority == INCIDENT_PRIORITY.HIGH
                        ) {
                            dispatch(
                                setAlertCurrent({
                                    sound: additionalData?.sound,
                                    notice: additionalData?.notice,
                                    category_incident:
                                        additionalData?.category_incident,
                                    incident: additionalData?.incident,
                                })
                            );
                            dispatch(setIsAlarmActive(true));
                        }
                    }

                    if (additionalData?.action) {
                        manageActions(notification);
                    }
                }
            );
        }
    }, []);

    useEffect(() => {
        /**
         * bind the onesingal with shield
         */
        if (isSureNotificationService() && userId) {
            updateUserPushSubscription(userId)
                .then((data) => {})
                .catch((err) => {});
        }
    }, [userId]);

    return {};
};

export default useNotificationService;
