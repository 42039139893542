import React, { useEffect, useState } from "react";
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
    useIonAlert,
} from "@ionic/react";

import arrowBackButton from "../../assets/icons/arrow-back-button.svg";
import userProfile from "../../assets/icons/user-profile.svg";
import bell from "../../assets/icons/bell.svg";

import "./style.scss";
import { useHistory, useLocation } from "react-router";
import useProfile from "../../hooks/useProfile";
import exit from "../../assets/icons/exit.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/auth";
import { selectViewModel } from "./../../pages/tabs/pages/Notifications/selectors";

const ToolBar: React.FC<any> = ({ title, showExitButton }) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [presentAlert] = useIonAlert();

    const allNotices = useSelector(selectViewModel);

    const ActiveNotices = allNotices.filter(
        (item) => item.notification.aware === false
    ).length;

    const [buttonsDisabled, setbuttonsDisabled] = useState({
        profile: false,
        notifications: false,
    });

    useEffect(() => {
        let allpahts = location.pathname.split("/");

        setbuttonsDisabled({
            profile: allpahts.filter((path) => path == "profile").length > 0,
            notifications:
                allpahts.filter((path) => path == "notifications").length > 0,
        });
    }, [location]);

    const profileModal = useProfile();

    /**
     * handler backbutton
     */
    const handlerBackButton = () => {
        if (showExitButton) {
            presentAlert({
                message: "Deseas salir de la aplicación",
                buttons: [
                    {
                        text: "No",
                        role: "cancel",
                        handler: () => {},
                    },
                    {
                        text: "Si",
                        role: "confirm",
                        handler: () => {
                            onLogout();
                        },
                    },
                ],
            });
        } else {
            history.goBack();
        }
    };

    const onGoProfile = () => {
        history.push("/app/profile");
    };

    const onGoNotifications = () => {
        history.push("/app/notifications");
    };

    /**
     * salir de la aplicacion
     */
    const onLogout = () => {
        dispatch<any>(logout({ history }))
            .unwrap()
            .then((response: any) => {})
            .catch((err: any) => {
                console.log("estamos teniendo problemas");
            });
    };

    return (
        <IonHeader className="shield-tool-bar ion-no-border">
            <IonToolbar className="ion-text-center header">
                <IonButtons slot="start">
                    <IonButton
                        className="back-button"
                        onClick={handlerBackButton}>
                        <IonIcon
                            slot="icon-only"
                            icon={showExitButton ? exit : arrowBackButton}
                            className="toolbar"></IonIcon>
                    </IonButton>
                </IonButtons>
                <IonTitle className="title">{title}</IonTitle>
                <IonButtons slot="end">
                    {!buttonsDisabled.notifications && (
                        <IonButton onClick={onGoNotifications}>
                            <IonIcon slot="icon-only" icon={bell}></IonIcon>
                            {ActiveNotices > 0 && (
                                <IonBadge class="cart-badge icon-label">
                                    {ActiveNotices}
                                </IonBadge>
                            )}
                        </IonButton>
                    )}
                    {!buttonsDisabled.profile && (
                        <IonButton onClick={onGoProfile}>
                            <IonIcon
                                slot="icon-only"
                                icon={userProfile}></IonIcon>
                        </IonButton>
                    )}
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};

export default ToolBar;
