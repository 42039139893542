import {
    createAnimation,
    Gesture,
    IonCard,
    IonCardContent,
    IonIcon,
    IonText,
    createGesture,
    Animation,
} from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import "./AlertButton.scss";
import React, { useEffect, useRef } from "react";
interface Alert {
    title: string;
    color: string;
    id: number;
    icon: IconDefinition;
    onAlert: any;
    disabled: boolean;
}

const AlertCard: React.FC<Alert> = ({
    title,
    color,
    icon,
    onAlert,
    id,
    disabled,
}: Alert) => {
    let darkcolor = "#FFF";

    switch (id) {
        case 1:
            darkcolor = "#D64280";
            break;
        case 2:
            darkcolor = "#322FCE";
            break;
        case 3:
            darkcolor = "#F39912";
            break;
        case 4:
            darkcolor = "#73BA3C";
            break;
        case 5:
            darkcolor = "#DF6921";
            break;
        default:
            darkcolor = "#8400E6";
            break;
    }
    color =
        "linear-gradient(260deg, " + color + " 8.9%, " + darkcolor + " 94.19%)";

    const card = useRef<HTMLDivElement | null>(null);
    const divToAnimate = useRef<HTMLDivElement | null>(null);
    const animation = useRef<Animation | null>(null);

    useEffect(() => {
        if (animation.current === null && !disabled) {
            animation.current = createAnimation()
                .addElement(divToAnimate.current!)
                .duration(1500)
                .keyframes([
                    { offset: 0, width: "0em" },
                    { offset: 1, width: "100%" },
                ])
                .onFinish(() => {
                    onAlert({
                        id,
                    });
                });
        }
    }, [divToAnimate]);

    useEffect(() => {
        if (card.current && !disabled) {
            const target = card.current;

            if (target) {
                const gesture = createGesture({
                    el: target,
                    onStart: () => onStart(),
                    onEnd: () => onEnd(),
                    gestureName: "click",
                    threshold: 0,
                });

                gesture.enable();
            }
        }
    }, [card]);

    const onStart = () => {
        animation.current?.play();
    };

    const onEnd = () => {
        animation.current?.stop();
    };

    return (
        <div className="alert-button ion-text-center" ref={card}>
            <IonCard
                disabled={disabled}
                className={
                    "ion-no-margin alert-button-card " +
                    (disabled ? "disabled" : "")
                }
                button
                style={{ background: disabled ? color : "white" }}
            >
                <IonCardContent className="alert-card-content ion-text-center">
                    {!disabled && (
                        <div className="progress-container">
                            <div
                                className="custome-progress"
                                ref={divToAnimate}
                                style={{ background: color }}
                            ></div>
                            <div className="foreground"></div>
                        </div>
                    )}

                    <IonIcon
                        className="shield-alert"
                        src={
                            "assets/icon/" +
                            icon +
                            (disabled ? "_disabled" : "") +
                            ".svg"
                        }
                    />
                </IonCardContent>
            </IonCard>
            <IonText className="alert-button-label">
                {disabled ? "..." : title}
            </IonText>
        </div>
    );
};

export default AlertCard;
