import React, { useRef } from "react";
import { IonModal, IonIcon, IonToolbar, IonTitle } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import "./InfoModal.scss";

const InfoModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
    isOpen,
    onClose,
}) => {
    const modal = useRef<HTMLIonModalElement>(null);

    return (
        <IonModal
            mode="ios"
            className="infoModal"
            isOpen={isOpen}
            onDidDismiss={onClose}
            backdropDismiss={true}>
            <IonToolbar
                mode="ios"
                className="title"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <IonIcon
                    icon={informationCircle}
                    style={{ marginRight: "8px" }}
                />
                <IonTitle
                    style={{
                        fontSize: "15px",
                        marginRight: "110px",
                    }}>
                    Artículo 406 BIS
                </IonTitle>
            </IonToolbar>

            <div
                style={{
                    fontSize: "11px",
                    fontWeight: 800,
                }}
                className="ion-padding-start ion-padding-end">
                <p>
                    Con base al Artículo 406 BIS del Código Penal del Estado de
                    Durango, al que haga uso indebido de los números de
                    emergencias se le impondrá de seis meses a dos años de
                    prisión y multa de treinta y seis a ciento cuarenta y cuatro
                    veces la Unidad de Medida y Actualización.
                </p>
            </div>
        </IonModal>
    );
};

export default InfoModal;
