import { selectAllUsers } from "./../redux/slices/entities/users/index";
import axios, { AxiosError } from "axios";
import createAuthRefreshInterceptor, {
    AxiosAuthRefreshOptions,
} from "axios-auth-refresh";

interface ClientSettings {
    headers: {};
}

class EscudoWebAPI {
    client: any = null;
    token = null;

    /**
     * En la contruccion de esta clase el cliente tomara
     * datos de la enviroments que se encuentren por defecto
     * al correo el bundle
     */
    constructor() {
        this.client = this.getHTTPClient();
    }

    /**
     * Request another token using refreshtoken
     */
    refreshAuthLogic(failedRequest: any): Promise<any> {
        let url = encodeURI(`/auth/token/refresh`);

        return this.client
            .post(
                url,
                {},
                {
                    "Content-Type": "application/json",
                }
            )
            .then((tokenRefreshResponse: any) => {
                // TODO actualziar la store con el nuevo token

                //localStorage.setItem('token', tokenRefreshResponse.data.token);
                //failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
                return Promise.resolve();
            })
            .catch((err: any) => {
                // TODO sacar al usuario al login y reinicar la store
            });
    }

    /**
     * create base httpclient
     */
    getHTTPClient(clientSettings?: ClientSettings) {
        let baseURL = process.env.REACT_APP_WEB_API
            ? process.env.REACT_APP_WEB_API
            : "";

        return axios.create({
            baseURL: baseURL,
            proxy: false,
            headers: clientSettings?.headers,
        });
    }

    /**
     * create new authoriced client
     */
    createAuthorizedHTTPClient(token: string) {
        this.client = this.getHTTPClient({
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        createAuthRefreshInterceptor(this.client, this.refreshAuthLogic, {});
    }

    /**
     * udpate user properties for recive push notifications
     */
    updateUserPushSubscription(requestBody: any): Promise<any> {
        let url = encodeURI(`api/v1/me/push-subscriptions`);

        return this.client.put(
            url,
            {
                user_id: requestBody.userId,
                push_token: requestBody.pushToken,
            },
            {
                "Content-Type": "application/json",
            }
        );
    }

    /**
     * recover the user information
     */
    meInformation(): Promise<any> {
        let url = encodeURI(`api/v1/me/information`);

        return this.client.get(url, {
            "Content-Type": "application/json",
        });
    }

    /**
     * search user
     */
    SearchUsers(searchTerm: string): Promise<any> {
        let url = encodeURI(`api/v1/shields?search=${searchTerm}`);
        return this.client.get(url);
    }

    /**
     * recover the user information
     */
    getMeRoles(): Promise<any> {
        let url = encodeURI(`api/v1/me/roles`);

        return this.client.get(url, {
            "Content-Type": "application/json",
        });
    }

    /**
     *Delete the user information
     */
    DeleteUserInfo(userPhone: string): Promise<any> {
        let url = encodeURI(`/api/v1/me/unenrollment`);

        return this.client.delete(url, {
            headers: { "Content-Type": "application/json" },
            data: {
                cellphone: userPhone,
            },
        });
    }
    /**
     *Update the user information
     */

    UpdateUserInfo(userId: any, userData: any): Promise<any> {
        let url = encodeURI(`/api/v1/users/${userId}`);
        return this.client.put(url, userData, {
            "Content-Type": "application/json",
        });
    }

    /**
     *Update Pass
     */

    UpdateUserPass(
        old_password: any,
        password: any,
        password_confirmation: any
    ): Promise<any> {
        let url = encodeURI(`/api/v1/me/password`);

        return this.client.put(
            url,
            {
                old_password: old_password,
                password: password,
                password_confirmation: password_confirmation,
            },
            {
                headers: { "Content-Type": "application/json" },
            }
        );
    }

    UpdateUserUsename(newPhone: any): Promise<any> {
        let url = encodeURI(`/api/v1/me/username`);

        return this.client.put(
            url,
            {
                username: newPhone,
            },
            {
                headers: { "Content-Type": "application/json" },
            }
        );
    }

    /**
     * login user
     */
    login(username: string, password: string) {
        let url = encodeURI(`/oauth/token`);

        let clientId = process.env.REACT_APP_WEB_API_CLIENT_ID;
        let clientSecret = process.env.REACT_APP_WEB_API_CLIENT_SECRET;

        return this.client
            .post(
                url,
                {
                    grant_type: "password",
                    username,
                    password,
                    client_id: clientId,
                    client_secret: clientSecret,
                    scope: "*",
                },
                {
                    "Content-Type": "application/json",
                }
            )
            .then((response: any) => {
                this.createAuthorizedHTTPClient(response.data.access_token);

                return response;
            });
    }
    /**
     * create a new user
     */
    signup(userCreate: any) {
        let url = encodeURI(`/api/v1/shields`);

        return this.client.post(
            url,
            {
                ...userCreate,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    }

    /**
     * recover the user information
     */
    createIncident(
        userId: number,
        groupId: number,
        request: any
    ): Promise<any> {
        let url = encodeURI(
            `api/v1/users/${userId}/groups/${groupId}/incidents`
        );

        return this.client.post(url, request, {
            "Content-Type": "application/json",
        });
    }

    createEmergencyIncident(userId: number, data: any): Promise<any> {
        let url = encodeURI(`api/v1/users/${userId}/emergencyIncidents`);

        return this.client.post(url, data, {
            "Content-Type": "application/json",
        });
    }

    /**
     * create a new incident
     */
    createIncidents(
        userId: number,
        groupId: number,
        request: any
    ): Promise<any> {
        let url = encodeURI(
            `api/v1/catalogs/${userId}/groups/${groupId}/incidents`
        );

        return this.client.post(url, request, {
            "Content-Type": "application/json",
        });
    }

    /**
     * get all catalogs incidents
     */
    getCatalogsIncidents() {
        let url = encodeURI(`api/v1/catalogincidents`);

        return this.client.get(url);
    }

    /**
     * get user's groups
     */
    getGroupsByUser(userId: number) {
        let url = encodeURI(`api/v1/users/${userId}/groups`);

        return this.client.get(url);
    }

    /**
     * get user's news
     */
    getNewsByUser(userId: number) {
        let url = encodeURI(`api/v1/users/${userId}/news`);

        return this.client.get(url);
    }

    /**
     * get user's news
     */
    getNotificationsByUser(userId: number) {
        let url = encodeURI(`api/v1/users/${userId}/notifications`);

        return this.client.get(url);
    }

    /**
     * get user's incidents
     */
    getIncidentsByUser(userId: number) {
        let url = encodeURI(`api/v1/users/${userId}/incidents`);

        return this.client.get(url);
    }

    /**
     * recupera los incidentes de los usuarios a los que pertence un grupo
     */
    getEventualitiesByUser(userId: number) {
        let url = encodeURI(`api/v1/users/${userId}/eventualities`);
        return this.client.get(url);
    }

    /**
     * get user's news
     */
    getMemberhipsByUser(userId: number) {
        let url = encodeURI(`api/v1/users/${userId}/memberships`);

        return this.client.get(url);
    }

    /**
     * get states
     */

    getAllStates() {
        let url = encodeURI(`api/v1/states`);
        return this.client.get(url);
    }

    /**
     * get State By Municipality
     */
    getstatesByMunicipalite(municipalityId: number) {
        let url = encodeURI(`api/v1/municipalities/${municipalityId}/states`);
        return this.client.get(url);
    }
    /**
     * get Municipalities By State
     */
    getMunicipaliteById(municipaliteId: number) {
        let url = encodeURI(`api/v1/municipalities/${municipaliteId}`);
        return this.client.get(url);
    }
    /**
     * get Municipalities By State
     */
    getMunicipalitiesByState(stateId: number) {
        let url = encodeURI(`api/v1/states/${stateId}/municipalities`);
        return this.client.get(url);
    }

    /**
     * get Municipalities by city
     */

    getMunicipalityByCityId(cityId: number) {
        let url = encodeURI(`api/v1/cities/${cityId}/municipalities`);
        return this.client.get(url);
    }

    /**
     * get all Citys
     */

    getAllCitys() {
        let url = encodeURI(`api/v1/states`);
        return this.client.get(url);
    }
    /**
     * get Citys by id
     */
    getCitysById(cityId: number) {
        let url = encodeURI(`api/v1/cities/${cityId}`);
        return this.client.get(url);
    }
    /**
     * get City by municipalite
     */

    getCityByMunicipalite(MunicipalitiesId: number) {
        let url = encodeURI(`api/v1/municipalities/${MunicipalitiesId}/cities`);
        return this.client.get(url);
    }

    /**
     * agregar comentario a un incidente
     */
    setIncidentDescription(
        incidentId: number,
        description: string
    ): Promise<any> {
        let url = encodeURI(`api/v1/incidents/${incidentId}`);

        return this.client.put(url, {
            description,
        });
    }

    /**
     * pone como resuelto un incidente
     */
    setIncidentSolved(incidentId: number, solved: boolean): Promise<any> {
        let url = encodeURI(`api/v1/incidents/${incidentId}`);

        return this.client.put(url, {
            solved,
        });
    }

    /**
     * Creacion de un nuevo grupo
     */
    createGroup(data: any): Promise<any> {
        let url = encodeURI(`api/v1/groups`);

        return this.client.post(url, data, {
            "Content-Type": "application/json",
        });
    }
    /**
     * Creacion de un grupo con un usuario registrado
     */
    createGroupWithUserExist(
        id: any,
        name: string,
        group_type: any,
        dependency: any
    ): Promise<any> {
        let url = encodeURI(`api/v1/users/${id}/groups`);

        return this.client.post(
            url,
            {
                name: name,
                group_type: group_type,
                dependency: dependency,
            },
            {
                headers: { "Content-Type": "application/json" },
            }
        );
    }
    /**
     * Obtencion de el rol del usaurio
     */
    getRoleUser(id: any): Promise<any> {
        let url = encodeURI(`api/v1/users/${id}/roles`);

        return this.client.get(url, {
            "Content-Type": "application/json",
        });
    }
    /**
     * Creacion de ss
     */
    createSuperShield(id: any): Promise<any> {
        let url = encodeURI(`api/v1/roles/${id}`);
        return this.client.put(url, {
            "Content-Type": "application/json",
        });
    }

    /**
     * get dependenci for a one group
     */
    GetDependenciforGroup(id: any): Promise<any> {
        let url = encodeURI(`api/v1/groups/${id}/dependency`);
        return this.client.get(url);
    }

    /**
     * actualziar informacion de un grupo
     */
    updateGroup(groupId: number, data: any): Promise<any> {
        let url = encodeURI(`api/v1/groups/${groupId}`);

        return this.client.put(url, data, {
            "Content-Type": "application/json",
        });
    }

    /**
     * crear una nueva membrecia
     */
    createMembership(groupId: number, data: any): Promise<any> {
        let url = encodeURI(`api/v1/groups/${groupId}/memberships`);

        return this.client.post(url, data, {
            "Content-Type": "application/json",
        });
    }

    /**
     * crear una nueva membrecia
     */
    getMembersByGroup(groupId: number): Promise<any> {
        let url = encodeURI(`api/v1/groups/${groupId}/users`);

        return this.client.get(url, {
            "Content-Type": "application/json",
        });
    }

    /**
     * crear una nueva membrecia
     */
    getMembeshipsByGroup(groupId: number): Promise<any> {
        let url = encodeURI(`api/v1/groups/${groupId}/memberships`);

        return this.client.get(url, {
            "Content-Type": "application/json",
        });
    }

    /**
     * crear una nueva membrecia
     */
    removemembreship(membershipId: number): Promise<any> {
        let url = encodeURI(`api/v1/memberships/${membershipId}`);

        return this.client.delete(url, {
            "Content-Type": "application/json",
        });
    }

    /**
     * recuperar usuario usando una membreshia
     */
    getUserByMembership(membershipId: number): Promise<any> {
        let url = encodeURI(`api/v1/memberships/${membershipId}/users`);

        return this.client.get(url, {
            "Content-Type": "application/json",
        });
    }

    /**
     * recuperar usuario usando una membreshia
     */
    getGroupById(groupId: number): Promise<any> {
        let url = encodeURI(`api/v1/groups/${groupId}`);

        return this.client.get(url, {
            "Content-Type": "application/json",
        });
    }

    /**
     * remplaca una membrecia de un usuario
     */
    repaceMembership(
        groupId: number,
        userId: number,
        membeshipType: any
    ): Promise<any> {
        let url = encodeURI(
            `api/v1/users/${groupId}/groups/${userId}/memberships`
        );

        return this.client.post(
            url,
            {
                type: membeshipType,
            },
            {
                "Content-Type": "application/json",
            }
        );
    }

    /**
     * cambia el estatus de enterado de una notificación
     */
    setNotificationAware(notificationId: number, aware: boolean): Promise<any> {
        let url = encodeURI(`api/v1/notifications/${notificationId}`);

        return this.client.put(url, {
            aware,
        });
    }
    /////////////////////////////////////////USUARIO DEPENDENCIA//////////////////////////////////////////

    /**
     * get dependencyById
     */
    getDependencyById(id: any): Promise<any> {
        let url = encodeURI(`api/v1/dependencies/${id}`);

        return this.client.get(url, {
            "Content-Type": "application/json",
        });
    }

    /**
     * get all grops for dependency for id
     */

    getGroupsForDependency(id: any, queryParameters = {}) {
        let endpoint = `api/v1/dependencies/${id}/groups`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.client.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * get all grops for dependency for id
     */

    getMembersForDependeny(id: any, queryParameters = {}) {
        let endpoint = `api/v1/dependencies/${id}/shields`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.client.get(`${endpoint}?${queryEncoded}`);
    }
    /**
     * get all grops for dependency for id
     */

    getIncidentesByGroup(id: any, queryParameters = {}) {
        let endpoint = `/api/v1/groups/${id}/incidents`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.client.get(`${endpoint}?${queryEncoded}`);
    }
    //////////////////////////////////////////USUARIO GOBERNADOR//////////////////////////////////////////
    /**
     * Obtener todos los usaurios
     */
    AllUsers(queryParameters = {}) {
        let endpoint = `api/v1/users`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.client.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Obtener todos los usaurios
     */
    AllUsersTest(id: any, queryParameters = {}) {
        let endpoint = `api/v1/users`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.client.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Obtener todos los grupos
     */
    AllGroups(queryParameters = {}) {
        let endpoint = `api/v1/groups`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.client.get(`${endpoint}?${queryEncoded}`);
    }
    /**
     * Obtener todos los incidentes
     */
    AllIncidents(queryParameters = {}) {
        let endpoint = `api/v1/incidents`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.client.get(`${endpoint}?${queryEncoded}`);
    }
    /**
     * Obtener todas las membresias
     */
    Allmemberships(queryParameters = {}) {
        let endpoint = `api/v1/memberships`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.client.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * get all dependencies
     */
    getAllDependenci(queryParameters = {}) {
        let endpoint = `api/v1/dependencies`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.client.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Combierte un Objecto de JS en un QueryHttp
     * @param {*} object
     *
     * @returns
     */
    encodeQuery(object: any) {
        return Object.entries(object)
            .map(([key, val]) => {
                let value = this.isObject(val)
                    ? encodeURIComponent(JSON.stringify(val))
                    : val;

                return `${encodeURIComponent(key)}=${value}`;
            })
            .join("&");
    }
    isObject(obj: any) {
        return typeof obj === "object" && obj !== null && !Array.isArray(obj);
    }
    /**
     * Recuperar recursos del servidor paginados
     *
     * @param {string} method Metodo que sera usado por a API
     * @param {array} params Parametros que seran enviados a method
     * @param {object} queryParameters Parametros de query que ira en la solicitud
     *
     * @returns
     */
    async getResourcesPaginatedFromServer(
        method: any,
        params: any,
        queryParameters = {},
        lastPage = null
    ) {
        let currentPage = 0;

        let that: any = this;

        let items: any = [];
        let metas: any = [];

        let parameter = {
            per_page: 50,
            page: currentPage,
        };

        if (queryParameters) {
            parameter = { ...parameter, ...queryParameters };
        }

        do {
            currentPage++;

            parameter.page = currentPage;

            let itemsResponse = await that[method](...params, parameter).then(
                (res: any) => res.data
            );

            let itemsPaginated = itemsResponse.data;
            let meta = itemsResponse.meta;

            if (lastPage == null) {
                lastPage = meta.last_page;
            }

            items = items.concat(itemsPaginated);
            metas = metas.concat(meta);
        } while (currentPage < lastPage!);

        return {
            metas,
            data: items,
        };
    }
}
export default new EscudoWebAPI();
