import {
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { selectAuthRole, selectAuthenticatedUserId } from "../../auth";
import { ROLES } from "../../../../constants";

/**
 * create adapter for the group entity
 */
const membreshipsAdapter = createEntityAdapter<any>({
    selectId: (membership) => membership.membership_id,
    sortComparer: (membershipA, membershipB) =>
        membershipA.membership_id - membershipB.membership_id,
});

/**
 * groups slice definitions
 */
export const membershipsSlice = createSlice({
    name: "memberships",
    initialState: membreshipsAdapter.getInitialState(),
    reducers: {
        addManyMembreships: membreshipsAdapter.addMany,
        addOneMembreship: membreshipsAdapter.addOne,
        updateOneMembership: membreshipsAdapter.updateOne,
        upsertOneMembreship: membreshipsAdapter.upsertOne,
        upsertManyMembreships: membreshipsAdapter.upsertMany,
        setAllMembreships: membreshipsAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return membreshipsAdapter.getInitialState();
        });
    },
});

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManyMembreships,
    addOneMembreship,
    upsertOneMembreship,
    upsertManyMembreships,
    setAllMembreships,
    updateOneMembership,
} = membershipsSlice.actions;

/**
 * export reducer autegenerated by toolkit
 */
export default membershipsSlice.reducer;

//////////////////// SELECTORES //////////////////

/**
 * global selectors
 */
const globalSelectors = membreshipsAdapter.getSelectors(
    (store: any) => store.entities.memberships
);

export const selectAllMembreships = (store: any) =>
    globalSelectors.selectAll(store);
export const selectMembershipsById = (membershipId: any) => (store: any) =>
    globalSelectors.selectById(store, membershipId);
export const selectMembershipsIds = (store: any) =>
    globalSelectors.selectIds(store);
export const selectMembershipsEntities = (store: any) =>
    globalSelectors.selectEntities(store);

/**
 * Selector para recupera todas las membrecias activas
 */
export const selectAllMembreshipsActived = createSelector(
    selectAllMembreships,
    (allmemberships) =>
        allmemberships.filter((membership: any) => membership.active)
);

/**
 * selector para recuperar las membrecias de un usuario
 */
export const selectMembershipsByUser = (userId: number) =>
    createSelector(selectAllMembreships, (allmemberships) =>
        allmemberships.filter((mem) => mem.user_id == userId)
    );

/**
 * selector para recuperar membrecias activas de un usuario
 */
export const selectActivedMembershipsByUser = (userId: number) =>
    createSelector(selectMembershipsByUser(userId), (memberships) =>
        memberships.filter((mem) => mem.active)
    );

/**
 * selector para recuperar membrecias de un grupo
 */
export const selectMembershipsByGroup = (groupId: number) =>
    createSelector(selectAllMembreshipsActived, (allMemberships) => {
        return allMemberships.filter(
            (member: any) => member.group_id == groupId
        );
    });

/////////////////// AUTH USER ///////////////////

/**
 * selector para recuperar las membrecias del usuario autenticado
 */
export const selectMeMemberships = createSelector(
    selectAuthenticatedUserId,
    selectAllMembreships,
    selectAuthRole,
    (userId, allmemberships, authUser) => {
        if (authUser?.user_type == ROLES.ADMIN) {
            return allmemberships;
        }
        if (authUser?.user_type == ROLES.SUPERADMIN) {
            return allmemberships;
        }
        return allmemberships.filter((mem: any) => mem.user_id == userId);
    }
);
/**
 * selector para recuperar las membrecias activas del usuario autenticado
 */
export const selectMeActivedMemberships = createSelector(
    selectMeMemberships,
    (memberships) => memberships.filter((mem) => mem.active)
);
