import React from "react";
import { IonModal, IonButton } from "@ionic/react";
import "./ModalUpdatePass.scss";

interface ModalUpdatePassProps {
  isOpen: boolean;
  closeModal: () => void;
}

const ModalUpdatePass: React.FC<ModalUpdatePassProps> = ({
  isOpen,
  closeModal,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      className="ModalUpdatePass"
    >
      <div className="wrapper">
        <p className="ModalUpdatePassTitle">Advertencia</p>
        <p className="ModalUpdatePassText">
          <span>
            Ha modificado su contraseña con éxito. Asegúrese de
            recordar su nueva contraseña para futuras referencias.
          </span>
        </p>
        <IonButton className="buttonGoBack" onClick={closeModal}>
          regresar
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalUpdatePass;
