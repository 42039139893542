import {
    IonActionSheet,
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ToolBar from "../../../../../../components/ToolBar";
import { useHistory, useParams } from "react-router";
import "./style.scss";
import { useSelector } from "react-redux";
import {
    selectgroupsById,
    upsertOnegroup,
} from "../../../../../../redux/slices/entities/groups";
import groupIcon from "./../../../../../..//assets/icons/group_icon.svg";
import { DateTime } from "luxon";

import RedDot from "./../../../../../../assets/icons/dot_red.svg";
import GreenDot from "./../../../../../../assets/icons/dot_green.svg";
import escudoWebAPI from "../../../../../../services/escudoWebAPI";
import { selectUsersWithMembershipsByGroup } from "../../../../../../redux/slices/entities/selectors";
import { MEMBERSHIPS } from "../../../../../../constants";
import {
    selectMeActivedMemberships,
    upsertManyMembreships,
} from "../../../../../../redux/slices/entities/memberships";
import {
    logout,
    selectAuthenticatedUserId,
    selectCanManageGroups,
    selectDependencyUser,
    selectGobUser,
} from "../../../../../../redux/slices/auth";
import feedback from "../../../../../../services/feedback";
import { useFeedback } from "../../../../../../hooks";
import { useDispatch } from "react-redux";

import MemberGroupItem from "./Components/MemberGroupItem";
import { alertCircle } from "ionicons/icons";
import { selectIncidentsWithCatalogByGroup } from "../../../../../../redux/slices/entities/incidents";

const GroupDetails: React.FC = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    const dispatch = useDispatch();
    const { id }: any = useParams();
    const [showAlert] = useIonAlert();
    const [showDeleteMeMembershipALert] = useIonAlert();
    const [showCloseAppALert] = useIonAlert();
    const feedbackApp = useFeedback();
    const [present, disminss] = useIonLoading();

    /**
     * shared state
     */
    const userId = useSelector(selectAuthenticatedUserId);
    const group = useSelector(selectgroupsById(id));
    const incidents = useSelector(selectIncidentsWithCatalogByGroup(id));
    const members = useSelector(selectUsersWithMembershipsByGroup(id));
    const canManageGroups = useSelector(selectCanManageGroups);
    const myMemberships = useSelector(selectMeActivedMemberships);

    const membershipInThisGroup = myMemberships.find(
        (membership: any) => membership.group_id == id
    );

    const isGovernor = useSelector(selectGobUser);
    const isDependency = useSelector(selectDependencyUser);

    /**
     * Local state
     */

    const [isOpenMembershipLevelAction, setIsOpenMembershipLevelAction] =
        useState(false);
    const [memberSelected, setMemberSelected] = useState<any>({
        user: null,
        membership: null,
    });
    const [dinamicActions, setDinamicActions] = useState<any>([]);

    const totalResolved = incidents.filter(
        (incidentWithCatalog: any) => incidentWithCatalog.incident.solved
    ).length;
    const totalNotResolved = incidents.filter(
        (incidentWithCatalog: any) => !incidentWithCatalog.incident.solved
    ).length;

    let incidentsOrdened = incidents.sort((a: any, b: any) => {
        let aDate = DateTime.fromSQL(a.incident.created_at);
        let bDate = DateTime.fromSQL(b.incident.created_at);

        return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
    });

    /**
     * ir al detalle del grupo
     */
    const onGoEditGroup = () => {
        history.push(`/app/groups/details/${id}/edit`);
    };

    const onGoMembersMap = () => {
        history.push(`/app/groups/details/${id}/members-group-map`);
    };

    /**
     * Generador de las opciones para modificar el tipo de
     * membrecia de in integrante de un equipo
     */
    useEffect(() => {
        let dinamicActionsTemp: any = [];

        if (memberSelected.user) {
            let membership = memberSelected.membership;

            if (membership?.type == MEMBERSHIPS.ADMINISTRATOR) {
                dinamicActionsTemp.push({
                    text: "Convertir a invitado",
                    data: {
                        action: "to-guest",
                    },
                });
            } else {
                dinamicActionsTemp.push({
                    text: "Convertir a administrador",
                    data: {
                        action: "to-admin",
                    },
                });
            }

            setDinamicActions(
                dinamicActionsTemp.concat([
                    {
                        text: "Eliminar del grupo",
                        role: "destructive",
                        data: {
                            action: "remove",
                        },
                    },
                    {
                        text: "Cerrar",
                        role: "cancel",
                        data: {
                            action: "cancel",
                        },
                    },
                ])
            );
        }
    }, [memberSelected]);

    /**
     * trater de modificar la membrecia de un usuario
     */
    const onTryUpdateMembershipUser = (userWithMembership: any) => {
        setMemberSelected(userWithMembership);
        setIsOpenMembershipLevelAction(true);
    };

    /**
     * menejador de las membrecias selecionadas
     */
    const onHandlerSelectMembershipLevelAction = (detail: any) => {
        let { action } = detail.data;

        setIsOpenMembershipLevelAction(false);

        if (action == "cancel") {
            return;
        }

        let header = "";
        let message = "";
        let handler = () => {};

        if (action == "remove") {
            header = "Eliminar miembro";
            message = `Verdaderamente deseas eliminar a ${memberSelected.user?.name} del grupo`;

            handler = () => {
                removeUserFromGroup(memberSelected);
            };
        } else if (action == "to-guest") {
            header = "Convertir a invitado";
            message = `Verdaderamente deseas convertir a ${memberSelected.user?.name} a invitado`;

            handler = () => {
                changeUserMembershipType(memberSelected, MEMBERSHIPS.GUEST);
            };
        } else if (action == "to-admin") {
            header = "Convertir a administrador";
            message = `Verdaderamente deseas convertir a ${memberSelected.user?.name} en invitado`;
            handler = () => {
                changeUserMembershipType(
                    memberSelected,
                    MEMBERSHIPS.ADMINISTRATOR
                );
            };
        }

        showAlert({
            header,
            message,
            backdropDismiss: false,
            buttons: [
                {
                    text: "no",
                    role: "cancel",
                    handler: () => {},
                },
                {
                    text: "si",
                    role: "confirm",
                    handler,
                },
            ],
        });
    };

    /**
     * Eliminar usurio de un grupo
     */
    const removeUserFromGroup = ({ user, membership }: any) => {
        present({
            message: "Por favor espera",
        });

        removeOneGroupFromGroup(membership)
            .then((data) => {
                let groupUpdated = data.group;
                let allMemberships = data.allMembershipsInGroup;

                dispatch(upsertManyMembreships(allMemberships));
                dispatch(upsertOnegroup(groupUpdated));
            })
            .catch((err) => {
                let message = feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                disminss();
            });
    };

    /**
     * eliminar a un usuario de un grupo
     */
    const removeOneGroupFromGroup = async (membershipToRemove: any) => {
        let membreshipDeleted = await escudoWebAPI
            .removemembreship(membershipToRemove.membership_id)
            .then((res) => res.data.data);
        let group = await escudoWebAPI
            .getGroupById(membershipToRemove.group_id)
            .then((res) => res.data.data);
        let allMembershipsInGroup = await escudoWebAPI
            .getMembeshipsByGroup(membershipToRemove.group_id)
            .then((res) => res.data.data);

        return {
            membreshipDeleted,
            group,
            allMembershipsInGroup,
        };
    };

    /**
     * cambiar el tipo de membrecia de un usuario
     */
    const changeUserMembershipType = (
        { user, membership }: any,
        newMembershipType: MEMBERSHIPS
    ) => {
        present({
            message: "Por favor espera",
        });

        changeMembership(user.user_id, id, newMembershipType)
            .then((data) => {
                let groupUpdated = data.group;
                let allMemberships = data.allMembershipsInGroup;

                dispatch(upsertManyMembreships(allMemberships));
                dispatch(upsertOnegroup(groupUpdated));
            })
            .catch((err) => {
                let message = feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                disminss();
            });
    };

    /**
     * realziar el cambio de la membrecia
     */
    const changeMembership = async (
        userId: any,
        groupId: any,
        newMembershipType: any
    ) => {
        let membreshipCreated = await escudoWebAPI
            .repaceMembership(userId, groupId, newMembershipType)
            .then((res) => res.data.data);
        let group = await escudoWebAPI
            .getGroupById(groupId)
            .then((res) => res.data.data);
        let allMembershipsInGroup = await escudoWebAPI
            .getMembeshipsByGroup(groupId)
            .then((res) => res.data.data);

        return {
            membreshipCreated,
            group,
            allMembershipsInGroup,
        };
    };

    /**
     * funcion para eliminar mi membrecia
     */
    const onRemoveMeMebership = (data: any) => {
        showDeleteMeMembershipALert({
            header: "Salir del grupo",
            message: "Verdaderamente deseo salir del grupo",
            backdropDismiss: false,
            buttons: [
                {
                    text: "no",
                    role: "cancel",
                    handler: () => {},
                },
                {
                    text: "si",
                    role: "confirm",
                    handler: () => {
                        removeMeMembership(data);
                    },
                },
            ],
        });
    };

    /**
     * accion para eliminar la membrecia de un usuario
     */
    const removeMeMembership = ({ membership }: any) => {
        present({
            message: "Por favor espera",
        });

        escudoWebAPI
            .removemembreship(membership.membership_id)
            .then((res) => res.data.data)
            .then((membershipCreated) => {
                showCloseAppALert({
                    header: "Alerta",
                    message: "La aplicacion se cerrará",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "si",
                            role: "confirm",
                            handler: () => {
                                dispatch<any>(logout({ history }));
                            },
                        },
                    ],
                });
            })
            .catch((err) => {
                let message = feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                disminss();
            });
    };

    return (
        <IonPage className="group-detail-page">
            <ToolBar title="Grupo" showExitButton={!canManageGroups}></ToolBar>
            <IonContent>
                <div className="container-group-icon ion-text-center">
                    <div className="">
                        <IonIcon src={groupIcon} className="group-icon" />
                    </div>
                    <div>
                        <IonText className="group-name">{group?.name}</IonText>
                    </div>
                    <div>
                        {isGovernor ? (
                            <IonText className="group-total-members">
                                {group?.total_members} Participantes
                            </IonText>
                        ) : isDependency ? (
                            <IonText className="group-total-members">
                                {group?.total_members - 1} Participantes
                            </IonText>
                        ) : (
                            <IonText className="group-total-members">
                                {group?.total_members -
                                    members.filter((item) => {
                                        if (
                                            membershipInThisGroup?.type ===
                                                MEMBERSHIPS.CREATOR ||
                                            membershipInThisGroup?.type ===
                                                MEMBERSHIPS.ADMINISTRATOR ||
                                            membershipInThisGroup?.type ===
                                                MEMBERSHIPS.GUEST
                                        ) {
                                            return (
                                                item.membership.type ===
                                                MEMBERSHIPS.CREATOR
                                            );
                                        } else {
                                            return false;
                                        }
                                    }).length}{" "}
                                Participantes
                            </IonText>
                        )}
                    </div>
                    <div>
                        <IonText className="group-total-members">
                            Creado el{" "}
                            {DateTime.fromSQL(group?.created_at)
                                .setLocale("es")
                                .toLocaleString(DateTime.DATE_FULL)}
                        </IonText>
                    </div>
                </div>
                <div className="ion-text-center ion-margin-top">
                    {!isGovernor &&
                        !isDependency &&
                        membershipInThisGroup?.type != MEMBERSHIPS.GUEST && (
                            <IonButton
                                onClick={onGoEditGroup}
                                className="edit-group-button"
                                mode="ios"
                                size="small">
                                Editar grupo
                            </IonButton>
                        )}
                    {isGovernor ||
                        isDependency ||
                        (canManageGroups && (
                            <IonButton
                                onClick={onGoMembersMap}
                                className="edit-group-button"
                                mode="ios"
                                size="small">
                                Miembros en Mapa
                            </IonButton>
                        ))}
                </div>

                <div className="divider"></div>

                <div className="flex-row ion-justify-content-center ion-align-items-center">
                    <IonCard className="box-indicator served">
                        <IonCardContent class="flex-column ion-justify-content-center ion-align-items-center">
                            <IonText className="subtitle">Reportes</IonText>
                            <IonText className="title">Atendidos</IonText>
                            <IonText class="indicator">{totalResolved}</IonText>
                            <IonButton
                                className="buttonIncident"
                                mode="ios"
                                size="small"
                                onClick={() =>
                                    history.push(
                                        `/app/groups/${id}/incidets-Solved`
                                    )
                                }>
                                Ver en el mapa
                            </IonButton>
                        </IonCardContent>
                    </IonCard>

                    <IonCard className="box-indicator pedding">
                        <IonCardContent class="flex-column ion-justify-content-center ion-align-items-center">
                            <IonText className="subtitle">Reportes</IonText>
                            <IonText className="title">Pendientes</IonText>
                            <IonText class="indicator">
                                {totalNotResolved}
                            </IonText>
                            <IonButton
                                className="buttonIncident"
                                mode="ios"
                                size="small"
                                onClick={() =>
                                    history.push(
                                        `/app/groups/${id}/incidets-unsolved`
                                    )
                                }>
                                Ver en el mapa
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                </div>

                <div className="ion-text-center ion-margin-top">
                    <IonText className="page-content-subtitle">
                        Reportes
                    </IonText>
                </div>

                <IonCard className="incidents">
                    <IonCardContent className="incidents-scroll">
                        <IonList inset={false}>
                            {incidentsOrdened.map((incidentWithCatalog) => (
                                <IonItem
                                    detail={false}
                                    lines={"full"}
                                    className="incidents-item"
                                    key={
                                        incidentWithCatalog.incident.incident_id
                                    }>
                                    <IonIcon
                                        className="incident-icon-bottom-sheet"
                                        aria-hidden="true"
                                        src={
                                            "assets/icon/" +
                                            incidentWithCatalog.catalog.icon +
                                            ".svg"
                                        }
                                        slot="start"></IonIcon>
                                    <IonLabel>
                                        {incidentWithCatalog.catalog.title}
                                    </IonLabel>
                                    <div slot="end">
                                        <IonText className="report-date">
                                            {DateTime.fromSQL(
                                                incidentWithCatalog.incident
                                                    .created_at
                                            ).toLocaleString(DateTime.DATE_MED)}
                                        </IonText>
                                        <IonIcon
                                            className="point"
                                            src={
                                                incidentWithCatalog.incident
                                                    .solved
                                                    ? GreenDot
                                                    : RedDot
                                            }></IonIcon>
                                    </div>
                                </IonItem>
                            ))}
                        </IonList>

                        {incidentsOrdened.length == 0 && (
                            <div className="ion-margin-top ion-text-center flex-column incidents-not-incidentes ion-justify-content-center ion-align-items-center">
                                <IonIcon
                                    className="no-group-icon"
                                    src={alertCircle}
                                />
                                <IonText className="ion-margin-top">
                                    Este grupo aún no cuenta con reporte de
                                    incidentes
                                </IonText>
                            </div>
                        )}
                    </IonCardContent>
                </IonCard>

                <div className="ion-text-center ion-margin-top">
                    <IonText className="page-content-subtitle">
                        Integrantes de este Grupo
                    </IonText>
                </div>

                <IonCard className="members safe-bottom-margin">
                    <IonCardContent>
                        {isGovernor || isDependency ? (
                            <IonList inset={false}>
                                {members
                                    .sort((a, b) => {
                                        return a.user.name.localeCompare(
                                            b.user.name
                                        );
                                    })
                                    .map((item: any) => (
                                        <MemberGroupItem
                                            key={item.user.user_id}
                                            data={item}
                                            me={
                                                userId == item.user.user_id
                                            }></MemberGroupItem>
                                    ))}
                            </IonList>
                        ) : (
                            <IonList inset={false}>
                                {members
                                    .filter((item) => {
                                        if (
                                            membershipInThisGroup?.type >=
                                            MEMBERSHIPS.ADMINISTRATOR
                                        ) {
                                            return (
                                                item.membership.type !==
                                                MEMBERSHIPS.CREATOR
                                            );
                                        } else {
                                            return item.membership.type;
                                        }
                                    })
                                    .sort((a, b) => {
                                        return a.user.name.localeCompare(
                                            b.user.name
                                        );
                                    })
                                    .map((item: any) => (
                                        <MemberGroupItem
                                            key={item.user.user_id}
                                            data={item}
                                            me={userId == item.user.user_id}
                                            showMembershipOptions={
                                                (membershipInThisGroup.type ==
                                                    MEMBERSHIPS.ADMINISTRATOR ||
                                                    membershipInThisGroup.type ==
                                                        MEMBERSHIPS.CREATOR) &&
                                                item.membership.type !=
                                                    MEMBERSHIPS.CREATOR
                                            }
                                            onChangeMembership={
                                                onTryUpdateMembershipUser
                                            }
                                            onRemoveMeMembership={
                                                onRemoveMeMebership
                                            }></MemberGroupItem>
                                    ))}
                            </IonList>
                        )}
                    </IonCardContent>
                </IonCard>

                <IonActionSheet
                    mode="ios"
                    className="member-group-actions"
                    isOpen={isOpenMembershipLevelAction}
                    header={`Opciones para ${memberSelected.user?.name}`}
                    backdropDismiss={false}
                    buttons={dinamicActions}
                    onDidDismiss={({ detail }) =>
                        onHandlerSelectMembershipLevelAction(detail)
                    }></IonActionSheet>

                <div className="footer-pattern"></div>
            </IonContent>
        </IonPage>
    );
};

export default GroupDetails;
