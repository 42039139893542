import React from "react";
import { IonModal, IonButton } from "@ionic/react";
import "./ModalDelete.scss";

interface ModalDeleteProps {
 isOpen: boolean;
  closeModal: () => void;
  redirectToLogin: () => void;
}

const ModalDelete: React.FC<ModalDeleteProps> = ({
  isOpen,
  closeModal,
  redirectToLogin,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      className="ModalDelete"
    >
      <div className="wrapper">
        <p className="TitleDelete">Advertencia</p>
        <p className="textDelete">
          Tu cuenta ha sido eliminada correctamente.
        </p>
        <IonButton className="buttonGoBack" onClick={() => {
          closeModal();
          redirectToLogin();
          }}>
          Aceptar
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalDelete;
