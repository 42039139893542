import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

/**
 * create adapter for the notices entity
 */
const noticesAdapter = createEntityAdapter<any>({
    selectId: (notices) => notices.notice_id,
    sortComparer: (noticesA, noticesB) =>
        noticesA.notice_id - noticesB.notice_id,
});

/**
 * notices slice definitions
 */
export const noticesSlice = createSlice({
    name: "notices",
    initialState: noticesAdapter.getInitialState(),
    reducers: {
        addManynotices: noticesAdapter.addMany,
        addOnenotices: noticesAdapter.addOne,
        upsertOnenotices: noticesAdapter.upsertOne,
        upsertManynotices: noticesAdapter.upsertMany,
        setAllNotices: noticesAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return noticesAdapter.getInitialState();
        });
    },
});

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManynotices,
    addOnenotices,
    upsertOnenotices,
    upsertManynotices,
    setAllNotices,
} = noticesSlice.actions;

/**
 * export reducer autegenerated by toolkit
 */
export default noticesSlice.reducer;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = noticesAdapter.getSelectors(
    (state: any) => state.entities.notices
);

/**
 * select for recover all notices entities
 */
export const selectnoticesEntities = (state: any) =>
    globalizedSelectors.selectEntities(state);

/**
 * select for recover all notices
 */
export const selectAllnotices = (state: any) =>
    globalizedSelectors.selectAll(state);

/**
 * select for recover one notices by id
 */
export const selectnoticesById = (id: any) => (state: any) =>
    globalizedSelectors.selectById(state, id);
