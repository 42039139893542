import {
    IonCard,
    IonContent,
    IonIcon,
    IonLabel,
    IonPage,
    IonAccordion,
    IonAccordionGroup,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonImg,
    IonButton,
    IonTextarea,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useState } from "react";
import HomeToolBar from "../../../../components/HomeToolBar";
import {
    selectAllIncidents,
    upsertOneIncident,
} from "../../../../redux/slices/entities/incidents";
import { useSelector } from "react-redux";
import { selectViewModel } from "./selectors";
import RedDot from "./../../../../assets/icons/dot_red.svg";
import Check from "./../../../../assets/icons/check.svg";
import Mapa from "./../../../../assets/icons/vermapa.png";
import { DateTime } from "luxon";
import "./style.scss";

import { useFeedback } from "../../../../hooks";
import Feedback from "../../../../services/feedback";
import escudoWebAPI from "../../../../services/escudoWebAPI";
import { alertCircle, arrowForward } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { selectAuthenticatedUserId } from "../../../../redux/slices/auth";
import { selectMeActivedMemberships } from "../../../../redux/slices/entities/memberships";
import { MEMBERSHIPS } from "../../../../constants";
import { useHistory } from "react-router";

const IncidentItem: React.FC<{
    item: any;
    onSave: any;
    onSaveSolved: any;
}> = ({ item, onSave, onSaveSolved }) => {
    const history = useHistory();

    const incident = item.incident;
    let catalog = item.catalog;

    let userIncident = item?.user;

    const onChangeInput = (event: any) => {
        setDescription(event.detail.value);
    };

    const [description, setDescription] = useState("");

    const onSaveDescription = () => {
        onSave({
            description,
            id: incident.incident_id,
        });
    };

    const onSaveSolvedincident = () => {
        onSaveSolved({
            id: incident.incident_id,
        });
    };

    const userId = useSelector(selectAuthenticatedUserId);

    const meMemberships = useSelector(selectMeActivedMemberships);
    const membrship = meMemberships.find(
        (membeship: any) => membeship.group_id == incident.group_id
    );
    const isAdmin = membrship?.type === MEMBERSHIPS.ADMINISTRATOR;

    const redirectToMap = () => {
        if (incident && incident.longitude !== 0 && incident.latitude !== 0) {
            history.push("/app/incidents/map", {
                longitude: incident.longitude,
                latitude: incident.latitude,
            });
        } else {
            console.error(
                "Las coordenadas del incidente no están definidas correctamente."
            );
        }
    };
    return (
        <IonAccordion value={incident.incident_id}>
            <div slot="header">
                <IonGrid className="grid-incident">
                    <IonRow>
                        <IonCol
                            className="col-incident"
                            size="12"
                            style={{
                                background: catalog.color,
                            }}>
                            <IonText className="folio-incident">
                                {incident.invoice}
                                &nbsp;
                            </IonText>
                            <IonText className="name-user">
                                • {userIncident?.name} {userIncident?.last_name}
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="row-incident">
                        <IonCol size="3">
                            <IonIcon
                                className="icon-catalog"
                                src={"assets/icon/" + catalog.icon + ".svg"}
                            />
                        </IonCol>
                        <IonCol size="7.5">
                            <IonLabel className="date-incident">
                                {DateTime.fromSQL(incident.created_at)
                                    .setLocale("es")
                                    .toLocaleString(DateTime.DATE_MED)}
                            </IonLabel>
                            <IonLabel className="time-incident">
                                {" "}
                                •{" "}
                                {DateTime.fromSQL(incident.created_at)
                                    .setLocale("es")
                                    .toLocaleString({
                                        hour: "numeric",
                                        minute: "2-digit",
                                        hour12: true,
                                    })}
                            </IonLabel>
                            <div>
                                <IonLabel
                                    className="type-incident"
                                    style={{
                                        color: catalog.color,
                                    }}>
                                    {catalog.title}
                                </IonLabel>
                            </div>
                            <div>
                                <IonLabel className="address-incident">
                                    {incident?.address}
                                </IonLabel>
                            </div>
                        </IonCol>
                        <IonCol className="ion-align-items-center ion-justify-content-center col-icon">
                            <IonIcon
                                className="icon-resolved"
                                src={
                                    incident.solved ? Check : RedDot
                                }></IonIcon>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            <div slot="content">
                <IonGrid>
                    <IonRow>
                        <IonCol
                            size="12"
                            className="description-incident ion-text-center">
                            {incident.description ? incident.description : null}
                        </IonCol>

                        {incident.description === null &&
                            !incident.solved &&
                            userId === incident.user_id && (
                                <IonCol
                                    size="12"
                                    className="description-incident">
                                    <IonCard className="card-description">
                                        <IonTextarea
                                            label="Descripción"
                                            labelPlacement="floating"
                                            placeholder="* Describe el incidente, de esa manera tus vecinos podrán auxiliarte o pedir ayuda inmediata..."
                                            counter={true}
                                            maxlength={500}
                                            counterFormatter={(
                                                inputLength,
                                                maxLength
                                            ) => `${inputLength}/${maxLength}`}
                                            value={description}
                                            onIonInput={onChangeInput}
                                            rows={4}
                                            className="text-area-description "></IonTextarea>

                                        <IonCard
                                            onClick={onSaveDescription}
                                            disabled={description === ""}
                                            className="card-description">
                                            <div className="direction">
                                                <div
                                                    className="circle"
                                                    style={{
                                                        background:
                                                            catalog.color,
                                                    }}>
                                                    <IonIcon
                                                        icon={
                                                            arrowForward
                                                        }></IonIcon>
                                                </div>
                                            </div>
                                        </IonCard>
                                    </IonCard>
                                </IonCol>
                            )}
                    </IonRow>
                    <IonRow className="flex-row ion-justify-content-center">
                        <IonCol
                            size="6"
                            className="flex-row ion-align-items-center ion-justify-content-center">
                            <IonCol
                                size="12"
                                className="flex-row ion-align-items-center ion-justify-content-center">
                                <IonImg
                                    className="image-incident"
                                    src={Mapa}
                                    onClick={() => redirectToMap()}></IonImg>
                            </IonCol>
                        </IonCol>
                        {((!incident.solved && userId === incident.user_id) ||
                            (!incident.solved && isAdmin)) && (
                            <IonCol
                                size="6"
                                className="flex-row ion-align-items-center ion-justify-content-center">
                                <IonButton
                                    fill="outline"
                                    size="small"
                                    mode="ios"
                                    className="complete-button"
                                    onClick={onSaveSolvedincident}>
                                    Atendido
                                </IonButton>
                            </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
            </div>
        </IonAccordion>
    );
};

const Incidents: React.FC = () => {
    /**
     * Hooks
     */
    const allIncidents = useSelector(selectViewModel);
    const dispatch = useDispatch();

    let allIncidentsa = allIncidents.sort((a: any, b: any) => {
        let aDate = DateTime.fromSQL(a.incident.created_at);
        let bDate = DateTime.fromSQL(b.incident.created_at);

        return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
    });

    const feedbackApp = useFeedback();

    const [present, dismiss] = useIonLoading();
    const [presetAlert] = useIonAlert();

    const descriptionAct = (datafromChild: any) => {
        present({
            message: "Por favor espera",
        });

        escudoWebAPI
            .setIncidentDescription(datafromChild.id, datafromChild.description)
            .then((incident: any) => {
                dispatch(upsertOneIncident(incident.data.data));
                presetAlert({
                    header: "Mensaje enviado",
                    message:
                        "Se ha enviado un mensaje complementario a los miembros del grupo sobre tú emergencia",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                        },
                    ],
                });
            })
            .catch((err: any) => {
                let message = Feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    const statusAct = (statusfromChild: any) => {
        present({
            message: "Por favor espera",
        });

        escudoWebAPI
            .setIncidentSolved(statusfromChild.id, true)
            .then((incident: any) => {
                dispatch(upsertOneIncident(incident.data.data));
                presetAlert({
                    header: "Actualización correcta",
                    message:
                        "Se ha marcado como atendido el incidente " +
                        statusfromChild.id,
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                        },
                    ],
                });
            })
            .catch((err: any) => {
                let message = Feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    return (
        <IonPage className="incidents-page">
            <HomeToolBar title="Incidentes"></HomeToolBar>
            <IonContent className="incidents-content">
                <IonAccordionGroup expand="inset">
                    {allIncidentsa.map((item: any) => {
                        return (
                            <IncidentItem
                                key={item.incident.incident_id}
                                item={item}
                                onSave={descriptionAct}
                                onSaveSolved={statusAct}></IncidentItem>
                        );
                    })}
                </IonAccordionGroup>

                {allIncidentsa.length == 0 && (
                    <div className="ion-margin-top ion-text-center flex-column incidents-not-incidentes ion-justify-content-center ion-align-items-center">
                        <IonIcon className="no-group-icon" src={alertCircle} />
                        <IonText className="ion-margin-top">
                            Aún no cuentas con incidentes
                        </IonText>
                    </div>
                )}
            </IonContent>
        </IonPage>
    );
};

export default Incidents;
