import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import WebAPI from "../../../services/escudoWebAPI";
import { addOneUser, selectAllUsers, upsertOneUser } from "../entities/users";
import { appClear } from "../../actions";
import Feedback from "../../../services/feedback";
import { ROLES } from "../../../constants";
//import Feedback from "../../../service/Feedback";
//import Authentication from "../../../service/Login";
//import Services from "../../../service/Connection";
//import { selectUserById, upsertOneUser as upsertOneUserEnt } from "../entities/users"

/**
 * initial state for auth
 */
let initlaState = {
    token: {
        access_token: null,
        expires_in: null,
        refresh_token: null,
        token_type: null,
    },
    user: {
        user_id: null,
        role: {
            rol_id: null,
            user_id: null,
            user_type: null,
            created_at: null,
            updated_at: null,
        },
    },
    data: {},
    operations: {
        login: "idle",
    },
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: "idle",
        statusOperation: "idle",
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    ui: {},
};

/**
 * auth slice definitions
 */
export const userSlice = createSlice({
    name: "auth",
    initialState: initlaState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserId: (state, actions) => {
            state.user.user_id = actions.payload;
        },
        setRole: (state, actions) => {
            state.user.role = actions.payload;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase("app/clear", (state, action) => {
            return initlaState;
        });

        /**
         * clase for login
         */
        builder.addCase(login.rejected, (state, action) => {
            state.operations.login = "rejected";
            //state.server.feedback = action.payload.feedback
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.operations.login = "fulfilled";
        });
        builder.addCase(login.pending, (state, action) => {
            state.operations.login = "pending";
        });

        /////////////////////// USER UPDATE /////////////////////////

        builder.addCase(updateUser.rejected, (state, action) => {
            state.server.statusOperation = "rejected";
            //state.server.feedback = action.payload.feedback
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.server.statusOperation = "fulfilled";
        });
        builder.addCase(updateUser.pending, (state, action) => {
            state.server.statusOperation = "pending";
        });

        //////////////////// APERMISOS ////////////////

        builder.addCase(updateUserPermissions.rejected, (state, action) => {
            state.server.statusOperation = "rejected";
            //state.server.feedback = action.payload.feedback
        });
        builder.addCase(updateUserPermissions.fulfilled, (state, action) => {
            state.server.statusOperation = "fulfilled";
        });
        builder.addCase(updateUserPermissions.pending, (state, action) => {
            state.server.statusOperation = "pending";
        });

        //////////////////// CONTRASEÑA ////////////////

        builder.addCase(updateUserPassword.rejected, (state, action) => {
            state.server.statusOperation = "rejected";
            //state.server.feedback = action.payload.feedback
        });
        builder.addCase(updateUserPassword.fulfilled, (state, action) => {
            state.server.statusOperation = "fulfilled";
        });
        builder.addCase(updateUserPassword.pending, (state, action) => {
            state.server.statusOperation = "pending";
        });

        //////////////////// NOMBRE DE USUARIO ////////////////

        builder.addCase(updateUserUsername.rejected, (state, action) => {
            state.server.statusOperation = "rejected";
            //state.server.feedback = action.payload.feedback
        });
        builder.addCase(updateUserUsername.fulfilled, (state, action) => {
            state.server.statusOperation = "fulfilled";
        });
        builder.addCase(updateUserUsername.pending, (state, action) => {
            state.server.statusOperation = "pending";
        });
    },
});

/**
 * reducer actions substraid from create reducer
 */
export const { setToken, setUser, setUserId, setRole } = userSlice.actions;

//////////////////// SELECTORES //////////////////

/**
 * Selector for recover the auth slice
 */
export const selectAuth = (state: any) => state.auth;

/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */
export const selectUsersUI = (state: any) => state.auth.ui;

export const selectUsersData = (state: any) => state.auth.data;

export const selectUsersServer = (state: any) => state.auth.server;

export const selectStatusServer = (state: any) =>
    state.auth.server.statusServer;

export const selectStatusOperation = (state: any) =>
    state.auth.server.statusOperation;

/**
 * selector for recover all operations
 */
export const selectOperations = createSelector(
    [selectAuth],
    (auth: any) => auth.operations
);

/**
 * selectpr for recover user authenticated
 */
export const selectAuthUser = createSelector(
    selectAuth,
    (auth: any) => auth.user
);

/**
 * selector para recuperar el rol del usuario logeado
 */
export const selectAuthRole = createSelector(
    selectAuthUser,
    (auth: any) => auth.role
);

/**
 * selector for recover user authentidated id
 */
export const selectAuthenticatedUserId = createSelector(
    selectAuthUser,
    (user) => user.user_id
);

/**
 * Selector for recover login operation
 */
export const selectLoginOperation = createSelector(
    [selectOperations],
    (operations) => operations.login
);

/**
 * Selector for detemite if a user was loged
 */
export const selectIsUserLoged = createSelector(
    [selectAuth],
    (auth) => auth.token.access_token != null
);

/**
 * Selector for recvoer user access token
 */
export const selectAccessToken = createSelector(
    [selectAuth],
    (auth) => auth.token.access_token
);

/**
 * selector para determinar si el usuarios es un superescudo
 */
export const selectIsAuthenticatedUserSuperShield = () =>
    createSelector(
        selectAuthenticatedUserId,
        selectAllUsers,
        (authenticatedUserId, allUsers) => {
            return allUsers.find((user) => user.user_id == authenticatedUserId);
        }
    );

/**
 * selector para determinar si un usuario puede administrar grupos
 */
export const selectCanManageGroups = createSelector(selectAuthRole, (role) => {
    return role?.user_type < ROLES.SHIELD;
});

/**
 * selector para determinar si un usuario es gobernador
 */
export const selectGobUser = createSelector(selectAuthRole, (role) => {
    return role?.user_type == ROLES.SUPERADMIN;
});
/**
 * selector para determinar si un usuario es dependencia
 */
export const selectDependencyUser = createSelector(selectAuthRole, (role) => {
    return role?.user_type == ROLES.ADMIN;
});

export default userSlice.reducer;

/////////////////// TRUNCKS /////////////////////

/**
 * login user
 */
export const login = createAsyncThunk(
    "authUI/user/login",
    async ({ username, password }: any, thunkAPI) => {
        try {
            let token = await WebAPI.login(username, password).then(
                (response: any) => response.data
            );
            let me = await WebAPI.meInformation().then(
                (response: any) => response.data.data
            );
            const roles = await WebAPI.getMeRoles().then(
                (req: any) => req.data.data
            );
            let highestRole = roles.sort((ra: any, rb: any) => {
                return ra.user_type - rb.user_type;
            })[0];

            thunkAPI.dispatch(addOneUser(me));
            thunkAPI.dispatch(setRole(highestRole));
            thunkAPI.dispatch(setUserId(me.user_id));
            thunkAPI.dispatch(setToken(token));

            return {
                token,
                me,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar informacion del usuario
 */
export const logout = createAsyncThunk(
    "authUI/user/loginout",
    async ({ history }: any, thunkAPI) => {
        //let FeedbackService = new Feedback()

        try {
            history.replace("/login");
            thunkAPI.dispatch(appClear());

            return {
                ok: "asd",
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                value: "asd",
                //// feedback: FeedbackService.getMessage(err)
            });
        }
    }
);

/**
 * Register one user
 */
export const signup = createAsyncThunk(
    "authui/user/signup",
    async (data: any, thunkAPI) => {
        try {
            let user = await WebAPI.signup(data).then(
                (response: any) => response.data.data
            );

            return user;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar informacion del usuario
 */
export const updateUser = createAsyncThunk(
    "authUI/user/update-data",
    async ({ userId, userData }: any, thunkAPI) => {
        try {
            // Realiza la solicitud de actualización en el servidor
            const userUpdated = await WebAPI.UpdateUserInfo(
                userId,
                userData
            ).then((res) => res.data.data);

            thunkAPI.dispatch(upsertOneUser(userUpdated));

            // Devuelve la respuesta para que se maneje en el caso de éxito
            return userUpdated;
        } catch (error) {
            // Lanza una excepción para que se maneje en el caso de error
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(error),
            });
        }
    }
);

/**
 * Actualizar los permisos del usuario
 */
export const updateUserPermissions = createAsyncThunk(
    "authUI/user/update-permissions",
    async ({ userId, permissions }: any, thunkAPI) => {
        //let FeedbackService = new Feedback()

        try {
            /* let user = await Services.updateUserById(userId, permissions).then(res => res.data.data)
 
             user["user_id"] = userId
 
             thunkAPI.dispatch(upsertOneUser(user))
 
             if (selectUserById(userId)(thunkAPI.getState())) {
                 thunkAPI.dispatch(upsertOneUserEnt(user))
             }
 
             return {
                 user
             }*/
        } catch (err) {
            /*return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })*/
        }
    }
);

/**
 * Actualizar los permisos del usuario
 */
export const updateUserPassword = createAsyncThunk(
    "authUI/user/update-password",
    async (
        { old_password, password, password_confirmation }: any,
        thunkAPI
    ) => {
        try {
            await WebAPI.UpdateUserPass(
                old_password,
                password,
                password_confirmation
            );

            return {
                data: "ok",
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar nombre de usuario
 */
export const updateUserUsername = createAsyncThunk(
    "authUI/user/update-username",
    async ({ newPhone }: any, thunkAPI) => {
        try {
            await WebAPI.UpdateUserUsename(newPhone);
            return {
                data: "ok",
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    }
);

export const deleteAccount = createAsyncThunk(
    "authUI/user/delete-account",
    async ({ phoneData }: any, thunkAPI) => {
        try {
            const userPhone = phoneData.cellphone;
            await WebAPI.DeleteUserInfo(userPhone);

            return {
                success: true,
                message: "Cuenta eliminada con éxito",
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    }
);
