import { createSelector } from "@reduxjs/toolkit";
import { selectMeGroupsWithMemberships } from "../../../../redux/slices/entities/groups";
import { selectAllUsers } from "../../../../redux/slices/entities/users";

/**
 * Selector para la vista
 */
export const selectGroupDetails = createSelector(
    selectMeGroupsWithMemberships,
    selectAllUsers,
    (allGroups, allUsers) => {
        const groupDetails = allGroups.map((group) => {
            const usersMembership = group.memberships.map((member: any) => {
                const user = allUsers.find(
                    (users) => member.user_id == users.user_id
                );
                return { ...member, user };
            });

            return { ...group, memberships: usersMembership };
        });

        return groupDetails;
    }
);
