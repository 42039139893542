import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import WebAPI from "../../../services/escudoWebAPI";
import Feedback from "../../../services/feedback";
import { APP_EXPIRE_TIME, ROLES } from "../../../constants";
import { setAllCatalogIncidents } from "../entities/catalogincidents";
import { selectAuthenticatedUserId, setRole } from "../auth";
import {
    selectAllgroups,
    setAllGroups,
    upsertOnegroup,
} from "../entities/groups";
import { setAllNotices } from "../entities/notices";
import { setAllNotifications } from "../entities/notifications";
import {
    addOneIncident,
    setAllIncidents,
    upsertOneIncident,
} from "../entities/incidents";
import { addOneMembreship, setAllMembreships } from "../entities/memberships";
import { selectAllUsers, setAllUsers, upsertOneUser } from "../entities/users";
import {
    addOnedependencies,
    selectAlldependencies,
    setAlldependencies,
} from "../entities/dependencies";
import * as _ from "lodash";
import escudoWebAPI from "../../../services/escudoWebAPI";
import { Geolocation } from "@capacitor/geolocation";

/**
 * initial state for auth
 */
let initlaState = {
    isAlarmActive: false,
    alertCurrent: {
        sound: null,
        notice: {
            notice_id: null,
            user_id: null,
            title: null,
            description: null,
            type: null,
            status: false,
            created_at: null,
            updated_at: null,
        },
        category_incident: {
            catalog_incident_id: null,
            title: null,
            priority: null,
            active: false,
            icon: null,
            color: null,
            created_at: null,
            updated_at: null,
        },
        incident: {
            incident_id: null,
            catalog_incident_id: null,
            group_id: null,
            notice_id: null,
            latitude: null,
            longitude: null,
            solved: false,
            description: null,
            address: null,
            invoice: null,
            created_at: null,
            updated_at: null,
            updated_by: null,
            created_by: null,
            user_id: null,
        },
    },
    data: {},
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: "idle",
        statusOperation: "idle",
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    ui: {},
};

/**
 * auth slice definitions
 */
export const appSlice = createSlice({
    name: "app",
    initialState: initlaState,
    reducers: {
        invalidate: (state, action) => {
            state.server.didInvalidate = true;
        },
        setIsAlarmActive: (state, actions) => {
            state.isAlarmActive = actions.payload;
        },
        setAlertCurrent: (state, actions) => {
            state.alertCurrent = actions.payload;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase("app/clear", (state, action) => {
            return initlaState;
        });

        builder.addCase(appSync.rejected, (state: any, action: any) => {
            state.server.statusServer = "rejected";
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(appSync.fulfilled, (state: any, action: any) => {
            state.server.expireIn = new Date().setHours(
                new Date().getHours() + APP_EXPIRE_TIME
            );
            state.server.ferchingAt = Date.now();
            state.server.didInvalidate = false;

            state.server.statusServer = "fulfilled";
        });

        builder.addCase(appSync.pending, (state: any, action: any) => {
            state.server.statusServer = "pending";
        });
    },
});

/**
 * reducer actions substraid from create reducer
 */
export const { invalidate, setIsAlarmActive, setAlertCurrent } =
    appSlice.actions;

//////////////////// SELECTORES //////////////////

/**
 * Selector recuperar el slice de app
 */
export const selectApp = (state: any) => state.app;

/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */
export const selectAppUI = createSelector(selectApp, (app: any) => app.ui);

export const selectAppData = createSelector(selectApp, (app: any) => app.data);

export const selectAppServer = createSelector(
    selectApp,
    (app: any) => app.server
);

export const selectAppStatusServer = createSelector(
    selectAppServer,
    (server: any) => server.statusServer
);

export const selectAppStatusOperation = createSelector(
    selectAppServer,
    (server: any) => server.statusOperation
);

/**
 * Determina si una alerta se encuentra activada
 */
export const selectorIsAlarmActive = createSelector(
    selectApp,
    (app) => app.isAlarmActive
);
export const selectorAlertCurrent = createSelector(
    selectApp,
    (app) => app.alertCurrent
);

/**
 * Recuperar todos los usuarios de la app
 */
export const selectAllUsersData = createSelector(
    selectAllUsers,
    (users) => users
);
/**
 * Recuperar todos los usuarios de la app
 */
export const selectAllGroupData = createSelector(
    selectAllgroups,
    (groups) => groups
);
/**
 * Recuperar todos las dependenicas de la app
 */
export const selectAllDependenciesData = createSelector(
    selectAlldependencies,
    (dependencies) => dependencies
);
/**
 * Recuperar todos las dependenicas de la app
 */
export const selectAllIncidentsData = createSelector(
    selectAlldependencies,
    (dependencies) => dependencies
);

export default appSlice.reducer;

/////////////////// TRUNCKS /////////////////////

/**
 * recover all resoruces for the app
 */
export const appSync = createAsyncThunk<any>(
    "app/sync",
    async (data: any, thunkAPI) => {
        //const { school_id, cycle }: any = data

        try {
            const userId = selectAuthenticatedUserId(thunkAPI.getState());

            let me = await WebAPI.meInformation().then(
                (response: any) => response.data.data
            );
            const catalog = await WebAPI.getCatalogsIncidents().then(
                (req: any) => req.data.data
            );
            let groups = null;
            let allMemberships: any = [];
            let allMembers: any = [];

            try {
                groups = await WebAPI.getGroupsByUser(userId).then(
                    (req: any) => req.data.data
                );

                for (let group of groups) {
                    let memberships = await WebAPI.getMembeshipsByGroup(
                        group.group_id
                    ).then((req: any) => req.data.data);

                    allMemberships = allMemberships.concat(memberships);

                    let members = await WebAPI.getMembersByGroup(
                        group.group_id
                    ).then((req: any) => req.data.data);

                    allMembers = allMembers.concat(members);
                }
            } catch (err) {
                // TODO NOTHING
            }

            const notices = await WebAPI.getNewsByUser(userId).then(
                (req: any) => req.data.data
            );
            const notifications = await WebAPI.getNotificationsByUser(
                userId
            ).then((req: any) => req.data.data);
            const incidents = await WebAPI.getIncidentsByUser(userId).then(
                (req: any) => req.data.data
            );
            const eventualities = await WebAPI.getEventualitiesByUser(
                userId
            ).then((req: any) => req.data.data);

            const meMemberhips = await WebAPI.getMemberhipsByUser(userId).then(
                (req: any) => req.data.data
            );
            const roles = await WebAPI.getMeRoles().then(
                (req: any) => req.data.data
            );
            let role = roles.sort((ra: any, rb: any) => {
                return ra.user_type - rb.user_type;
            })[0];

            thunkAPI.dispatch(setAllCatalogIncidents(catalog));

            if (groups) {
                thunkAPI.dispatch(setAllGroups(groups));
            }
            if (role?.user_type === ROLES.SUPERADMIN) {
                const AllGroups = await escudoWebAPI
                    .getResourcesPaginatedFromServer("AllGroups", [], {
                        per_page: 100,
                    })
                    .then((i) => i.data);
                const AllIncidents = await escudoWebAPI
                    .getResourcesPaginatedFromServer("AllIncidents", [], {
                        per_page: 100,
                    })
                    .then((i) => i.data);
                const AllDependencies = await escudoWebAPI
                    .getResourcesPaginatedFromServer("getAllDependenci", [], {
                        per_page: 100,
                    })
                    .then((i) => i.data);
                const AllMembresips = await escudoWebAPI
                    .getResourcesPaginatedFromServer("Allmemberships", [], {
                        per_page: 100,
                    })
                    .then((i) => i.data);
                const AllUsers = await escudoWebAPI
                    .getResourcesPaginatedFromServer("AllUsers", [], {
                        per_page: 100,
                    })
                    .then((i) => i.data);

                thunkAPI.dispatch(setAllUsers(AllUsers.concat([me])));
                thunkAPI.dispatch(setAllGroups(AllGroups));
                thunkAPI.dispatch(setRole(role));
                thunkAPI.dispatch(
                    setAllIncidents(AllIncidents.concat(eventualities))
                );
                thunkAPI.dispatch(setAlldependencies(AllDependencies));
                thunkAPI.dispatch(
                    setAllMembreships(AllMembresips.concat(allMemberships))
                );
            } else if (role?.user_type === ROLES.ADMIN) {
                const Dependency = await WebAPI.getDependencyById(
                    me.dependency
                ).then((req: any) => req.data.data);

                const MembersForDependency = await escudoWebAPI
                    .getResourcesPaginatedFromServer(
                        "AllUsersTest",
                        [me.dependency],
                        {
                            per_page: 100,
                        }
                    )
                    .then((i) => i.data);

                let GroupsForDependency = await escudoWebAPI
                    .getResourcesPaginatedFromServer(
                        "getGroupsForDependency",
                        [me.dependency],
                        { per_page: 100 }
                    )
                    .then((i) => i.data);

                let incidentsDependency: any = [];

                for (let dependency of GroupsForDependency) {
                    const memberships =
                        await WebAPI.getResourcesPaginatedFromServer(
                            "getMembeshipsByGroup",
                            [dependency.group_id],
                            { per_page: 100 }
                        ).then((i) => i.data);

                    allMemberships = allMemberships.concat(memberships);

                    const incidents =
                        await WebAPI.getResourcesPaginatedFromServer(
                            "getIncidentesByGroup",
                            [dependency.group_id],
                            { per_page: 100 }
                        ).then((i) => i.data);

                    incidentsDependency = incidentsDependency.concat(incidents);
                }

                thunkAPI.dispatch(addOnedependencies(Dependency));

                thunkAPI.dispatch(
                    setAllUsers(MembersForDependency.concat([me]))
                );
                thunkAPI.dispatch(setAllGroups(GroupsForDependency));
                thunkAPI.dispatch(setRole(role));
                thunkAPI.dispatch(setAllMembreships(allMemberships));
                thunkAPI.dispatch(
                    setAllIncidents(incidentsDependency.concat(eventualities))
                );
            } else {
                thunkAPI.dispatch(setAllNotices(notices));
                thunkAPI.dispatch(setAllNotifications(notifications));
                thunkAPI.dispatch(
                    setAllIncidents(incidents.concat(eventualities))
                );
                thunkAPI.dispatch(
                    setAllMembreships(meMemberhips.concat(allMemberships))
                );
                thunkAPI.dispatch(setAllUsers(allMembers.concat([me])));
                thunkAPI.dispatch(setRole(role));
            }
            return { data: catalog };
        } catch (err) {
            console.log(err, "error");

            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }: any) => {
            let { didInvalidate, expireIn } = selectAppServer(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Register a new user in group
 */

export const createUser = createAsyncThunk(
    "app/sync/user-create",
    async ({ data, membershipType, groupId }: any, thunkAPI) => {
        try {
            let userResponse = await WebAPI.signup(data);
            let user = userResponse.data.data;

            let membershipResponse = await WebAPI.createMembership(groupId, {
                cellphone: user.cellphone,
                type: membershipType,
            });
            let membership = membershipResponse.data.data;

            let groupResponse = await WebAPI.getGroupById(groupId);
            let group = groupResponse.data.data;

            thunkAPI.dispatch(upsertOnegroup(group));
            thunkAPI.dispatch(addOneMembreship(membership));
            thunkAPI.dispatch(upsertOneUser(user));

            return { user, membership, group };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    }
);

/**
 * Create one incident in group
 */

export const Createincident = createAsyncThunk(
    "app/sync/Incident-Create",
    async (
        { incidentId, userId, group_id, latitude, longitude }: any,
        thunkAPI
    ) => {
        try {
            let incident = await WebAPI.createIncident(userId, group_id, {
                catalog_incident_id: incidentId,
                latitude,
                longitude,
            }).then((response: any) => response.data.data);

            thunkAPI.dispatch(addOneIncident(incident));
            return { incident };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    }
);

export const CreateEmergenncyIncident = createAsyncThunk(
    "app/sync/Incident-Create/emergencyIncidents",
    async ({ userId, data }: any, thunkAPI) => {
        try {
            let incident = await WebAPI.createEmergencyIncident(
                userId,
                data
            ).then((response: any) => response.data.data);

            thunkAPI.dispatch(addOneIncident(incident));
            return { incident };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    }
);
/**
 * Create incident with description
 */

export const CreateDescriptionToIncident = createAsyncThunk(
    "app/sync/Incident-Create-Description",
    async ({ incidentId, description }: any, thunkAPI) => {
        try {
            let incident = await WebAPI.setIncidentDescription(
                incidentId,
                description
            ).then((response: any) => response.data.data);

            thunkAPI.dispatch(upsertOneIncident(incident));

            return { incident };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: Feedback.getMessage(err),
            });
        }
    }
);
