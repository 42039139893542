import { createAction } from "@reduxjs/toolkit";

/**
 * Accion para pugar aplicacion
 */
export const appClear = createAction('app/clear')

/**
 * Accion para pugar aplicacion
 */
 export const noticesModalDistributinModesReset = createAction('noticesModalUI/distributinModes/reset')

/**
 * Accion para pugar aplicacion
 */
 export const noticesModalUIReset = createAction('noticesModalUI/reset')

