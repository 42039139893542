import { combineReducers } from "redux";
import incidentsSlice from "./incidents";
import notificationsSlice from "./notifications";
import noticesSlice from "./notices";
import catalogincidentsSlice from "./catalogincidents";
import groupsSlice from "./groups";
import statesSlice from "./states";
import countriesSlice from "./countries";
import municipalitiessSlice from "./municipalities";
import usersSlice from "./users";
import membershipsSlice from "./memberships";
import dependenciesSlice from "./dependencies";

/**
 * entities definitions, use like a DATABASE
 */
export const entities = combineReducers<any>({
    incidents: incidentsSlice,
    notifications: notificationsSlice,
    notices: noticesSlice,
    catalogincidents: catalogincidentsSlice,
    memberships: membershipsSlice,
    groups: groupsSlice,
    states: statesSlice,
    countries: countriesSlice,
    municipalities: municipalitiessSlice,
    users: usersSlice,
    dependencies: dependenciesSlice,
});
