import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import Map from "ol/Map";
import View from "ol/View";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Icon, Style, Text, Fill } from "ol/style.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { useSelector } from "react-redux";
import Popup from "ol-popup";
import { selectAuthenticatedUserId } from "../../../../redux/slices/auth";
import { MEMBERSHIPS } from "../../../../constants";
import ToolBar from "../../../../components/ToolBar";
import { selectGroupDetails } from "./selectors";

const DependenciesMap: React.FC = () => {
    const userId = useSelector(selectAuthenticatedUserId);
    const meGroups = useSelector(selectGroupDetails);
    const firstGroup = meGroups[0];
    const membershipsArray = firstGroup ? firstGroup.memberships || [] : [];

    const adminGroup = membershipsArray.find(
        (admin: any) => admin.type == MEMBERSHIPS.ADMINISTRATOR
    );

    const mapRef = useRef<HTMLDivElement | null>(null);
    const [popupState, setPopupState] = useState({
        isVisible: false,
    });

    const durangoCoordinates = {
        latitude: 24.0228,
        longitude: -104.6676,
    };

    const handlerClickMap = (event: any, map: any, popup: any) => {
        if (!popupState.isVisible) {
            popup.hide();
            setPopupState({ isVisible: false });
        }
        const features = map.getFeaturesAtPixel(event.pixel);

        if (features && features.length > 0) {
            const feature = features[0];
            if (feature.get("data")) {
                const coordinate = event.coordinate;
                const group = feature.get("group");
                const member = feature.get("data");
                const imgUser = feature.get("image");
                const userType = feature.get("userT");

                const colorD = feature.get("colorDependencies");
                const NameD = feature.get("NameDependencies");

                const content = `
            <div style="background: #fff; border-radius:20px; width:350px">
                <div style="text-align:center">
                    <div
                        style="background:${colorD}; color: #fff;   
                        border-top-right-radius: 20px;
                        border-top-left-radius: 20px;"
                    >
                        <ion-text style="font-weight: 800">
                        ${group}
                        </ion-text>
                        <ion-text style="font-weight: 800">
                        </ion-text>
                    </div>
                </div>
                <div style="display: flex; margin-top: 10px; margin-bottom: 10px">
                    <div style="flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
                    width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
                    max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);"> 
                    <svg
                    width="66"
                    height="66"
                    style="width: 70px;
                    height: 70px;
                    margin-left: 10px"
                    viewBox="0 0 66 66"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                <path
                        d="M12.705 49.83C15.51 47.685 18.645 45.9932 22.11 44.7546C25.575 43.5182 29.205 42.9 33 42.9C36.795 42.9 40.425 43.5182 43.89 44.7546C47.355 45.9932 50.49 47.685 53.295 49.83C55.22 47.575 56.7193 45.0175 57.7929 42.1575C58.8643 39.2975 59.4 36.245 59.4 33C59.4 25.685 56.8293 19.4557 51.6879 14.3121C46.5443 9.1707 40.315 6.6 33 6.6C25.685 6.6 19.4568 9.1707 14.3154 14.3121C9.1718 19.4557 6.6 25.685 6.6 33C6.6 36.245 7.1368 39.2975 8.2104 42.1575C9.2818 45.0175 10.78 47.575 12.705 49.83ZM33 36.3C29.755 36.3 27.0182 35.1868 24.7896 32.9604C22.5632 30.7318 21.45 27.995 21.45 24.75C21.45 21.505 22.5632 18.7682 24.7896 16.5396C27.0182 14.3132 29.755 13.2 33 13.2C36.245 13.2 38.9818 14.3132 41.2104 16.5396C43.4368 18.7682 44.55 21.505 44.55 24.75C44.55 27.995 43.4368 30.7318 41.2104 32.9604C38.9818 35.1868 36.245 36.3 33 36.3ZM33 66C28.435 66 24.145 65.1332 20.13 63.3996C16.115 61.6682 12.6225 59.3175 9.6525 56.3475C6.6825 53.3775 4.3318 49.885 2.6004 45.87C0.8668 41.855 0 37.565 0 33C0 28.435 0.8668 24.145 2.6004 20.13C4.3318 16.115 6.6825 12.6225 9.6525 9.6525C12.6225 6.6825 16.115 4.3307 20.13 2.5971C24.145 0.8657 28.435 0 33 0C37.565 0 41.855 0.8657 45.87 2.5971C49.885 4.3307 53.3775 6.6825 56.3475 9.6525C59.3175 12.6225 61.6682 16.115 63.3996 20.13C65.1332 24.145 66 28.435 66 33C66 37.565 65.1332 41.855 63.3996 45.87C61.6682 49.885 59.3175 53.3775 56.3475 56.3475C53.3775 59.3175 49.885 61.6682 45.87 63.3996C41.855 65.1332 37.565 66 33 66Z"
                        fill=${colorD}
                    />
                </svg>

                    </div>

                    <div style="    flex: 0 0 calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
                    width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
                    max-width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%); margin-bottom: 10px;">
                        
                    <div>
                        <ion-label
                            style="color: ${colorD}; font-family: Noto Sans;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;"
                        >
                                ${member.user?.name} ${
                    member.user?.last_name
                }  ${
                    member.user?.second_last_name == null
                        ? ""
                        : member.user?.second_last_name
                }
                        </ion-label>
                        <div>
                        <ion-label
                        style="color: #8D82B7;
                        font-family: Noto Sans;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;"
                    >
                        ${userType}
                    </ion-label>
                    </div>
                        </div>
                        <ion-label style="color: #8D82B7;
                        font-family: Noto Sans;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;">
                        Tel.:  ${member.user.cellphone}
                        <div style="margin-right: 10px" >
                            <ion-label style="color: #8D82B7;
                            font-family: Noto Sans;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;">
                            Domicilio:  Calle  ${member.user.street} 
                                        No. ${member.user?.outside_number}
                                        ${
                                            member.user?.inside_number == null
                                                ? ""
                                                : member.user?.inside_number
                                        }
                                        ,  Colonia ${member.user?.colony}, 
                                        C.p. ${member.user?.zipcode} 
                            </ion-label>
                        </div>
                    </div>
                </div>

            `;

                if (!popupState.isVisible) {
                    popup.show(coordinate, content);
                    setPopupState({ isVisible: true });
                }
            }
        }
    };

    useEffect(() => {
        if (mapRef.current && meGroups.length > 0) {
            const vectorSource = new VectorSource();
            const vectorLayer = new VectorLayer({ source: vectorSource });

            const map = new Map({
                target: mapRef.current,
                layers: [
                    new TileLayer({ source: new OSM({ attributions: [] }) }),
                    vectorLayer,
                ],
                view: new View({
                    center: fromLonLat([
                        durangoCoordinates.longitude,
                        durangoCoordinates.latitude,
                    ]),
                    zoom: 13,
                }),
            });

            const popup = new Popup();
            map.removeOverlay(popup);
            map.addOverlay(popup);

            vectorSource.clear();
            vectorLayer.getSource()?.clear();
            map.render();
            map.renderSync();

            meGroups.forEach((group) => {
                group.memberships.forEach((membership: any) => {
                    const user = membership.user;

                    const itsMe = user.user_id == userId;

                    let imgUser = null;
                    let colorUser = null;
                    let nameGroup = null;
                    let userType = null;
                    let colorD = null;
                    let NameD = null;

                    if (itsMe) {
                        imgUser = "assets/icon/me.svg";
                        colorUser = `${colorD}`;
                        nameGroup = "Gobernador";
                        userType = "Gobernador";
                    } else {
                        nameGroup = group.group.name;
                        colorD =
                            group.dependencies && group.dependencies.length > 0
                                ? group.dependencies[0].color
                                : "";
                        NameD =
                            group.dependencies && group.dependencies.length > 0
                                ? group.dependencies[0].invoice
                                : "";

                        const User = encodeURIComponent(`
                                <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" fill="none">
                                    <path d="M12.705 49.83C15.51 47.685 18.645 45.9932 22.11 44.7546C25.575 43.5182 29.205 42.9 33 42.9C36.795 42.9 40.425 43.5182 43.89 44.7546C47.355 45.9932 50.49 47.685 53.295 49.83C55.22 47.575 56.7193 45.0175 57.7929 42.1575C58.8643 39.2975 59.4 36.245 59.4 33C59.4 25.685 56.8293 19.4557 51.6879 14.3121C46.5443 9.1707 40.315 6.6 33 6.6C25.685 6.6 19.4568 9.1707 14.3154 14.3121C9.1718 19.4557 6.6 25.685 6.6 33C6.6 36.245 7.1368 39.2975 8.2104 42.1575C9.2818 45.0175 10.78 47.575 12.705 49.83ZM33 36.3C29.755 36.3 27.0182 35.1868 24.7896 32.9604C22.5632 30.7318 21.45 27.995 21.45 24.75C21.45 21.505 22.5632 18.7682 24.7896 16.5396C27.0182 14.3132 29.755 13.2 33 13.2C36.245 13.2 38.9818 14.3132 41.2104 16.5396C43.4368 18.7682 44.55 21.505 44.55 24.75C44.55 27.995 43.4368 30.7318 41.2104 32.9604C38.9818 35.1868 36.245 36.3 33 36.3ZM33 66C28.435 66 24.145 65.1332 20.13 63.3996C16.115 61.6682 12.6225 59.3175 9.6525 56.3475C6.6825 53.3775 4.3318 49.885 2.6004 45.87C0.8668 41.855 0 37.565 0 33C0 28.435 0.8668 24.145 2.6004 20.13C4.3318 16.115 6.6825 12.6225 9.6525 9.6525C12.6225 6.6825 16.115 4.3307 20.13 2.5971C24.145 0.8657 28.435 0 33 0C37.565 0 41.855 0.8657 45.87 2.5971C49.885 4.3307 53.3775 6.6825 56.3475 9.6525C59.3175 12.6225 61.6682 16.115 63.3996 20.13C65.1332 24.145 66 28.435 66 33C66 37.565 65.1332 41.855 63.3996 45.87C61.6682 49.885 59.3175 53.3775 56.3475 56.3475C53.3775 59.3175 49.885 61.6682 45.87 63.3996C41.855 65.1332 37.565 66 33 66Z" fill="${colorD}"/>
                                </svg>
                            `);

                        switch (membership.type) {
                            case MEMBERSHIPS.CREATOR:
                                imgUser = `data:image/svg+xml;utf8,${User}`;
                                colorUser = colorD;
                                userType = "Creador";
                                break;
                            case MEMBERSHIPS.ADMINISTRATOR:
                                imgUser = `data:image/svg+xml;utf8,${User}`;
                                colorUser = colorD;
                                userType = "Administrador";
                                break;
                            default:
                                imgUser = `data:image/svg+xml;utf8,${User}`;
                                colorUser = colorD;
                                userType = "Invitado";
                                break;
                        }
                    }

                    const iconFeature = new Feature({
                        geometry: new Point(
                            fromLonLat([user.longitude, user.latitude])
                        ),
                        group: nameGroup,
                        data: membership,
                        color: colorUser,
                        image: imgUser,
                        userT: userType,
                        colorDependencies: colorD,
                        NameDependencies: NameD,
                    });

                    const iconStyle = new Style({
                        image: new Icon({
                            anchor: [0.5, 1],
                            anchorXUnits: "fraction",
                            anchorYUnits: "fraction",
                            width: 40,
                            height: 40,
                            src: imgUser,
                        }),
                        text: new Text({
                            text: NameD,
                            offsetY: -45,
                            fill: new Fill({
                                color: colorD,
                            }),
                            font: "bold 12px Noto Sans",
                        }),
                    });

                    iconFeature.setStyle(iconStyle);
                    vectorSource.addFeature(iconFeature);
                });
            });

            const clickHandler = (event: any) =>
                handlerClickMap(event, map, popup);

            map.un("click", clickHandler);
            map.on("click", clickHandler);
        }
    }, [mapRef, meGroups, userId, popupState.isVisible]);

    return (
        <IonPage>
            <ToolBar title="Miembros de Dependencia" />
            <IonContent>
                <div
                    ref={mapRef}
                    className="map"
                    style={{ width: "100%", height: "100%" }}
                />
            </IonContent>
        </IonPage>
    );
};

export default DependenciesMap;
