import { IonApp, setupIonicReact } from "@ionic/react";
import { App as AppCapacitor } from '@capacitor/app';
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./style.scss"

//Routes for loading
import Pages from "./pages";

//REDUX
import { Provider } from "react-redux";
import store from "./redux";
import { PersistGate } from 'redux-persist/integration/react'
import AnimatedGate from "./components/loading/AnimatedGate";
import { useEffect } from "react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

const storeI = store()


setupIonicReact();

const App: React.FC = () => {

    useEffect(() => {
        if (Capacitor.getPlatform() == 'ios') {
            AppCapacitor.addListener('appUrlOpen', data => {
              if (data.url.indexOf('closewindowfromrecoveraccount://')> -1) {
                Browser.close();
              }
            });
          }
    })

    return (
        <Provider store={storeI.store}>
            <PersistGate persistor={storeI.persistor}>
                {(bootstrapped) => (
                    <AnimatedGate bootstrapped={bootstrapped}>
                        <IonApp>
                            <Pages />
                        </IonApp>
                    </AnimatedGate>
                )}
            </PersistGate>
        </Provider>
    );
};

export default App;
