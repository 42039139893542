import { useEffect, useState } from "react"
import { Capacitor } from "@capacitor/core"
import { App } from "@capacitor/app"

/**
 * Hook for register user in onesingla
 */
let useAppInfo = () => {

    const [appEnviroment, setAppEnviroment] = useState({mode: process.env.REACT_APP_NAME, version: '0.0.0'});

    /**
     * Recover app version
     */
    useEffect(function () {
      if (Capacitor.isNativePlatform()) {
        const getInfo = async ()  => {
          let AppInfo = await App.getInfo()
  
          setAppEnviroment({
            ...appEnviroment,
            version: AppInfo.version
          })
        }
  
        getInfo()
  
      }
    }, [])

    return appEnviroment
}

export default useAppInfo