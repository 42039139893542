/**
 * Clase encargada de la autenticacion y tipos de usuarios
 */
class Feedback {
    constructor() {
        this.errors = {
            // Alumnos 3100 - 3199
            3100: {
                title: "El alumno ya pertenece al grupo",
                message: "",
            },
            3101: {
                title: "El alumno ya tiene grupo principal",
                message: "",
            },
            3102: {
                title: "El alumno no pertenece grupo",
                message: "",
            },
            3103: {
                title: "El alumno y el grupo no pertenecen a la misma escuela",
                message: "",
            },
            // Grupos 5900 - 5999
            5900: {
                title: "El grupo ya se encuentra registrado",
                message: "Los grupos no pueden repetirse",
            },
            5901: {
                title: "El grupo no le pertenece a la escuela",
                message:
                    "El grupo enviado no se encuntra o no pertenece a la escuela",
            },
            5902: {
                title: "El grupo no puede ser eliminado, ya que contiene calificaciones o materias",
                message: "Los grupos tienen recursos relacionados",
            },
            5903: {
                title: "El grupos no puede cambiar de nivel, ya que este contiene, calificaciones o asistencias",
                message: "Los grupos tienen recursos relacionados",
            },
            5904: {
                title: "El grupo no puede dejar de tener un orientador ya que existen expedientes",
                message: "Eliminar expedientes",
            },
            5905: {
                title: "Los grupos son incompatibles",
                message: "Los grupos no son del mismo tipo, especial o normal",
            },
            5910: {
                title: "El grupo especial no puede recibir alumnos, ya que aún no cuenta con materias",
                message:
                    "El grupo especial no puede recibir alumnos, ya que aún no cuenta con materias",
            },
            // Parciales 6100 - 6199
            6100: {
                title: "El parcial ya no puede puede ser modificado",
                message: "El parcial ya ha sido eliminado",
            },
            6101: {
                title: "El parcial ya se encuentra registrado",
                message: "Los parciales no pueden repetirse",
            },
            6102: {
                title: "El parcial no puede cambiar de nivel, ya que este contiene, calificaciones o asistencias",
                message: "Los parciales tienen recursos relacionados",
            },
            6103: {
                title: "Los rangos de fechas entran en conflicto con otro parcial",
                message: "Los parciales no pueden tener fechas superpuestas",
            },
            6104: {
                title: "El parcial no puede ser eliminado, ya que contiene calificaciones o asistencias",
                message: "Los parciales tienen recursos relacionados",
            },

            4022: {
                title: "La escuela aun no tiene ningun parcial",
                message: "Los parciales tienen recursos relacionados",
            },
            4024: {
                title: "La fecha ingresada no concuerda con ningun parcial",
                message: "Los parciales tienen recursos relacionados",
            },
            // Materias
            3001: {
                title: "El alumno ya pertenece al grupo",
                message: "Los parciales tienen recursos relacionados",
            },
            3005: {
                title: "Las materias no tienen una relacion correcta",
                message:
                    "El formato de tranferencia de materias no tiene la relacion correcta",
            },

            // OTROS
            1025: {
                title: "El recurso ya no puede ser eliminado/deshactivar",
                message: "El recurso ya no puede ser eliminado/deshactivar",
            },
        };
    }

    /**
     * Muestra un mensaje de error generico
     *
     * @param subHeader Subtitulo
     * @param Message Contenido del mensaje
     * @param Message Botones
     */
    async showGenericMessage(subHeader, Message, buttons) {}

    /**
     * Funcion encargada de manejar los errores de la app
     *
     * @param err Error a ser manejado
     * @param button Boton de accion
     */
    handlerError(err, button = "Aceptar") {
        let message = this.getMessage(err);

        this.showGenericMessage(message.title, message.message, button);
    }

    /**
     * Obtenemos un mensaje
     *
     * @param err Error de donde se obtendran los datos
     *
     * @returns Object Objecto de error
     */
    getMessage(err) {
        let message = {
            title: "Se produjo un problema",
            message: "Por favor intente de nuevo",
        };

        if (typeof err.response === "undefined") {
            return message;
        }

        let error = err.response.data;

        if (
            typeof error.message === "string" ||
            error.message instanceof String
        ) {
            return {
                title: error.message,
                message: error.message,
            };
        } else {
            return {
                title: Object.values(error.message)[0][0],
                message: Object.values(error.message)[0][0],
            };
        }
    }

    /**
     * Retornamos un mensaje usando una promesa
     *
     * @param {*} err
     */
    async getMessageAsPromise(err) {
        // typeof err.response.data.hasOwnProperty('type')

        if (this.isInternalError(err)) {
            return {
                title: "Se produjo un problema",
                message: "Por favor intente de nuevo",
            };
        }

        return this.convertBlob(err.response.data).then((ServerMessage) => {
            err.response.data = ServerMessage;

            return this.getMessage(err);
        });
    }

    isInternalError(err) {
        return typeof err.response === "undefined";
    }

    /**
     * Convierte una respuesta blob en Objecto de js
     *
     * @param {*} blob
     */
    async convertBlob(blob) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();

            fr.onload = (e) => {
                resolve(JSON.parse(e.target.result));
            };

            fr.readAsText(blob);
        });
    }
}

export default new Feedback();
