import React from "react";
import {
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonLabel,
    IonPage,
    IonRow,
    useIonLoading,
    useIonAlert,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { selectViewModel } from "./selectors";
import "./style.scss";
import ToolBar from "../alerts/components/ToolBar";
import Feedback from "../../../../services/feedback";
import escudoWebAPI from "../../../../services/escudoWebAPI";
import { useFeedback } from "../../../../hooks";
import { upsertOneNotifications } from "../../../../redux/slices/entities/notifications";
import { NOTICE_TYPE } from "../../../../constants";

const Notifications: React.FC = () => {
    const allNotices = useSelector(selectViewModel);

    const ActiveNotices = allNotices.filter(
        (item) => item.notification.aware === false
    ).length;

    const dispatch = useDispatch();

    const feedbackApp = useFeedback();

    const [present, dismiss] = useIonLoading();
    const [presetAlert] = useIonAlert();

    const awareAct = (data: any) => {
        present({
            message: "Por favor espera",
        });

        escudoWebAPI
            .setNotificationAware(data.notification_id, true)
            .then((notification: any) => {
                dispatch(upsertOneNotifications(notification.data.data));
                presetAlert({
                    header: "Actualización Exitosa",
                    message:
                        "Se ha cambiado el estatus de la notificación a enterado",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                        },
                    ],
                });
            })
            .catch((err: any) => {
                let message = Feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    const markAllAsRead = () => {
        present({
            message: "Por favor espera",
        });

        const notificationIds = allNotices.map(
            (item) => item.notification.notification_id
        );

        Promise.all(
            notificationIds.map((notificationId) =>
                escudoWebAPI.setNotificationAware(notificationId, true)
            )
        )
            .then((notifications: any) => {
                notifications.forEach((notification: any) => {
                    dispatch(upsertOneNotifications(notification.data.data));
                });

                presetAlert({
                    header: "Actualización Exitosa",
                    message: "Se han marcado todos los mensajes como leídos",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                        },
                    ],
                });
            })
            .catch((err: any) => {
                let message = Feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    return (
        <IonPage className="notifications-page">
            <ToolBar title="Notificaciones"></ToolBar>
            <IonContent className="notification-content">
                <IonGrid>
                    <IonRow>
                        <IonCol
                            size="6"
                            className="flex-row ion-justify-content-start description-notification">
                            <IonLabel>Últimas notificaciones</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonLabel className="flex-row ion-justify-content-end count-notice">
                                {ActiveNotices} Mensajes sin leer
                            </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                            {ActiveNotices >= 2 && (
                                <IonLabel
                                    className="flex-row ion-justify-content-center  description-notification"
                                    onClick={markAllAsRead}>
                                    Marcar todos como leídos
                                </IonLabel>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {allNotices.map((item) => (
                    <IonCard
                        key={item.notice.notice_id}
                        disabled={item.notification.aware}
                        onClick={() => awareAct(item.notification)}
                        className="card-notification">
                        <IonGrid>
                            <IonRow>
                                <IonCol
                                    size="4"
                                    className="flex-row ion-justify-content-center ion-align-items-center">
                                    <IonIcon
                                        className="icon-catalog"
                                        src={
                                            item.notice.type !=
                                            NOTICE_TYPE.NOTICE
                                                ? "assets/icon/" +
                                                  item.catalog.icon +
                                                  ".svg"
                                                : "assets/icon/aviso.svg"
                                        }
                                    />
                                </IonCol>
                                <IonCol>
                                    <IonLabel className="date-notification">
                                        {DateTime.fromSQL(
                                            item.notice.created_at
                                        )
                                            .setLocale("es")
                                            .toLocaleString(DateTime.DATE_MED)}
                                    </IonLabel>
                                    <IonLabel className="time-notification">
                                        {" "}
                                        •{" "}
                                        {DateTime.fromSQL(
                                            item.notice.created_at
                                        )
                                            .setLocale("es")
                                            .toLocaleString({
                                                hour: "numeric",
                                                minute: "2-digit",
                                                hour12: true,
                                            })}
                                    </IonLabel>
                                    <div>
                                        <IonLabel
                                            className="type-notification"
                                            style={{
                                                color:
                                                    item.notice.type !=
                                                    NOTICE_TYPE.NOTICE
                                                        ? item.catalog.color
                                                        : "#8400E6",
                                            }}>
                                            {item.notice.title}
                                        </IonLabel>
                                    </div>
                                    {item.notice.type == NOTICE_TYPE.NOTICE && (
                                        <div>
                                            <IonLabel className="description-notification">
                                                {item.notice.description}
                                            </IonLabel>{" "}
                                        </div>
                                    )}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCard>
                ))}
            </IonContent>
        </IonPage>
    );
};

export default Notifications;
