import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

/**
 * create adapter for the Notifications entity
 */
const notificationsAdapter = createEntityAdapter<any>({
    selectId: (notifications) => notifications.notification_id,
    sortComparer: (notificationsA, notificationsB) =>
        notificationsA.notification_id - notificationsB.notification_id,
});

/**
 * notifications slice definitions
 */
export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: notificationsAdapter.getInitialState(),
    reducers: {
        addManyNotifications: notificationsAdapter.addMany,
        addOneNotifications: notificationsAdapter.addOne,
        upsertOneNotifications: notificationsAdapter.upsertOne,
        upsertManyNotifications: notificationsAdapter.upsertMany,
        setAllNotifications: notificationsAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return notificationsAdapter.getInitialState();
        });
    },
});

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManyNotifications,
    addOneNotifications,
    upsertOneNotifications,
    upsertManyNotifications,
    setAllNotifications,
} = notificationsSlice.actions;

/**
 * export reducer autegenerated by toolkit
 */
export default notificationsSlice.reducer;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = notificationsAdapter.getSelectors(
    (state: any) => state.entities.notifications
);

/**
 * select for recover all Notifications entities
 */
export const selectNotificationsEntities = (state: any) =>
    globalizedSelectors.selectEntities(state);

/**
 * select for recover all Notifications
 */
export const selectAllNotifications = (state: any) =>
    globalizedSelectors.selectAll(state);

/**
 * select for recover one Notifications by id
 */
export const selectNotificationsById = (id: any) => (state: any) =>
    globalizedSelectors.selectById(state, id);
