import React from "react";
import { IonModal, IonButton } from "@ionic/react";
import "./style.scss";
const GlobalModal: React.FC<any> = ({
    isOpen,
    title,
    text,
    header,
    onGoPermissions,
    onClose,
}) => {
    /**
     *  Funcion para la bandera
     */
    const handleGoPermissions = () => {
        onGoPermissions();
    };
    return (
        <IonModal
            isOpen={isOpen}
            backdropDismiss={false}
            className="GlobalModal">
            <div className="wrapper">
                <p className="Title">
                    {title} <p>{header}</p>
                </p>
                <p className="text"></p>
                <p className="text">
                    <span>{text}</span>
                </p>
                <div className="ion-text-center ion-justify-content-center ion-align-items-center">
                    <IonButton mode="ios" size="small" onClick={onClose}>
                        Ok
                    </IonButton>
                    <IonButton
                        mode="ios"
                        size="small"
                        onClick={handleGoPermissions}>
                        Ir a la pantalla de permisos
                    </IonButton>
                </div>
            </div>
        </IonModal>
    );
};

export default GlobalModal;
