import React, { useState } from "react";
import FeedbackContext from "./FeedbackContext";
import UserFeedback from "../components/UserFeedback";

/**
 * Componente para proporcionar el servicio de Feedback
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const FeedbackProvider = (props) => {
    const { children } = props

    const [message, setMessage] = useState({
        open: false,
        title: '',
        message: ''
    })

    const showFeedback = message => {
        setMessage({
            message: message?.message,
            title: message?.title,
            open: true
        })
    }

    const closeFeedback = () => {
        setMessage({
            message: '',
            title: '',
            open: false
        })
    }

    return (
        <FeedbackContext.Provider value={{
            showFeedback,
            closeFeedback,
            message: message.message,
            title: message.title,
            open: message.open
        }}>
            <UserFeedback />
            {children}
        </FeedbackContext.Provider>)
}

export default FeedbackProvider