import { IonContent, IonPage, useIonAlert, useIonLoading } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import HomeToolBar from "../../../../components/HomeToolBar";
import Map from "ol/Map";
import View from "ol/View";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Fill, Icon, Style } from "ol/style.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { Geolocation } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core";
import { selectViewModel } from "./selectors";
import { useDispatch, useSelector } from "react-redux";
import Popup from "ol-popup";
import { DateTime } from "luxon";
import RedDot from "./../../../../assets/icons/dot_red.svg";
import GreenDot from "./../../../../assets/icons/dot_green.svg";
import { selectAuthenticatedUserId } from "../../../../redux/slices/auth";
import { selectMeActivedMemberships } from "../../../../redux/slices/entities/memberships";
import { MEMBERSHIPS } from "../../../../constants";
import escudoWebAPI from "../../../../services/escudoWebAPI";
import { useFeedback } from "../../../../hooks";
import { upsertOneIncident } from "../../../../redux/slices/entities/incidents";
import Feedback from "../../../../services/feedback";
import CircleStyle from "ol/style/Circle";

const MapPage: React.FC = () => {
    const mapRef = useRef<HTMLDivElement | null>(null);
    const [location, setLocation] = useState({
        latitude: 0,
        longitude: 0,
    });

    const allIncidents = useSelector(selectViewModel);
    const [popupState, setPopupState] = useState({
        isVisible: false,
        incidentData: null,
    });
    const userId = useSelector(selectAuthenticatedUserId);
    const meMemberships = useSelector(selectMeActivedMemberships);
    const dispatch = useDispatch();

    const feedbackApp = useFeedback();
    let updateIncident = null;

    const [present, dismiss] = useIonLoading();
    const [presetAlert] = useIonAlert();

    const statusAct = (id: any, feature: any) => {
        present({
            message: "Por favor espera",
        });

        escudoWebAPI
            .setIncidentSolved(id, true)
            .then((incident: any) => {
                dispatch(upsertOneIncident(incident.data.data));
                presetAlert({
                    header: "Actualización correcta",
                    message: "Se ha marcado como atendido el incidente " + id,
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                        },
                    ],
                });
            })
            .catch((err: any) => {
                let message = Feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    useEffect(() => {
        const getLocaion = async () => {
            const locationPermissions = await Geolocation.checkPermissions();

            if (Capacitor.getPlatform() == "web") {
                if (locationPermissions.location == "denied") {
                    //setIsModalOpen(true);

                    return;
                }
            } else {
                if (locationPermissions.location != "granted") {
                    let permisionReuqest =
                        await Geolocation.requestPermissions();

                    let { location } = permisionReuqest;

                    if (location == "denied") {
                        //setIsModalOpen(true);

                        return;
                    }
                }
            }
        };

        getLocaion();
    }, []);

    const durangoCoordinates = {
        latitude: 24.0228,
        longitude: -104.6676,
    };
    const [map, setMap] = useState(null);

    const handlerClickMap = (event: any, map: any, popup: any) => {
        if (!popupState.isVisible) {
            popup.hide();
            setPopupState({ isVisible: false, incidentData: null });
        }
        const features = map.getFeaturesAtPixel(event.pixel);

        if (features && features.length > 0) {
            const feature = features[0];
            if (feature.get("data")) {
                const coordinate = event.coordinate;
                const incidentData = feature.get("data");

                const membrship = meMemberships.find(
                    (membeship: any) =>
                        membeship.group_id == incidentData.incident.group_id
                );
                const isAdmin = membrship?.type === MEMBERSHIPS.ADMINISTRATOR;

                const content = `
            <div style="background: #fff; border-radius:20px; width:350px">
                <div style="text-align:center">
                    <div
                        style="background: ${
                            incidentData.catalog.color
                        }; color: #fff;     border-top-right-radius: 20px;
                        border-top-left-radius: 20px;"
                    >
                        <ion-text style="font-weight: 800">
                            ${incidentData.incident.invoice} •
                        </ion-text>
                        <ion-text class="name-user">
                             ${incidentData.user?.name} ${
                    incidentData.user?.last_name
                }
                        </ion-text>
                    </div>
                </div>
                <div style="display: flex; margin-top: 10px; margin-bottom: 10px">
                    <div style="    flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
                    width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
                    max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%); display: flex; align-items:center;
                    justify-content: center; margin-left: 10px; border-radius: 100%; margin-right: 10px;">
                    <div style="border-radius: 100%; width: 85px; height: 85px;
                    display: flex; align-items:center;
                    justify-content: center;
                    background:${incidentData.catalog.color} 
                    ">
                        <ion-icon
                            style="width: 55px;
                            height: 55px;                      
                            "
                            src=${
                                "assets/icon/" +
                                incidentData.catalog.icon +
                                "_disabled.svg"
                            }
                        ></ion-icon>
                        </div>
                    </div>
                    <div style="    flex: 0 0 calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
                    width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
                    max-width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%); margin-bottom: 10px">
                        <ion-label style="color: #8D82B7;
                        font-family: Noto Sans;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;">
                            ${DateTime.fromSQL(incidentData.incident.created_at)
                                .setLocale("es")
                                .toLocaleString(DateTime.DATE_MED)}
                        </ion-label>
                        <ion-label style="color: #8D82B7;
                        font-family: Noto Sans;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;">
                            
                            •
                            ${DateTime.fromSQL(incidentData.incident.created_at)
                                .setLocale("es")
                                .toLocaleString({
                                    hour: "numeric",
                                    minute: "2-digit",
                                    hour12: true,
                                })}
                        </ion-label>
                        <div>
                            <ion-label
                                style="color: ${
                                    incidentData.catalog.color
                                }; font-family: Noto Sans;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;"
                            >
                                ${incidentData.catalog.title}
                            </ion-label>
                        </div>
                        <div>
                            <ion-label style="color: #8D82B7;
                            font-family: Noto Sans;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;">
                                ${incidentData.incident?.address}
                            </ion-label>
                        </div>
                    </div>
                </div>

            <div class="ion-row">
                <div  style="flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%); text-align: center; color: #8D82B7;
                font-family: Noto Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400; margin-bottom: 10px">
                    ${
                        incidentData.incident.description
                            ? incidentData.incident.description
                            : ""
                    }
                </div>

            </div>
            <div class="ion-row flex-row ion-justify-content-center">
                ${
                    (!incidentData.incident.solved &&
                        userId === incidentData.user.user_id) ||
                    (!incidentData.incident.solved && isAdmin)
                        ? `
                        <div style="flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);     align-items: center !important;     display: flex;
                        flex-direction: row;     justify-content: center !important;     margin-bottom: 15px;">
                        <button
                        type="button"
                        style="width: 130px; height: 34px; border: solid; border-radius: 6px; border-color: #00c483; color: #00c483; background: transparent;"
                        id="atendidoButton${incidentData.incident.incident_id}"
                    >
                        Atendido
                    </button>
                     <button id="auxilioButton${incidentData.incident.incident_id}" 
                     type="button" style="width: 130px; height: 34px; border:
                      solid; border-radius: 6px; border-color: ${incidentData.catalog.color}; color: #fff; 
                      background:${incidentData.catalog.color}; margin-left: 10px;">Ir al Auxilio</button>

                </div>
                
                </div>

                `
                        : ""
                }
                <div class="ion-row flex-row ion-justify-content-center" style=" width: 100%;">
                <div style="flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
                max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);     align-items: center !important;     display: flex;
                flex-direction: row;justify-content: center !important;
                background: ${
                    incidentData.incident.solved ? "#8AD44F" : "#FF1F8A"
                };
                color: #fff;
                font-family: Noto-Sans;
                font-size: 18px;
                font-weight: 700;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;">         
                ${
                    incidentData.incident.solved
                        ? "Incidente Atendido"
                        : "Incidente Pendiente"
                }          
                </div>
            </div>
            
            `;

                if (!popupState.isVisible) {
                    popup.show(coordinate, content);
                    setPopupState({ isVisible: true, incidentData });

                    const atendidoButton = document.getElementById(
                        "atendidoButton" + incidentData.incident.incident_id
                    ) as HTMLButtonElement;

                    document
                        .getElementById(
                            `auxilioButton${incidentData.incident.incident_id}`
                        )
                        ?.addEventListener("click", () => {
                            const { latitude, longitude } =
                                incidentData.incident;
                            const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
                            window.open(googleMapsUrl, "_blank");
                        });

                    atendidoButton?.addEventListener("click", () => {
                        statusAct(incidentData.incident.incident_id, feature);

                        popup.hide();
                        setPopupState({
                            isVisible: false,
                            incidentData: null,
                        });
                        updateIncident = feature.get("data");
                        updateIncident = {
                            ...updateIncident,
                            incident: {
                                ...updateIncident.incident,
                                solved: true,
                            },
                        };
                        feature.set("data", updateIncident);
                        feature.changed();
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (mapRef.current) {
            const vectorSource = new VectorSource();
            const vectorLayer = new VectorLayer({
                source: vectorSource,
            });

            const map = new Map({
                target: mapRef.current,
                layers: [
                    new TileLayer({
                        source: new OSM({
                            attributions: [],
                        }),
                    }),
                    vectorLayer,
                ],
                view: new View({
                    center: fromLonLat([
                        durangoCoordinates.longitude,
                        durangoCoordinates.latitude,
                    ]),
                    zoom: 7,
                }),
            });

            const popup = new Popup();
            map.removeOverlay(popup);
            map.addOverlay(popup);

            vectorSource.clear();
            vectorLayer.getSource()?.clear();
            map.render();
            map.renderSync();

            allIncidents.forEach((item) => {
                const iconFeature = new Feature({
                    geometry: new Point(
                        fromLonLat([
                            item.incident.longitude,
                            item.incident.latitude,
                        ])
                    ),
                    data: item,
                });

                const circleStyle = new Style({
                    image: new CircleStyle({
                        radius: 30,
                        fill: new Fill({
                            color: item.catalog.color,
                        }),
                        displacement: [0, 17.5], // Ajusta el valor vertical según sea necesario
                    }),
                });

                const iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        anchorXUnits: "fraction",
                        anchorYUnits: "fraction",
                        width: 35,
                        height: 35,
                        src:
                            "assets/icon/" +
                            item.catalog.icon +
                            "_disabled.svg",
                    }),
                });

                const combinedStyle = [circleStyle, iconStyle];

                iconFeature.setStyle(combinedStyle);
                vectorSource.addFeature(iconFeature);

                const iconStateFeature = new Feature({
                    geometry: new Point(
                        fromLonLat([
                            item.incident.longitude,
                            item.incident.latitude,
                        ])
                    ),
                });

                const iconStateStyle = new Style({
                    image: new Icon({
                        anchor: [-8, 28],
                        anchorXUnits: "pixels",
                        anchorYUnits: "pixels",
                        width: 18,
                        height: 18,
                        src: item.incident.solved ? GreenDot : RedDot,
                    }),
                });
                iconStateFeature.setStyle(iconStateStyle);
                vectorSource.addFeature(iconStateFeature);
            });

            const clickHandler = (event: any) =>
                handlerClickMap(event, map, popup);

            map.un("click", clickHandler);

            map.on("click", clickHandler);
        }
    }, [allIncidents, popupState.isVisible]);

    return (
        <IonPage>
            <HomeToolBar title="Mapa"></HomeToolBar>

            <IonContent>
                <div
                    ref={mapRef}
                    className="map"
                    style={{ width: "100%", height: "100%" }}
                />
            </IonContent>
        </IonPage>
    );
};

export default MapPage;
