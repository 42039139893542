import React from "react";
import { IonModal, IonButton } from "@ionic/react";
import "./ModalUpdatePhone.scss";

interface ModalUpdatePhoneProps {
  isOpen: boolean;
  closeModal: () => void;
}

const ModalUpdatePhone: React.FC<ModalUpdatePhoneProps> = ({
  isOpen,
  closeModal,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      className="ModalUpdatePhone"
    >
      <div className="wrapper">
        <p className="ModalUpdatePhoneTitle">Advertencia</p>
        <p className="ModalUpdatePhoneText">
          <span>
            Ha modificado su número de teléfono con éxito. Asegúrese de
            recordar el nuevo número para futuras referencias.
          </span>
          <span>
            La aplicacion se cerrara
          </span>
        </p>
        <IonButton className="buttonGoBack" onClick={closeModal}>
          regresar
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalUpdatePhone;
