import React from "react";
import { IonModal, IonButton } from "@ionic/react";

import "./ModalUpdate.scss";
interface UpdateModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

const UpdateModal: React.FC<UpdateModalProps> = ({ isOpen, closeModal }) => {
    return (
        <IonModal
            isOpen={isOpen}
            backdropDismiss={false}
            className="ModalUpdate">
            <div className="wrapper">
                <p className="TitleModalUpdate">¡Actualización Exitosa!</p>
                <p className="textModalUpdate">
                    <span>
                        Tu información se ha actualizado correctamente. Los
                        cambios que realizaste han sido guardados con éxito en
                        nuestro sistema. Agradecemos tu actualización y estamos
                        aquí para ayudarte en caso de que necesites realizar más
                        cambios en el futuro.
                    </span>
                </p>
                <IonButton className="buttonclose" onClick={closeModal}>
                    Cerrar
                </IonButton>
            </div>
        </IonModal>
    );
};

export default UpdateModal;
