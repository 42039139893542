import { createSelector } from "@reduxjs/toolkit";
import { selectMeGroupsWithMemberships } from "../../../../redux/slices/entities/groups";
import { selectAllUsers } from "../../../../redux/slices/entities/users";
import { selectAlldependencies } from "../../../../redux/slices/entities/dependencies";
import { selectAuthRole } from "../../../../redux/slices/auth";
import { ROLES } from "../../../../constants";

/**
 * Selector para la vista
 */
export const selectGroupDetails = createSelector(
    selectMeGroupsWithMemberships,
    selectAllUsers,
    selectAlldependencies,
    selectAuthRole,
    (allGroupsMemberchips, allUsers, allDependencies, authUser) => {
        if (authUser?.user_type == ROLES.ADMIN) {
            const groupDetails = allGroupsMemberchips.map((group) => {
                const usersMembership = group.memberships
                    .filter((member: any) => member.type !== 1)
                    .map((member: any) => {
                        const user = allUsers.find(
                            (users) => users.user_id == member.user_id
                        );

                        const userDependencies = allDependencies.filter(
                            (dependency: any) => dependency
                        );

                        return { ...member, userDependencies, user };
                    });

                return {
                    ...group,
                    memberships: usersMembership,
                    dependencies: usersMembership[0].userDependencies,
                };
            });

            return groupDetails;
        }
        const groupDetails = allGroupsMemberchips.map((group) => {
            const usersMembership = group.memberships.map((member: any) => {
                const user = allUsers.find(
                    (users) => member.user_id == users.user_id
                );

                const userDependencies = allDependencies.filter(
                    (dependency: any) =>
                        dependency.catalog_dependency_id ===
                        group.group.dependency
                );

                return { ...member, userDependencies, user };
            });

            return {
                ...group,
                memberships: usersMembership,
                dependencies: usersMembership[0].userDependencies,
            };
        });

        return groupDetails;
    }
);
