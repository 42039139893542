import React from "react";
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
    useIonAlert,
} from "@ionic/react";

import userProfile from "../../assets/icons/user-profile.svg";
import bell from "../../assets/icons/bell.svg";

import "./style.scss";
import { useHistory } from "react-router";
import exit from "../../assets/icons/exit.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/auth";
import { selectTotalNotAwareNotices } from "../../pages/tabs/pages/Notifications/selectors";
import { Browser } from "@capacitor/browser";
import Help from "../../assets/icons/HelpIcon.svg";
import { TabToolBarProps } from "./props";

/**
 * Componente que reprecenta la barra de herramientas 
 * del home de la aplicacion
 */
const HomeToolBar: React.FC<TabToolBarProps> = ({ title }) => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    const history = useHistory();
    const [presentAlert] = useIonAlert();

    /**
     * shared state
     */
    const totalNotAwareNotices = useSelector(selectTotalNotAwareNotices);

    /**
     * Manejador para la accion de salir de la aplicacion
     */
    const handlerBackButton = () => {
        presentAlert({
            message: "Deseas salir de la aplicación",
            buttons: [
                {
                    text: "No",
                    role: "cancel",
                },
                {
                    text: "Si",
                    role: "confirm",
                    handler: () => {
                        dispatch<any>(logout({ history }))
                    },
                },
            ],
        });
    };

    /**
     * Accion para ir al modulo de perfil
     */
    const onGoProfile = () => {
        history.push("/app/profile");
    };

    /**
     * Accion para ir al modulo de notificaciones
     */
    const onGoNotifications = () => {
        history.push("/app/notifications");
    };

    /**
     * Accion para ir a pagina soporte
     */
    const onGoSupport = () => {
        const openCapacitorSite = async () => {
            await Browser.open({
                url: process.env.REACT_APP_WEB_API + "/soporte",
            });
        };

        openCapacitorSite();
    };

    return (
        <IonHeader className="shield-tool-bar ion-no-border">
            <IonToolbar className="ion-text-center header">
                <IonButtons slot="start">
                    <IonButton
                        className="back-button"
                        onClick={handlerBackButton}>
                        <IonIcon
                            className="custome"
                            slot="icon-only"
                            icon={exit}></IonIcon>
                    </IonButton>
                    <IonButton onClick={onGoSupport}>
                        <IonIcon
                            className="HelpIcon"
                            slot="icon-only"
                            src={Help}></IonIcon>
                    </IonButton>
                </IonButtons>
                <IonTitle className="title">{title}</IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={onGoNotifications}>
                        <IonIcon slot="icon-only" icon={bell}></IonIcon>
                        {totalNotAwareNotices > 0 && (
                            <IonBadge class="cart-badge icon-label">
                                {totalNotAwareNotices}
                            </IonBadge>
                        )}
                    </IonButton>
                    <IonButton onClick={onGoProfile}>
                        <IonIcon slot="icon-only" icon={userProfile}></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};

export default HomeToolBar;
