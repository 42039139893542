import React from "react";
import { IonModal, IonButton } from "@ionic/react";

import "./ModalWelccome.scss";
interface WelcomeModalProps {
    isOpen: boolean;
    onDismiss: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ isOpen, onDismiss }) => {
    return (
        <IonModal
            isOpen={isOpen}
            backdropDismiss={false}
            className="ModalWelcome">
            <div className="wrapper">
                <p className="TitleModalWelcome">
                    Se han registrado de manera exitosa tus datos.
                </p>
                <p className="textModalWelcome ion-margin-bottom">
                    <span>¡ Bienvenido a Escudo Vecinal !</span>
                </p>
                <IonButton className="buttonclose" onClick={onDismiss}>
                    Comenzar
                </IonButton>
            </div>
        </IonModal>
    );
};

export default WelcomeModal;
