import {
    IonButton,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonPage,
    IonPopover,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { addOnegroup } from "../../../../../../redux/slices/entities/groups";
import "./style.scss";
import {
    callOutline,
    calendarOutline,
    mailOutline,
    eyeOutline,
    eyeOffOutline,
    chevronDownOutline,
    chevronUpOutline,
} from "ionicons/icons";

import ToolBar from "../../../../../../components/ToolBar";
import { useMaskito } from "@maskito/react";
import { DateTime } from "luxon";
import MapComponent from "../../../../../register/pages/MapComponent";
import escudoWebAPI from "../../../../../../services/escudoWebAPI";
import { Geolocation } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core";
import { useDispatch } from "react-redux";
import { useFeedback } from "../../../../../../hooks";
import Feedback from "../../../../../../services/feedback";
import { useHistory } from "react-router";
import { selectAuthenticatedUserId } from "../../../../../../redux/slices/auth";
import { upsertManyMembreships } from "../../../../../../redux/slices/entities/memberships";
import marketInput from "../../../../../../assets/icons/market-input.svg";
import { State } from "../../../../../../models/state";
import { Municipality } from "../../../../../../models/municipality";
import { City } from "../../../../../../models/city";
import { upsertManyUsers } from "../../../../../../redux/slices/entities/users";
import Another_dependenci from "../../../../../../assets/icons/Another_dependenci.svg";
import municipality_dependence from "../../../../../../assets/icons/municipality_dependence.svg";
import State_dependence from "../../../../../../assets/icons/State_dependence.svg";
import { DependencyList } from "../../../../../../models/Dependency";
import anime from "animejs";

const CreateGroups: React.FC = () => {
    /**
     * Hooks
     */
    const phoneMask = useMaskito({
        options: {
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        },
    });
    const dispatch = useDispatch();
    const [present, dismiss] = useIonLoading();
    const feedbackApp = useFeedback();
    const [presetAlert] = useIonAlert();
    const history = useHistory();

    /**
     * global state
     */
    //const meGroups = useSelector(selectMeActivedGroups)
    const userId = useSelector(selectAuthenticatedUserId);

    /**
     * Local state
     */
    const [group, setGroup] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedGender, setSelectedGender] = useState<string>("");
    const [selectedTypeDependency, setSelectedTypeDependency] = useState(null);
    const [selectedDependency, setselectedDependency] = useState<
        DependencyList | any
    >(null);
    const [Dependencies, setAllDependencies] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [filteredDependencies, setFilteredDependencies] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * Estado para los listados de ciudades
     */
    const [states, setAllStates] = useState([]);
    const [allMunicipalities, setAllMunicipalities] = useState([]);
    const [allCities, setAllCities] = useState([]);

    /**
     * Estado para las localidades seleccionadas
     */
    const [selectedState, setSelectedState] = useState<State | null>(null);
    const [selectedMunicipality, setSelectedMunicipality] =
        useState<Municipality | null>(null);
    const [selectedCities, setSelectedCities] = useState<City | null>(null);
    const [showFirstSelect, setShowFirstSelect] = useState(false);

    let [user, setUser] = useState({
        name: "",
        last_name: "",
        second_last_name: "",
        cellphone: "",
        email: "",
        inside_number: "",
        outside_number: "",
        street: "",
        zipcode: "",
        date_birth: "",
        gender: "",
        password: "",
        password_confirmation: "",
        latitude: 0,
        longitude: 0,
        colony: "",
        address_city_id: null,
    });

    /**
     * Efecto inicial
     */
    useEffect(() => {
        const get = async () => {
            const state = await escudoWebAPI
                .getAllStates()
                .then((res: any) => res.data.data);
            setAllStates(state);
        };

        get();
    }, []);

    useEffect(() => {
        if (selectedState !== null) {
            const getMunicipalities = async () => {
                const municipalities = await escudoWebAPI
                    .getMunicipalitiesByState(selectedState.state_id)
                    .then((res: any) => res.data.data);
                setAllMunicipalities(municipalities);
            };

            getMunicipalities();
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedMunicipality !== null) {
            const getCitys = async () => {
                const cities = await escudoWebAPI
                    .getCityByMunicipalite(selectedMunicipality.municipality_id)
                    .then((res: any) => res.data.data);
                setAllCities(cities);
            };

            getCitys();
        }
    }, [selectedMunicipality]);

    const getLocaion = async () => {
        const locationPermissions = await Geolocation.checkPermissions();

        if (Capacitor.getPlatform() === "web") {
            if (locationPermissions.location === "denied") {
                setIsModalOpen(true);
            }
        } else {
            if (locationPermissions.location !== "granted") {
                let permisionReuqest = await Geolocation.requestPermissions();

                let { location } = permisionReuqest;

                if (location === "denied") {
                    setIsModalOpen(true);
                }
            }
        }

        const position = await Geolocation.getCurrentPosition();

        let { latitude, longitude } = position.coords;

        return {
            latitude,
            longitude,
        };
    };

    useEffect(() => {
        getLocaion()
            .then(({ latitude, longitude }: any) => {
                setUser((user: any) => {
                    return {
                        ...user,
                        latitude,
                        longitude,
                    };
                });
            })
            .catch((er) => {
                setIsModalOpen(true);
            });
    }, []);

    /**
     * Cambiar el nombre del grupo
     */
    const onChangeGroupName = (data: any) => {
        let value = data.detail.value;

        setGroup(value);
    };

    /**
     * funcion para crear un grupo
     */
    const onCreateGroup = async () => {
        present({
            message: "Por favor espere",
        });

        storeGroup({
            name: group,
            group_type: 1,
            dependency: selectedDependency,
            user,
        })
            .then((data: any) => {
                dispatch(addOnegroup(data.group));
                dispatch(upsertManyMembreships(data.memberships));
                dispatch(upsertManyUsers(data.members));

                presetAlert({
                    header: "Grupo creado",
                    message: "El grupo fue creado correctamente",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                            handler: () => {
                                history.goBack();
                            },
                        },
                    ],
                });
            })
            .catch((err: any) => {
                let message = Feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    /**
     * almacenar el nuevo grupo
     */
    const storeGroup = async (data: any) => {
        const groupCreated = await escudoWebAPI
            .createGroup(data)
            .then((res: any) => res.data.data);

        const memberships = await escudoWebAPI
            .getMembeshipsByGroup(groupCreated.group_id)
            .then((res: any) => res.data.data);

        const members = await escudoWebAPI
            .getMembersByGroup(groupCreated.group_id)
            .then((res: any) => res.data.data);

        return {
            group: groupCreated,
            memberships,
            members,
        };
    };

    /**
     * vamos a la siguiente step
     */
    const handleNext = () => {
        setCurrentStep(2);
    };

    /**
     * Muestra el password 1
     */
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    /**
     * muestra el password 2
     */
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    /**
     * handler the user interaction
     */
    const onHandlerInputChange = (customeEvent: CustomEvent) => {
        let { event, value } = customeEvent.detail;

        let name = event.target.name;

        if (name == "zipcode" && name.length > 5) {
            value = value.slice(0, 5);
        }

        setUser({ ...user, [name]: value });
    };

    /**
     * maneja el cambio de los radio buttons
     */
    const handleRadioChange = (customeEvent: CustomEvent) => {
        const selectedValue = customeEvent.detail.value;
        setSelectedGender(selectedValue);

        setUser({ ...user, gender: selectedValue });
    };

    /**
     * maneja el cambio de la fecha de nacimiento
     */
    const handleDateChange = (customEvent: CustomEvent) => {
        const selectedDate = customEvent.detail.value;

        if (DateTime.fromISO(selectedDate).isValid) {
            const formattedDate =
                DateTime.fromISO(selectedDate).toFormat("yyyy-MM-dd");

            setUser({ ...user, date_birth: formattedDate });
        }
    };

    /**
     * Maneja la seleccion de un estado
     */
    const handleStateChange = (CustomeEvent: CustomEvent) => {
        const newState = CustomeEvent.detail.value;

        setSelectedState(newState);
        setSelectedMunicipality(null);
        setSelectedCities(null);
    };

    /**
     * Manejjo de la selecion del municipo
     */
    const onHandlerMunicipalityChange = (e: CustomEvent) => {
        setSelectedMunicipality(e.detail.value);
        setSelectedCities(null);
    };

    /**
     * Manejo de la selecion de una ciudad
     */
    const onHandlerCityChange = (e: CustomEvent) => {
        const selectedCity = e.detail.value;

        setSelectedCities(selectedCity);

        setUser({ ...user, address_city_id: selectedCity.city_id });
    };
    /**
     * Validations
     */

    /**
     * One step validation
     */
    const isStepOneValid = () => {
        return (
            isNameValid().isValid &&
            isLastNameValid().isValid &&
            isCellphoneValid().isValid &&
            isDateBirthValid().isValid &&
            isGenderValid().isValid &&
            isEmailValid().isValid &&
            selectedDependency
        );
    };

    /**
     * validacion de segundo paso
     */
    const isRegisterValid = () => {
        return (
            isNameValid().isValid &&
            isLastNameValid().isValid &&
            isCellphoneValid().isValid &&
            isDateBirthValid().isValid &&
            isGenderValid().isValid &&
            isPasswordValid().isValid &&
            isStreetValid().isValid &&
            isExteriorNumberValid().isValid &&
            isColonyValid().isValid &&
            isZipCode().isValid &&
            isCityValid().isValid
        );
    };

    /**
     * password validation
     */
    const isPasswordValid = () => {
        if (user.password === "") {
            return {
                isValid: false,
                message: "La contraseña es requerida",
            };
        } else if (user.password !== user.password_confirmation) {
            return {
                isValid: false,
                message: "Las contraseñas no coinciden",
            };
        }
        return {
            isValid: true,
            message: "",
        };
    };

    /**
     * Name validation
     */
    const isNameValid = () => ({
        isValid: user.name !== "",
        message: "El nombre es requerido",
    });

    /**
     * Last name validation
     */
    const isLastNameValid = () => ({
        isValid: user.last_name !== "",
        message: "El apellido paterno es requerido",
    });

    /**
     * cellphone validation
     */
    const isCellphoneValid = () => ({
        isValid: user.cellphone !== "" && user.cellphone.length == 10,
        message: "El telefono es requerido",
    });

    /**
     * date birth validation
     */
    const isDateBirthValid = () => ({
        isValid: user.date_birth !== "",
        message: "El año de nacimiento es requerido",
    });

    /**
     * gender validation
     */
    const isGenderValid = () => ({
        isValid: user.gender !== "",
        message: "El nombre es requerido",
    });

    /**
     * validacion para el correo
     */
    const isEmailValid = () => {
        var mailformat =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const isEmail = mailformat.test(user.email);

        return {
            isValid: (user.email !== "" && isEmail) || user.email === "",
            message: "Campo requerido o incorrecto",
        };
    };

    const isStreetValid = () => ({
        isValid: user.street !== "",
        message: "La calle del usuario es requerida",
    });

    const isExteriorNumberValid = () => ({
        isValid: user.outside_number !== "",
        message: "El número exterior es requerido",
    });

    const isColonyValid = () => ({
        isValid: user.colony !== "",
        message: "La colonia del usuario es requerida",
    });

    const isZipCode = () => ({
        isValid: user.zipcode !== "",
        message: "El código postal es obligatorio",
    });

    const isCityValid = () => ({
        isValid: user.address_city_id !== null,
        message: "La ciudad del usuario es requerida",
    });

    const onChangeLocation = ({ latitude, longitude }: any) => {
        setUser((user: any) => {
            return {
                ...user,
                latitude,
                longitude,
            };
        });
    };
    const fechaMaximaValida = DateTime.now()
        .minus({ years: 18 })
        .toFormat("yyyy-MM-dd");

    /**
     * instrucciones del selec de los tipos de las dependencias
     */
    const customAlertOptions = {
        header: "Selecciona el tipo de dependencia",
        subHeader: "Elige un tipo de dependencia",
        translucent: true,
    };

    const customAlertOptions2 = {
        header: "Selecciona la dependencia a la que perteneces",
        subHeader: "Elige una dependencia",
        translucent: true,
    };
    /**
     * Accion para recuperar todas las dependencias
     */
    useEffect(() => {
        const getDependencies = async () => {
            const Dependencies = await escudoWebAPI
                .getAllDependenci()
                .then((res: any) => res.data.data);

            Dependencies.sort((a: any, b: any) => a.name.localeCompare(b.name));

            setAllDependencies(Dependencies);
        };

        getDependencies();
    }, []);
    /**
     * Accion de la seleccion de la dependencia
     */
    const HandleSelectTypeDependenci = (event: CustomEvent) => {
        const selectedType = event.detail.value;

        if (selectedType === "3") {
            setShowFirstSelect(false);
            setselectedDependency(48);
        } else {
            setShowFirstSelect(true);
            if (Dependencies && Dependencies.length > 0) {
                const filteredData = Dependencies.filter(
                    (item: any) => item.type === parseInt(selectedType, 10)
                );
                setSelectedTypeDependency(
                    filteredData.length > 0 ? filteredData[0] : null
                );

                setFilteredDependencies(filteredData);
            }
        }
    };
    const handleSelectDependency = (event: CustomEvent) => {
        setselectedDependency(event.detail.value);
    };

    /**
     * animacion del select
     */
    useEffect(() => {
        if (showFirstSelect) {
            anime({
                targets: ".ion-select-animation",
                opacity: [0, 1],
                translateY: [20, 0],
                duration: 700,
                easing: "easeInOutExpo",
            });
        }
    }, [showFirstSelect]);

    return (
        <IonPage className="create-group-page">
            <ToolBar title="Nuevo Grupo"></ToolBar>
            <IonContent className="ion-padding content-create-group">
                <div>
                    {/*first seccion*/}
                    {currentStep === 1 && (
                        <div className="titleForm">Datos Personales</div>
                    )}
                    {currentStep === 1 && (
                        <div>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="12">
                                        <IonInput
                                            name="name"
                                            maxlength={50}
                                            className="inputs-create-group"
                                            labelPlacement="stacked"
                                            placeholder="Nombre del grupo"
                                            value={group}
                                            onIonInput={onChangeGroupName}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="inputs-create-group-text"
                                                slot="label"
                                            >
                                                Nombre del grupo
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="text-label-dif">
                                        Tipo de dependencia a la que pertenece{" "}
                                        <IonText color="danger">*</IonText>
                                    </IonCol>
                                    <IonCol
                                        size="12"
                                        className="select-dependency"
                                    >
                                        <IonItem
                                            lines="none"
                                            className="Dependency-seccion"
                                        >
                                            <IonIcon
                                                className="icon-dependenci"
                                                src={
                                                    selectedOption === "1"
                                                        ? State_dependence
                                                        : selectedOption === "2"
                                                        ? municipality_dependence
                                                        : Another_dependenci
                                                }
                                                slot="start"
                                            />
                                            <IonSelect
                                                interfaceOptions={
                                                    customAlertOptions
                                                }
                                                interface="alert"
                                                mode="md"
                                                placeholder="Selecciona el tipo de dependencia"
                                                toggleIcon={chevronDownOutline}
                                                expandedIcon={chevronUpOutline}
                                                onIonChange={
                                                    HandleSelectTypeDependenci
                                                }
                                            >
                                                <IonSelectOption value="1">
                                                    Estatal
                                                </IonSelectOption>
                                                <IonSelectOption value="2">
                                                    Municipal
                                                </IonSelectOption>
                                                <IonSelectOption value="3">
                                                    Independiente
                                                </IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {showFirstSelect === true && (
                                    <IonRow>
                                        <IonCol
                                            size="12"
                                            className="text-label-dif"
                                        >
                                            Nombre de su dependencia
                                            <IonText color="danger">*</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonSelect
                                                interfaceOptions={
                                                    customAlertOptions2
                                                }
                                                interface="alert"
                                                mode="md"
                                                placeholder="Selecciona una dependencia"
                                                toggleIcon={chevronDownOutline}
                                                expandedIcon={chevronUpOutline}
                                                disabled={
                                                    !selectedTypeDependency
                                                }
                                                onIonChange={
                                                    handleSelectDependency
                                                }
                                            >
                                                {filteredDependencies.map(
                                                    (item: any) => (
                                                        <IonSelectOption
                                                            key={`${item.catalog_dependency_idf}_${item.otherProperty}`}
                                                            value={
                                                                item.catalog_dependency_id
                                                            }
                                                        >
                                                            {item.name}
                                                        </IonSelectOption>
                                                    )
                                                )}
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                )}
                                {/*names seccion*/}
                                <IonRow>
                                    <IonCol size="12">
                                        <IonInput
                                            name="name"
                                            maxlength={50}
                                            className="inputs-create-group"
                                            labelPlacement="stacked"
                                            placeholder="Nombre(s)"
                                            value={user.name}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="inputs-create-group-text"
                                                slot="label"
                                            >
                                                Nombre
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*surnames seccion*/}
                                <IonRow>
                                    <IonCol size="6" class="last-name">
                                        <IonInput
                                            name="last_name"
                                            className="inputs-create-group"
                                            labelPlacement="stacked"
                                            placeholder="Tu apellido"
                                            maxlength={50}
                                            value={user.last_name}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="inputs-create-group-text"
                                                slot="label"
                                            >
                                                Apellido Paterno{" "}
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonInput
                                            maxlength={50}
                                            className="inputs-create-group"
                                            name="second_last_name"
                                            labelPlacement="stacked"
                                            placeholder="Tu apellido"
                                            value={user.second_last_name}
                                            onIonInput={onHandlerInputChange}
                                            counter={true}
                                        >
                                            <div
                                                className="inputs-create-group-text"
                                                slot="label"
                                            >
                                                Apellido Materno
                                                <IonText color="medium">
                                                    (Opcional)
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*phone seccion*/}
                                <IonRow>
                                    <IonCol size="12">
                                        <IonItem className="phone">
                                            <IonInput
                                                className="inputs-create-group"
                                                name="cellphone"
                                                maxlength={10}
                                                labelPlacement="stacked"
                                                placeholder="Ingresa el número celular"
                                                value={user.cellphone}
                                                onIonInput={
                                                    onHandlerInputChange
                                                }
                                                type="tel"
                                                required
                                                ref={async (phoneInput) => {
                                                    if (phoneInput) {
                                                        const input =
                                                            await phoneInput.getInputElement();
                                                        phoneMask(input);
                                                    }
                                                }}
                                            >
                                                <div
                                                    className="inputs-create-group-text"
                                                    slot="label"
                                                >
                                                    Teléfono{" "}
                                                    <IonText color="danger">
                                                        *
                                                    </IonText>
                                                </div>
                                            </IonInput>
                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={callOutline}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/*gender seccion*/}
                                <IonRow className="gender-seccion">
                                    <IonText className="genderTitle">
                                        Género{" "}
                                        <IonText color="danger">*</IonText>
                                    </IonText>
                                    <IonCol size="12">
                                        <IonRadioGroup
                                            value={
                                                selectedGender || user.gender
                                            }
                                            onIonChange={handleRadioChange}
                                            className="gender-Buttons flex-row ion-justify-content-between ion-align-items.center"
                                        >
                                            <IonRadio
                                                mode="md"
                                                value="1"
                                                className="circle-radio ion-padding-top"
                                            >
                                                <p className="gender">
                                                    Masculino
                                                </p>
                                            </IonRadio>

                                            <IonRadio
                                                mode="md"
                                                value="2"
                                                className="circle-radio ion-padding-top"
                                            >
                                                <p className="gender">
                                                    Femenino
                                                </p>
                                            </IonRadio>

                                            <IonRadio
                                                mode="md"
                                                value="3"
                                                className="circle-radio ion-padding-top"
                                            >
                                                <p className="gender">Otro</p>
                                            </IonRadio>
                                        </IonRadioGroup>
                                    </IonCol>
                                </IonRow>
                                {/*born day seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonItem
                                            className="bornDay born-seccion"
                                            id="auto-trigger"
                                        >
                                            <IonInput
                                                name="date_birth"
                                                labelPlacement="stacked"
                                                placeholder="DD/MM/YYYY"
                                                value={user.date_birth}
                                                className="inputs-create-group"
                                                onIonChange={handleRadioChange}
                                            >
                                                <div
                                                    className="inputs-create-group-text"
                                                    slot="label"
                                                >
                                                    Fecha de Nacimiento{" "}
                                                    <IonText color="danger">
                                                        *
                                                    </IonText>
                                                </div>
                                                <IonPopover
                                                    trigger="auto-trigger"
                                                    side="top"
                                                    alignment="center"
                                                    keepContentsMounted={true}
                                                >
                                                    <IonDatetime
                                                        size="cover"
                                                        presentation="date"
                                                        preferWheel={true}
                                                        showDefaultButtons={
                                                            true
                                                        }
                                                        doneText="Aceptar"
                                                        cancelText="Cancelar"
                                                        onIonChange={
                                                            handleDateChange
                                                        }
                                                        max={fechaMaximaValida}
                                                    ></IonDatetime>
                                                </IonPopover>
                                            </IonInput>

                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={calendarOutline}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/* email seccion  */}
                                <IonRow>
                                    <IonCol>
                                        <IonItem className="email email-seccion">
                                            <IonInput
                                                name="email"
                                                labelPlacement="stacked"
                                                placeholder="tumail@gmail.com"
                                                value={user.email}
                                                maxlength={100}
                                                onIonInput={
                                                    onHandlerInputChange
                                                }
                                                className="inputs-create-group"
                                            >
                                                <div
                                                    className="inputs-create-group-text"
                                                    slot="label"
                                                >
                                                    E-mail{" "}
                                                    <IonText color="medium">
                                                        (opcional)
                                                    </IonText>
                                                </div>
                                            </IonInput>
                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={mailOutline}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/*Button Next seccion*/}
                                <div className="button-create-group ion-justify-content-center">
                                    <IonButton
                                        className="nextButton"
                                        onClick={handleNext}
                                        disabled={!isStepOneValid()}
                                    >
                                        Siguiente
                                    </IonButton>
                                </div>
                            </IonGrid>
                        </div>
                    )}

                    {/*seccond seccion*/}

                    {currentStep === 2 && (
                        <div className="titleForm ion-padding-top">
                            Ubicación
                        </div>
                    )}
                    {currentStep === 2 && (
                        <div>
                            <IonGrid>
                                {/*street seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonInput
                                            name="street"
                                            labelPlacement="stacked"
                                            placeholder="Nombre de la calle"
                                            value={user.street}
                                            type="text"
                                            maxlength={50}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                            className="inputs-create-group"
                                        >
                                            <div
                                                className="inputs-create-group-text"
                                                slot="label"
                                            >
                                                Calle
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>

                                {/*numbers seccion*/}
                                <IonRow>
                                    <IonCol size="6" className="last-name">
                                        <IonInput
                                            maxlength={10}
                                            type="text"
                                            className="inputs-create-group"
                                            name="outside_number"
                                            label-placement="stacked"
                                            placeholder="Número"
                                            value={user.outside_number}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="inputs-create-group-text"
                                                slot="label"
                                            >
                                                Núm. Exterior
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonInput
                                            className="inputs-create-group"
                                            name="inside_number"
                                            labelPlacement="stacked"
                                            placeholder="Número"
                                            maxlength={10}
                                            type="text"
                                            value={user.inside_number}
                                            onIonInput={onHandlerInputChange}
                                            counter={true}
                                        >
                                            <div
                                                className="inputs-create-group-text"
                                                slot="label"
                                            >
                                                Núm. Interior{" "}
                                                <IonText color="medium">
                                                    (opcional)
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonInput
                                            name="colony"
                                            labelPlacement="stacked"
                                            placeholder="Nombre de la colonia"
                                            value={user.colony}
                                            type="text"
                                            maxlength={50}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                            className="inputs-create-group"
                                        >
                                            <div
                                                className="inputs-create-group-text"
                                                slot="label"
                                            >
                                                Colonia
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*CP seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonInput
                                            type="number"
                                            className="inputs-create-group"
                                            name="zipcode"
                                            maxlength={5}
                                            labelPlacement="stacked"
                                            placeholder="Número"
                                            value={user.zipcode}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="inputs-create-group-text"
                                                slot="label"
                                            >
                                                Código Postal{" "}
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*state seccion*/}
                                <IonRow className="states-seccion">
                                    <IonCol
                                        size="12"
                                        className="text-label-dif"
                                    >
                                        Estado{" "}
                                        <IonText color="danger">*</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonItem className="states">
                                            <IonIcon
                                                slot="start"
                                                src={marketInput}
                                            />

                                            <IonSelect
                                                className="states-select select-create-group-page"
                                                placeholder="Seleccionar estado"
                                                name="address_city_id"
                                                toggleIcon={chevronDownOutline}
                                                expandedIcon={chevronUpOutline}
                                                interface="action-sheet"
                                                onIonChange={handleStateChange}
                                                compareWith={(
                                                    o1: State,
                                                    o2: State
                                                ) => {
                                                    return o1 && o2
                                                        ? o1.state_id ===
                                                              o2.state_id
                                                        : o1 === o2;
                                                }}
                                                mode="ios"
                                                value={selectedState}
                                            >
                                                {states
                                                    .sort((a: any, b: any) =>
                                                        a.title.localeCompare(
                                                            b.title
                                                        )
                                                    )
                                                    .map((state: any) => (
                                                        <IonSelectOption
                                                            className="seccions"
                                                            key={state.state_id}
                                                            value={state}
                                                        >
                                                            {state.title.toUpperCase()}
                                                        </IonSelectOption>
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/*municipalite and city seccion*/}
                                <IonRow className="states-seccion">
                                    <IonCol size="6" className="last-name">
                                        <div className="text-label-dif">
                                            Municipio
                                            <IonText color="danger">*</IonText>
                                        </div>
                                        <IonSelect
                                            className="select select-create-group-page"
                                            interface="action-sheet"
                                            toggleIcon={chevronDownOutline}
                                            expandedIcon={chevronUpOutline}
                                            placeholder="Selc. Municipio"
                                            onIonChange={
                                                onHandlerMunicipalityChange
                                            }
                                            disabled={!selectedState}
                                            mode="ios"
                                            compareWith={(
                                                o1: Municipality,
                                                o2: Municipality
                                            ) => {
                                                return o1 && o2
                                                    ? o1.municipality_id ===
                                                          o2.municipality_id
                                                    : o1 === o2;
                                            }}
                                            value={selectedMunicipality}
                                        >
                                            {allMunicipalities
                                                .sort(
                                                    (
                                                        a: Municipality,
                                                        b: Municipality
                                                    ) =>
                                                        a.title.localeCompare(
                                                            b.title
                                                        )
                                                )
                                                .map((municipality: any) => (
                                                    <IonSelectOption
                                                        key={
                                                            municipality.municipality_id
                                                        }
                                                        value={municipality}
                                                    >
                                                        {municipality.title}
                                                    </IonSelectOption>
                                                ))}
                                        </IonSelect>
                                    </IonCol>

                                    {/*city seccion*/}
                                    <IonCol size="6">
                                        <div className="text-label-dif">
                                            Ciudad
                                            <IonText color="danger">*</IonText>
                                        </div>
                                        <IonSelect
                                            className="select select-create-group-page"
                                            interface="action-sheet"
                                            toggleIcon={chevronDownOutline}
                                            expandedIcon={chevronUpOutline}
                                            placeholder="Sel. Ciudad"
                                            aria-required
                                            compareWith={(
                                                o1: City,
                                                o2: City
                                            ) => {
                                                return o1 && o2
                                                    ? o1.city_id === o2.city_id
                                                    : o1 === o2;
                                            }}
                                            value={selectedCities}
                                            onIonChange={onHandlerCityChange}
                                            disabled={
                                                !selectedMunicipality ||
                                                !selectedState
                                            }
                                            mode="ios"
                                        >
                                            {allCities
                                                .sort((a: City, b: City) =>
                                                    a.title.localeCompare(
                                                        b.title
                                                    )
                                                )
                                                .map((city: any) => (
                                                    <IonSelectOption
                                                        key={city.city_id}
                                                        value={city}
                                                    >
                                                        {city.title}
                                                    </IonSelectOption>
                                                ))}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                {/*map seccion*/}
                                <IonRow>
                                    <IonCol
                                        size="12"
                                        className="mapText ion-padding-top"
                                    >
                                        <IonText className="mapText ion-padding-top">
                                            Ubicación Geográficamente de tu
                                            domicilio
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <MapComponent
                                            latitude={user.latitude}
                                            longitude={user.longitude}
                                            onChangeLocation={onChangeLocation}
                                        ></MapComponent>
                                    </IonCol>
                                </IonRow>
                                {/*passwords seccion*/}
                                <IonRow>
                                    <IonCol className="ion-padding-bottom">
                                        <IonItem className="password-seccion ion-padding-top">
                                            <IonInput
                                                maxlength={100}
                                                name="password"
                                                labelPlacement="stacked"
                                                type={
                                                    showPassword1
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder="Ingresa una contraseña"
                                                value={user.password}
                                                onIonInput={(e) => {
                                                    onHandlerInputChange(e);
                                                }}
                                                required
                                                className="inputs-create-group"
                                            >
                                                <div
                                                    className="inputs-create-group-text"
                                                    slot="label"
                                                >
                                                    Password{" "}
                                                    <IonText color="danger">
                                                        *
                                                    </IonText>
                                                </div>
                                            </IonInput>
                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={
                                                    showPassword1
                                                        ? eyeOutline
                                                        : eyeOffOutline
                                                }
                                                onClick={
                                                    togglePasswordVisibility1
                                                }
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonItem className="password-seccion ion-padding-top">
                                            <IonInput
                                                maxlength={100}
                                                name="password_confirmation"
                                                labelPlacement="stacked"
                                                type={
                                                    showPassword2
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder="Ingresa nuevamente la contraseña"
                                                value={
                                                    user.password_confirmation
                                                }
                                                onIonInput={(e) => {
                                                    onHandlerInputChange(e);
                                                }}
                                                className="inputs-create-group"
                                            >
                                                <div
                                                    className="inputs-create-group-text"
                                                    slot="label"
                                                >
                                                    Confirmar Password{" "}
                                                    <IonText color="danger">
                                                        *
                                                    </IonText>
                                                </div>
                                            </IonInput>
                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={
                                                    showPassword2
                                                        ? eyeOutline
                                                        : eyeOffOutline
                                                }
                                                onClick={
                                                    togglePasswordVisibility2
                                                }
                                            />
                                        </IonItem>
                                        {user.password ===
                                        user.password_confirmation ? null : (
                                            <IonText color="danger">
                                                Las contraseñas no coinciden.
                                            </IonText>
                                        )}
                                    </IonCol>
                                </IonRow>

                                {/*confirm button icon */}
                                <IonRow>
                                    <div className="button-create-group ion-justify-content-center">
                                        <IonButton
                                            className="nextButton"
                                            onClick={() => setCurrentStep(1)}
                                        >
                                            Atrás
                                        </IonButton>
                                        <IonButton
                                            className="finishButton"
                                            onClick={onCreateGroup}
                                            disabled={!isRegisterValid()}
                                        >
                                            Registrar
                                        </IonButton>
                                    </div>
                                </IonRow>
                            </IonGrid>
                        </div>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CreateGroups;
