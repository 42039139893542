import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../redux/slices/auth";

import WebAPI from "./../services/escudoWebAPI";

/**
 * Hook for register user in onesingla
 */
let useConfigureShieldWebAPI = () => {
    let accessToken = useSelector(selectAccessToken);

    useEffect(() => {
        WebAPI.createAuthorizedHTTPClient(accessToken);
    }, []);

    return {};
};

export default useConfigureShieldWebAPI;
