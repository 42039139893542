import React, { useState } from "react";
import { useHistory } from "react-router";
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonPage,
    IonRow,
    IonIcon,
    IonText,
    useIonLoading,
} from "@ionic/react";
import { useMaskito } from "@maskito/react";
import ToolBar from "../../../../components/ToolBar";
import ModalUpdatePhone from "./pages/Modal/ModalUpdatePhone";
import "./changePhone.scss";

import phoneChangeicon from "../../../../assets/icons/phoneChange.svg";
import Twoarrows from "../../../../assets/icons/TwoArrows.svg";
import { callOutline } from "ionicons/icons";
import { logout, updateUserUsername } from "../../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import { useFeedback } from "../../../../hooks";
const ChangeNumber: React.FC = () => {
    /**
     * hooks
     */

    const history = useHistory();
    const dispatch = useDispatch();
    const [present, dismiss] = useIonLoading();
    const feedbackApp = useFeedback();

    const phoneMask = useMaskito({
        options: {
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        },
    });
    /**
     * local state
     */
    const [newNumber, setNewNumber] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * shared state
     */
    //const userId = useSelector(selectAuthenticatedUserId);
    //const userInfo = useSelector(selectUsersById(userId));

    /**
     * cambiar el numero de telefono del usuario
     */
    const OnHandleChangePass = () => {
        present({
            message: "Por favor espere",
        });

        dispatch<any>(updateUserUsername({ newPhone: newNumber }))
            .unwrap()
            .then(() => {
                setIsModalOpen(true);
            })
            .catch(({ feedback }: any) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    /**
     * Cerrar modal de confirmacion
     */
    const closeModal = () => {
        setIsModalOpen(false);
        dispatch<any>(logout({ history }));
    };

    return (
        <IonPage className="Update-phone-page">
            <ToolBar title="Actualiza" />
            <IonContent className="Update-phone-content">
                <IonGrid>
                    <IonRow>
                        <IonCol
                            size="12"
                            className="ion-text-center ion-padding-top"
                        >
                            <IonIcon
                                className="Update-Icon"
                                src={phoneChangeicon}
                            >
                                {" "}
                            </IonIcon>
                            <IonIcon
                                className="Update-Icon-arrow"
                                src={Twoarrows}
                            >
                                {" "}
                            </IonIcon>
                            <IonIcon
                                className="Update-Icon"
                                src={phoneChangeicon}
                            >
                                {" "}
                            </IonIcon>
                        </IonCol>
                        <IonCol
                            size="12"
                            className="ion-text-center ion-padding-top"
                        >
                            <IonText className="ion-padding-top Update-Phone-Title">
                                Actualiza tu número de teléfono
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className=" ion-padding" size="12">
                            <IonText className="Update-Phone-Text">
                                En esta pantalla, puedes cambiar tu número de
                                teléfono. Por favor, sigue los pasos a
                                continuación.
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none" className="phoneUpdate">
                                <IonInput
                                    maxlength={10}
                                    counter={true}
                                    name="cellphone"
                                    labelPlacement="stacked"
                                    placeholder="Ingresa el número celular"
                                    type="tel"
                                    required
                                    value={newNumber}
                                    onIonInput={(e) =>
                                        setNewNumber(e.detail.value!)
                                    }
                                    ref={async (phoneInput) => {
                                        if (phoneInput) {
                                            const input =
                                                await phoneInput.getInputElement();
                                            phoneMask(input);
                                        }
                                    }}
                                >
                                    <div className="text-label" slot="label">
                                        Ingresa tu número de teléfono nuevo
                                    </div>
                                </IonInput>
                                <IonIcon
                                    className="icon"
                                    slot="end"
                                    icon={callOutline}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <div className="button-register ion-justify-content-center">
                        <IonButton
                            className="update-button"
                            onClick={OnHandleChangePass}
                            disabled={newNumber === ""}
                        >
                            Cambiar Número
                        </IonButton>
                    </div>
                </IonGrid>
                <ModalUpdatePhone
                    isOpen={isModalOpen}
                    closeModal={closeModal}
                />
                <div className="footerUpdate"></div>
            </IonContent>
        </IonPage>
    );
};

export default ChangeNumber;
