import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    useIonLoading,
} from "@ionic/react";
import React, { useState } from "react";
import "./styles.scss";
import { useHistory } from "react-router";
import ToolBar from "../../../../components/ToolBar";
import { callOutline } from "ionicons/icons";
import { useMaskito } from "@maskito/react";
import { useDispatch } from "react-redux";
import { useFeedback } from "../../../../hooks";
import { deleteAccount, logout } from "../../../../redux/slices/auth";
import ModalDelete from "./pages/ModalsDeleteAcconunt";

const DeleteAccount: React.FC = () => {
    /*
     * Hooks
     */
    const history = useHistory();
    const dispatch = useDispatch();
    const [present, dismiss] = useIonLoading();
    const feedbackApp = useFeedback();

    /*
  ! states
  */
    const [cellphone, setCellphone] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    /*
  ? Modal configs
  */

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleCellphoneChange = (event: any) => {
        const newCellphone = event.target.value;
        setCellphone(newCellphone);
    };

    /*
  ? Actions
  */

    const onHandleDeleteAccount = () => {
        present({
            message: "Por favor espere",
        });

        dispatch<any>(deleteAccount({ phoneData: { cellphone } }))
            .unwrap()
            .then(() => {
                setIsModalOpen(true);
            })
            .catch(({ feedback }: any) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    const acceptModal = () => {
        dispatch<any>(logout({ history }));
    };

    /*
  ? validations
  */

    const phoneMask = useMaskito({
        options: {
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        },
    });
    const isValidCellphone = () => {
        return cellphone.length === 10 && /^\d+$/.test(cellphone);
    };

    return (
        <IonPage>
            <ModalDelete
                isOpen={isModalOpen}
                closeModal={closeModal}
                redirectToLogin={acceptModal}
            />
            <ToolBar title="Eliminar Mi Cuenta"></ToolBar>
            <IonContent className="DeleteContent ">
                <IonGrid className=" ion-padding">
                    <IonRow className="ion-padding-top ">
                        <IonCol size="12">
                            <IonLabel className="DeleteTitle">
                                Si eliminas tu cuenta:
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow className="DeleteTexts center">
                        <IonCol>
                            <IonLabel>
                                • Se eliminará la info. de tu cuenta
                            </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                            <IonLabel>
                                • Se te eliminará de tu{" "}
                                <span>Grupo Vecinal</span>
                            </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                            <IonLabel>
                                • Se eliminará el historial de reportes en este
                                teléfono.
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonLabel className="DeleteTitleNumber ion-padding-top">
                        INGRESA TU NÚMERO:{" "}
                    </IonLabel>
                    <IonRow className="DeleteTel">
                        <IonCol size="12">
                            <IonItem lines="none" className="center">
                                <IonInput
                                    name="cellphone"
                                    labelPlacement="stacked"
                                    placeholder="Ingresa el número celular"
                                    type="tel"
                                    required
                                    maxlength={10}
                                    value={cellphone}
                                    onIonInput={handleCellphoneChange}
                                    ref={async (phoneInput) => {
                                        if (phoneInput) {
                                            const input =
                                                await phoneInput.getInputElement();
                                            phoneMask(input);
                                        }
                                    }}
                                ></IonInput>
                                <IonIcon
                                    slot="end"
                                    icon={callOutline}
                                ></IonIcon>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonButton
                        className="DeleteButton center"
                        onClick={onHandleDeleteAccount}
                        disabled={!isValidCellphone()}
                    >
                        Eliminar Mi Cuenta
                    </IonButton>
                </IonGrid>
                <div className="footer"></div>
            </IonContent>
        </IonPage>
    );
};

export default DeleteAccount;
