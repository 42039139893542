import React, { useState } from "react";
import ProfileContext from "./ProfileContext";
import Modal from "../../components/ProfileModal";

const ProfileProvider = (props) => {
    const { children } = props

    const [open, setOpen] = useState(false)

    const showProfile = message => {
        setOpen(true)
    }

    return (
        <ProfileContext.Provider value={{
            showProfile,
        }}>
            {children}
            <Modal isOpen={open} setOpen={setOpen}/>
        </ProfileContext.Provider>)
}

export default ProfileProvider