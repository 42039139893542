import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";


/**
 * create adapter for the incident entity
 */
const catalogincidentsAdapter = createEntityAdapter<any>({
    selectId: (incident) => incident.catalog_incident_id,
    sortComparer: (incidentA, incidentB) => incidentA.catalog_incident_id - incidentB.catalog_incident_id,
})

/**
 * incidents slice definitions
 */
export const catalogincidentsSlice = createSlice({
    name: "catalogincidents",
    initialState: catalogincidentsAdapter.getInitialState(),
    reducers: {
        addManyCatalogIncidents: catalogincidentsAdapter.addMany,
        addOneCatalogIncidents: catalogincidentsAdapter.addOne,
        setAllCatalogIncidents: catalogincidentsAdapter.setAll,
        upsertOneCatalogIncident: catalogincidentsAdapter.upsertOne,
        upsertManyCatalogIncidents: catalogincidentsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return catalogincidentsAdapter.getInitialState()
        })
    }
})

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManyCatalogIncidents, addOneCatalogIncidents, upsertOneCatalogIncident,
    upsertManyCatalogIncidents, setAllCatalogIncidents
} = catalogincidentsSlice.actions

/**
 * export reducer autegenerated by toolkit
 */
export default catalogincidentsSlice.reducer

//////////////////// SELECTORES //////////////////

const globalizedSelectors = catalogincidentsAdapter.getSelectors((state: any) => state.entities.catalogincidents)

/** 
 * select for recover all Catalog Incidents entities
 */
export const selectCatalogIncidentsEntities = (state: any) => globalizedSelectors.selectEntities(state)

/**
 * select for recover all CatalogIncidents 
 */
export const selectAllCatalogIncidents = (state: any) => globalizedSelectors.selectAll(state)

/**
 * select for recover one Catalog Incidents by id
 */
export const selectCatalogIncidentsById = (id: any) => (state: any) => globalizedSelectors.selectById(state, id)
