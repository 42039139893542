import {
    IonActionSheet,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonSkeletonText,
    IonText,
    IonThumbnail,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ToolBar from "../../../../../../components/ToolBar";
import { Capacitor } from "@capacitor/core";
import { useSelector } from "react-redux";
import {
    selectgroupsById,
    upsertOnegroup,
} from "../../../../../../redux/slices/entities/groups";
import { useHistory, useParams } from "react-router";
import "./style.scss";
import {
    arrowForward,
    chevronDownOutline,
    chevronUpOutline,
    close,
    personAddOutline,
    trashBin,
} from "ionicons/icons";
import { Share } from "@capacitor/share";
import { MEMBERSHIPS, STORES_LINKS } from "../../../../../../constants";
import { Device } from "@capacitor/device";
import escudoWebAPI from "../../../../../../services/escudoWebAPI";
import feedback from "../../../../../../services/feedback";
import { useFeedback } from "../../../../../../hooks";
import { useDispatch } from "react-redux";
import * as _ from "lodash-es";
import {
    addOneMembreship,
    selectMeActivedMemberships,
} from "../../../../../../redux/slices/entities/memberships";
import { upsertOneUser } from "../../../../../../redux/slices/entities/users";

import Another_dependenci from "../../../../../../assets/icons/Another_dependenci.svg";
import municipality_dependence from "../../../../../../assets/icons/municipality_dependence.svg";
import State_dependence from "../../../../../../assets/icons/State_dependence.svg";
import { DependencyList } from "../../../../../../models/Dependency";
import anime from "animejs";
import AdminIcon from "../../../../../../assets/icons/Admin-icon.svg";
import {
    ContactPayload,
    Contacts,
    PhonePayload,
} from "@capacitor-community/contacts";

const EditGroup: React.FC = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    const { id }: any = useParams();
    const [present, dismiss] = useIonLoading();
    const [presetAlert] = useIonAlert();
    const feedbackApp = useFeedback();
    const dispatch = useDispatch();

    const meMemberships = useSelector(selectMeActivedMemberships);

    const membrship = meMemberships.find(
        (membeship: any) => membeship.group_id == id
    );

    const canManageGroup = membrship?.type == MEMBERSHIPS.CREATOR;
    const AdminGroup = membrship?.type == MEMBERSHIPS.ADMINISTRATOR;

    /**
     * estado local para las dependencias
     */

    const [selectedTypeDependency, setSelectedTypeDependency] = useState<
        number | null
    >(null);
    const [dependency, setDependency] = useState<DependencyList | null>(null);
    const [allDependencies, setAllDependencies] = useState<DependencyList[]>(
        []
    );

    /**
     * global state
     */
    const group = useSelector(selectgroupsById(id));
    /**
     * local state
     */
    const [allContacts, setAllContacts] = useState<any>([]);
    const [isOpentTypeMember, setIsOpentTypeMember] = useState(false);
    const [cellphoneSelected, setCellphoneSelected] = useState("");
    const [results, setResults] = useState<any>([]);
    const [searchError, setSearchError] = useState<string | null>(null);
    const [debounceLoading, setDebounceLoading] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [isOpenSelectCellphone, setIsOpenSelectCellphone] = useState(false);
    const [concactSelected, setConcactSelected] = useState({
        phones: [],
    });
    const [showPermissionModal, setShowPermissionModal] = useState(false);

    const [editGroup, setEditGroup] = useState({
        name: "",
        dependency: 1,
    });

    /**
     * Recuperamos los concactos del telefono
     */
    useEffect(() => {
        getAllConcacts();
    }, []);

    /**
     * Recuperamos todos los concactos del telefono
     */
    const getAllConcacts = async () => {
        let contactsDisordered: any = [
            {
                contactId: 1,
                name: {
                    display: "prueba",
                },
                phones: [
                    {
                        type: 1,
                        label: "asdasd",
                        number: "6183202829",
                    },
                    {
                        type: 1,
                        label: "asdasd",
                        number: "0000000200",
                    },
                    {
                        type: 1,
                        label: "asdasd",
                        number: "61 832 02829",
                    },
                    {
                        type: 1,
                        label: "asdasd",
                        number: "+52161 832 02829",
                    },
                    {
                        type: 1,
                        label: "asdasd",
                        number: "+5261 832 02829",
                    },
                    {
                        type: 1,
                        label: "asdasd",
                        number: "+52 161 832 02829",
                    },
                    {
                        type: 1,
                        label: "asdasd",
                        number: "0000000005",
                    },
                ],
            },
            {
                contactId: 2,
                name: {
                    display: "arueba",
                },
                phones: [
                    {
                        type: 1,
                        label: "asdasd",
                        number: "asd12312378127836871263asd",
                    },
                ],
            },
        ];

        if (Capacitor.getPlatform() != "web") {
            try {
                const getContactResult = await Contacts.getContacts({
                    projection: {
                        name: true,
                        phones: true,
                    },
                });
                contactsDisordered = getContactResult.contacts || [];
            } catch (error) {
                setShowPermissionModal(true);
            }
        }

        let contacts = contactsDisordered
            .filter((item: ContactPayload) => {
                if (!item?.phones) {
                    return false;
                }

                if (!item?.name) {
                    return false;
                }

                return true;
            })
            .map((item: ContactPayload) => {
                let phonesAllowed: any = [];

                if (item?.phones) {
                    let safePhones = item?.phones.map((phone: PhonePayload) => {
                        let formattedPhone = phone?.number ?? "";

                        formattedPhone = formattedPhone.replace(/[\s()-]/g, "");
                        formattedPhone = formattedPhone.replace(/^\+?52/, "");
                        formattedPhone = formattedPhone.replace(/[^\d]/g, "");
                        formattedPhone = formattedPhone.slice(-10);

                        if (
                            formattedPhone.length !== 10 ||
                            !/^\d{10}$/.test(formattedPhone)
                        ) {
                            return {
                                ...phone,
                                number: "Número inválido",
                            };
                        }

                        return {
                            ...phone,
                            number: formattedPhone,
                        };
                    });

                    phonesAllowed = _.uniqBy(safePhones, "number");
                }

                return {
                    ...item,
                    phones: phonesAllowed,
                };
            })
            .sort((a: ContactPayload, b: ContactPayload) => {
                let aName = a.name?.display ?? "";
                let bName = b.name?.display ?? "";

                return aName.localeCompare(bName);
            });

        setAllContacts(contacts);
        setResults(contacts.slice(0, 30));
    };

    /**
     * Preparamos el grupo para la edicion
     */
    useEffect(() => {
        setEditGroup(group);
    }, [id]);

    /**
     * filtrado de los contactos en el administrador
     */
    const handleSearchContactsAdmin = (ev: Event) => {
        let query = "";
        const target = ev.target as HTMLIonSearchbarElement;
        if (target) query = target.value!.toLowerCase();

        setResults(
            allContacts
                .filter((d: ContactPayload) => {
                    let name = d.name?.display ? d.name.display : "";
                    let cellphones = d.phones;

                    if (name == "") {
                        return false;
                    }

                    return name.toLowerCase().indexOf(query) > -1;
                })
                .slice(0, 30)
        );
    };
    /**
     * filtrado de los contactos en el creador
     */
    const handleSearchContactsCreator = (ev: any) => {
        const query = (ev.detail.value as string).toLowerCase();
        if (query.length < 3) {
            setSearchError("Ingrese al menos 3 caracteres");
            return;
        }
        setSearchError(null);
        setDebounceLoading(true);
        setTimeout(() => {
            escudoWebAPI
                .SearchUsers(query)
                .then((dataFromApi) => setResults(dataFromApi))
                .catch(() => setResults([]))
                .finally(() => {
                    setDebounceLoading(false);
                });
        }, 1000);
    };
    /**
     * Cambiar el nombre del grupo
     */
    const onChangeGroupName = (data: any) => {
        let value = data.detail.value;

        setEditGroup({
            ...editGroup,
            name: value,
        });
    };

    /**
     * guardar grupo
     */
    const onSaveGroup = () => {
        present({
            message: "Por favor espera",
        });
        escudoWebAPI
            .updateGroup(id, editGroup)
            .then((res) => res.data.data)
            .then((group: any) => {
                dispatch(upsertOnegroup(group));

                presetAlert({
                    header: "Alerta",
                    message: "El grupo ha sido actualizado correctamente",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                            handler: () => {},
                        },
                    ],
                });
            })
            .catch((err: any) => {
                let message = feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    /**
     * guardar grupo
     */
    const onCreatemember = () => {
        history.push(`/app/groups/${id}/Create-member`);
    };

    /**
     * agregar un miembro a un grupo
     */
    const onShowAddMemberModalCreator = (user: any) => {
        setCellphoneSelected(user.cellphone);
        setIsOpentTypeMember(true);
    };

    /**
     * agregar un miembro a un grupo
     */
    const onShowAddMemberModalAdmin = (item: any) => {
        setConcactSelected(item);
        setIsOpenSelectCellphone(true);
    };
    /**
     * agregar un miembro a un grupo
     */
    const onSelectNumber = (action: any) => {
        let role = action.detail.role;
        setIsOpenSelectCellphone(false);

        if (role != "selected") {
            return;
        }

        let data = action.detail.data;

        if (canManageGroup) {
            setCellphoneSelected(data.cellphone);
            setIsOpentTypeMember(true);
        } else {
            onCreateMemberships(data.cellphone, id, MEMBERSHIPS.GUEST);
        }
    };

    /**
     * crear una membrecia
     */
    const onCreateMemberships = (
        cellphone: string,
        id: number,
        memberType: MEMBERSHIPS
    ) => {
        present({
            message: "Por favor espera",
        });

        createMembership(cellphone, memberType)
            .then(({ membership, user, group }) => {
                dispatch(upsertOnegroup(group));
                dispatch(addOneMembreship(membership));
                dispatch(upsertOneUser(user));

                presetAlert({
                    header: "Alerta",
                    message: "El usuario ha sido agregado correctamente",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                            handler: () => {
                                history.goBack();
                            },
                        },
                    ],
                });
            })
            .catch((error: any) => {
                if (error.response) {
                    if (error.response.status == 404) {
                        presetAlert({
                            header: "Alerta",
                            message:
                                "El usuario aún no pertenece a la comunidad de Escudo.",
                            backdropDismiss: false,
                            mode: "ios",
                            buttons: [
                                {
                                    text: "Cerrar",
                                    role: "cancel",
                                    handler: () => {},
                                },
                                {
                                    text: "Enviar Invitación",
                                    role: "confirm",
                                    handler: () => {
                                        sharedLink();
                                    },
                                },
                                {
                                    text: "Crear Nuevo Miembro",
                                    role: "confirm",
                                    handler: () => {
                                        onCreatemember();
                                    },
                                },
                            ],
                        });
                        return;
                    }
                }
                let message = feedback.getMessage(error);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    /**
     * Compartimos el link
     */
    const sharedLink = async () => {
        let playstoreApp = STORES_LINKS.PLAY_STORE;
        let appelStoreApp = STORES_LINKS.APPEL_sTORE;

        let url = STORES_LINKS.WEB_STORE;

        if (Capacitor.isNativePlatform()) {
            const logDeviceInfo = await Device.getInfo();

            let platform = logDeviceInfo.platform;
            let system = logDeviceInfo.operatingSystem;

            if (platform == "android") {
                url = playstoreApp;
            } else if (platform == "ios") {
                url = appelStoreApp;
            }
        }

        await Share.share({
            title: "Mantén protegido a tu vecindario",
            text:
                "Descarga e instala Escudo para proteger tus seres queridos: " +
                url,
            dialogTitle: "Comparte escudo con tus seres queridos",
        });
    };

    /**
     * cerrar el modal para eleguir el tipo de miembro
     */
    const onCloseTypeMember = () => {
        setCellphoneSelected("");
        setIsOpentTypeMember(false);
    };

    /**
     * Seleccionar un tipo de membrecia
     */
    function onDoneTypeMember(memberType: MEMBERSHIPS) {
        setIsOpentTypeMember(false);

        onCreateMemberships(cellphoneSelected, id, memberType);
    }

    /**
     * crear una nueva membrecia
     */
    const createMembership = async (
        cellphone: string,
        memberType: MEMBERSHIPS
    ) => {
        const membership = await escudoWebAPI
            .createMembership(id, { cellphone: cellphone, type: memberType })
            .then((res) => res.data.data);
        const user = await escudoWebAPI
            .getUserByMembership(membership.membership_id)
            .then((res) => res.data.data);
        const group = await escudoWebAPI
            .getGroupById(id)
            .then((res: any) => res.data.data);

        return {
            membership,
            user,
            group,
        };
    };

    /**
     * instrucciones del selec de los tipos de las dependencias
     */
    const customAlertOptions = {
        header: "Selecciona el tipo de dependencia",
        subHeader: "Elige un tipo de dependencia",
        translucent: true,
    };

    const customAlertOptions2 = {
        header: "Selecciona la dependencia a la que perteneces",
        subHeader: "Elige una dependencia",
        translucent: true,
    };

    /**
     * Accion para recuperar todas las dependencias y una sola por el grupo
     */
    useEffect(() => {
        const dependencyForGroup = async () => {
            const dependencyForGroup = await escudoWebAPI
                .GetDependenciforGroup(group.group_id)
                .then((res: any) => res.data.data);
            setDependency(dependencyForGroup);
            setSelectedTypeDependency(dependencyForGroup.type);
        };
        dependencyForGroup();
    }, []);

    /**
     * Accion para recuperar la dependencia de un grupo
     */

    useEffect(() => {
        const getAllDependency = async () => {
            const allDependencies = await escudoWebAPI
                .getAllDependenci()
                .then((res: any) => res.data.data);

            setAllDependencies(allDependencies);
        };

        getAllDependency();
    }, []);

    /**
     * Maneja la seleccion de un tipo de dependencia
     */

    const handleTypeChange = (customEvent: CustomEvent) => {
        const newState = customEvent.detail.value;
        setSelectedTypeDependency(newState);
    };

    const onHandlerDependencyChange = (
        selectedDependency: DependencyList
    ): any => {
        setDependency(selectedDependency);

        setEditGroup((prevEditGroup) => ({
            ...prevEditGroup,
            dependency: selectedDependency.catalog_dependency_id,
        }));
    };

    return (
        <IonPage className="edit-group-page">
            <ToolBar title="Grupo"></ToolBar>
            <IonContent className="ion-padding">
                <IonCard className="contacts ion-margin-top">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonInput
                                    name="name"
                                    maxlength={30}
                                    className="names"
                                    labelPlacement="stacked"
                                    placeholder="Nombre del grupo"
                                    value={editGroup.name}
                                    onIonInput={onChangeGroupName}
                                    required
                                    counter={true}>
                                    <div className="text-label" slot="label">
                                        Nombre del grupo
                                        <IonText color="danger">*</IonText>
                                    </div>
                                </IonInput>
                            </IonCol>
                        </IonRow>
                        {canManageGroup && (
                            <>
                                <IonRow>
                                    <IonCol
                                        size="12"
                                        className="text-label-dif">
                                        Tipo de dependencia a la que pertenece{" "}
                                        <IonText color="danger">*</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonItem
                                            lines="none"
                                            className="icon-dependenci">
                                            <IonIcon
                                                src={
                                                    selectedTypeDependency === 1
                                                        ? State_dependence
                                                        : selectedTypeDependency ===
                                                          2
                                                        ? municipality_dependence
                                                        : Another_dependenci
                                                }
                                                slot="start"
                                            />
                                            <IonSelect
                                                className="select-dependencia"
                                                interfaceOptions={
                                                    customAlertOptions
                                                }
                                                interface="alert"
                                                mode="md"
                                                placeholder="Selecciona el tipo de dependencia"
                                                toggleIcon={chevronDownOutline}
                                                expandedIcon={chevronUpOutline}
                                                value={selectedTypeDependency}
                                                onIonChange={handleTypeChange}>
                                                <IonSelectOption value={1}>
                                                    Estatal
                                                </IonSelectOption>
                                                <IonSelectOption value={2}>
                                                    Municipal
                                                </IonSelectOption>
                                                <IonSelectOption value={3}>
                                                    Independiente
                                                </IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol
                                        size="12"
                                        className="text-label-dif">
                                        Nombre de su dependencia
                                        <IonText color="danger">*</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonSelect
                                            interfaceOptions={
                                                customAlertOptions2
                                            }
                                            interface="alert"
                                            className="select"
                                            toggleIcon={chevronDownOutline}
                                            expandedIcon={chevronUpOutline}
                                            placeholder="Selecciona una dependencia"
                                            onIonChange={(e) =>
                                                onHandlerDependencyChange(
                                                    e.detail.value
                                                )
                                            }
                                            mode="md"
                                            compareWith={(
                                                o1: DependencyList,
                                                o2: DependencyList
                                            ) => {
                                                return o1 && o2
                                                    ? o1.catalog_dependency_id ===
                                                          o2.catalog_dependency_id
                                                    : o1 === o2;
                                            }}
                                            value={dependency}>
                                            {allDependencies
                                                .filter(
                                                    (dep: DependencyList) =>
                                                        !selectedTypeDependency ||
                                                        dep.type ===
                                                            selectedTypeDependency
                                                )
                                                .sort(
                                                    (
                                                        a: DependencyList,
                                                        b: DependencyList
                                                    ) =>
                                                        a.name.localeCompare(
                                                            b.name
                                                        )
                                                )
                                                .map((dep: DependencyList) => (
                                                    <IonSelectOption
                                                        key={
                                                            dep.catalog_dependency_id
                                                        }
                                                        value={dep}>
                                                        {dep.name}
                                                    </IonSelectOption>
                                                ))}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </IonGrid>
                </IonCard>

                <IonRow className="ion-margin flex-row ion-text-center">
                    <IonCol size="12">
                        <IonButton
                            onClick={onCreatemember}
                            size="small"
                            className="user-add-button"
                            mode="ios">
                            <IonIcon
                                slot="start"
                                icon={personAddOutline}></IonIcon>
                            <IonText>Crea un usuario nuevo</IonText>
                        </IonButton>
                    </IonCol>
                </IonRow>
                {AdminGroup && (
                    <>
                        <IonCard className="contacts ion-margin-top">
                            <IonCardHeader className="contacts-header">
                                <IonCardTitle className="ion-onCreatemembertext-center">
                                    Agregar participantes
                                </IonCardTitle>
                                <IonCol
                                    size="12"
                                    className="ion-text-center"></IonCol>
                                <IonSearchbar
                                    placeholder="Busca un nombre en tus contactos"
                                    debounce={100}
                                    animated={true}
                                    mode="ios"
                                    clearIcon={trashBin}
                                    onIonInput={(ev) => {
                                        setSearchInputValue(
                                            ev.target.value ?? ""
                                        );
                                        handleSearchContactsAdmin(ev);
                                    }}
                                    className="search-custome"></IonSearchbar>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonList>
                                    {results.length > 0 ? (
                                        results.map((item: any) => (
                                            <IonItem
                                                key={item.contactId}
                                                className="add-member">
                                                <IonLabel>
                                                    {item.name?.display}
                                                </IonLabel>
                                                <IonButton
                                                    className="add-member-button"
                                                    slot="end"
                                                    fill="solid"
                                                    onClick={() =>
                                                        onShowAddMemberModalAdmin(
                                                            item
                                                        )
                                                    }>
                                                    <IonIcon
                                                        slot="icon-only"
                                                        src={
                                                            arrowForward
                                                        }></IonIcon>
                                                </IonButton>
                                            </IonItem>
                                        ))
                                    ) : (
                                        <IonText className="flex-row ion-text-center">
                                            No se encontraron resultados
                                        </IonText>
                                    )}
                                </IonList>
                            </IonCardContent>
                        </IonCard>

                        <IonActionSheet
                            mode="ios"
                            className="phones-list"
                            isOpen={isOpenSelectCellphone}
                            header="Seleccionar un número de teléfono"
                            buttons={[
                                ...concactSelected?.phones.map((phone: any) => {
                                    return {
                                        text: phone?.number,
                                        role: "selected",
                                        data: {
                                            cellphone: phone.number,
                                        },
                                    };
                                }),
                                {
                                    text: "Cancelar",
                                    role: "cancel",
                                    data: {
                                        action: "cancel",
                                    },
                                },
                            ]}
                            onDidDismiss={(action) =>
                                onSelectNumber(action)
                            }></IonActionSheet>
                    </>
                )}
                {canManageGroup && (
                    <IonCard className="contacts ion-margin-top">
                        <IonCardHeader mode="md" className="contacts-header">
                            {searchError && (
                                <div className="search-error-message ion-padding-top">
                                    <IonText className="search-error-text">
                                        {searchError}
                                    </IonText>
                                </div>
                            )}

                            <IonCardTitle className="ion-onCreatemembertext-center">
                                Agregar participantes
                            </IonCardTitle>
                            <IonCol
                                size="12"
                                className="ion-text-center"></IonCol>
                            <IonSearchbar
                                placeholder="Busca un miembro"
                                debounce={2000}
                                animated={true}
                                mode="ios"
                                clearIcon={trashBin}
                                onIonInput={(ev) => {
                                    const searchValue =
                                        ev.target?.value?.trim() ?? "";
                                    setSearchInputValue(searchValue);
                                    if (searchValue !== "") {
                                        handleSearchContactsCreator(ev);
                                    } else {
                                        setResults([]);
                                    }
                                }}
                                className="search-custome"></IonSearchbar>
                        </IonCardHeader>
                        <IonCardContent>
                            {debounceLoading ? (
                                <IonList className="flew-row ion-text-center">
                                    <IonItem>
                                        <IonThumbnail slot="start">
                                            <IonSkeletonText
                                                animated={
                                                    true
                                                }></IonSkeletonText>
                                        </IonThumbnail>
                                        <IonLabel>
                                            <h3>
                                                <IonSkeletonText
                                                    animated={true}
                                                    style={{
                                                        width: "80%",
                                                    }}></IonSkeletonText>
                                            </h3>
                                            <p>
                                                <IonSkeletonText
                                                    animated={true}
                                                    style={{
                                                        width: "60%",
                                                    }}></IonSkeletonText>
                                            </p>
                                            <p>
                                                <IonSkeletonText
                                                    animated={true}
                                                    style={{
                                                        width: "30%",
                                                    }}></IonSkeletonText>
                                            </p>
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                            ) : (
                                <>
                                    {results.data &&
                                    results.data.data.length > 0 ? (
                                        results.data.data.map((user: any) => (
                                            <IonItem key={user.user_id}>
                                                <IonThumbnail slot="start">
                                                    <IonIcon
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                        icon={
                                                            AdminIcon
                                                        }></IonIcon>
                                                </IonThumbnail>
                                                <IonLabel>
                                                    <h3>
                                                        {user.name}{" "}
                                                        {user.last_name}{" "}
                                                        {user.seccond_last_name}
                                                    </h3>
                                                    <p>{user.cellphone}</p>
                                                    <p>{user.email}</p>
                                                </IonLabel>
                                                <IonButton
                                                    mode="ios"
                                                    className="add-member-button"
                                                    slot="end"
                                                    fill="solid"
                                                    onClick={() => {
                                                        onShowAddMemberModalCreator(
                                                            user
                                                        );
                                                    }}>
                                                    <IonIcon
                                                        slot="icon-only"
                                                        src={
                                                            arrowForward
                                                        }></IonIcon>
                                                </IonButton>
                                            </IonItem>
                                        ))
                                    ) : (
                                        <div className="show-mesasage ion-padding-top">
                                            <IonLabel>
                                                {searchInputValue.trim() ===
                                                "" ? (
                                                    <IonText>
                                                        Comienza tu búsqueda
                                                    </IonText>
                                                ) : (
                                                    <IonText>
                                                        No se encontraron
                                                        resultados
                                                    </IonText>
                                                )}
                                            </IonLabel>
                                        </div>
                                    )}
                                </>
                            )}
                        </IonCardContent>
                    </IonCard>
                )}
                <div className="footer-pattern"></div>

                <IonModal
                    isOpen={isOpentTypeMember}
                    id="type-membership-modal"
                    backdropDismiss={false}>
                    <IonContent>
                        <IonToolbar>
                            <IonTitle>Nuevo miembro</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={onCloseTypeMember}>
                                    <IonIcon
                                        size="large"
                                        icon={close}></IonIcon>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <IonList className="ion-padding">
                            Por favor selecciona el tipo de membresía para el
                            nuevo miembro:
                        </IonList>
                        <IonText className="cellphone ion-margin-top">
                            {cellphoneSelected}
                        </IonText>

                        <div className="bottons ion-padding">
                            <IonButton
                                className="GUEST"
                                mode="ios"
                                strong={true}
                                onClick={() =>
                                    onDoneTypeMember(MEMBERSHIPS.GUEST)
                                }>
                                Invitado
                            </IonButton>

                            <IonButton
                                className="ADMINISTRATOR"
                                mode="ios"
                                strong={true}
                                onClick={() =>
                                    onDoneTypeMember(MEMBERSHIPS.ADMINISTRATOR)
                                }>
                                Administrador
                            </IonButton>
                        </div>
                    </IonContent>
                </IonModal>

                <IonRow className="ion-margin-top">
                    <IonCol size="12" className="ion-text-center">
                        <IonButton
                            onClick={onSaveGroup}
                            className="update-group-button"
                            disabled={
                                editGroup.name == "" ||
                                editGroup.dependency == null
                            }
                            mode="ios">
                            Guardar
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EditGroup;
