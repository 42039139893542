import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSkeletonText,
    IonText,
    IonThumbnail,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ToolBar from "../../../../../../components/ToolBar";
import { useHistory } from "react-router";
import "./style.scss";
import { closeCircleOutline, trashBin } from "ionicons/icons";
import escudoWebAPI from "../../../../../../services/escudoWebAPI";
import { useFeedback } from "../../../../../../hooks";
import { useDispatch } from "react-redux";
import * as _ from "lodash-es";
import AdminIcon from "../../../../../../assets/icons/Admin-icon.svg";
import anime from "animejs";
import check from "../../../../../../assets/icons/check.svg";
import ConfirmationModal from "./pages/modalConfirmation";

const CreateSuperShields: React.FC = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    const [present, dismiss] = useIonLoading();
    const [presetAlert] = useIonAlert();
    const feedbackApp = useFeedback();
    const dispatch = useDispatch();

    /**
     * local state
     */

    const [results, setResults] = useState<any>([]);
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [debounceLoading, setDebounceLoading] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [searchError, setSearchError] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [userId, setUserId] = useState("");
    const [RoleId, setRoleId] = useState(null);
    /**
     * filtrado de los contactos
     */

    const handleSearchContacts = (ev: any) => {
        const query = (ev.detail.value as string).toLowerCase();
        if (query.length < 3) {
            setSearchError("Ingrese al menos 3 caracteres");
            return;
        }
        setSearchError(null);
        setDebounceLoading(true);
        setTimeout(() => {
            escudoWebAPI
                .SearchUsers(query)
                .then((dataFromApi) => setResults(dataFromApi))
                .catch(() => setResults([]))
                .finally(() => {
                    setDebounceLoading(false);
                });
        }, 1000);
    };

    /**
     * agregar una burbuja
     */
    const onShowAddMemberbubble = (user: any) => {
        toggleUserSelection(user);
    };

    /**
     * animacion de la burbuja
     */
    const animateCheckIcon = (user_id: string, fadeIn: boolean) => {
        anime({
            targets: `.check-icon-animation-${user_id}`,
            scale: fadeIn ? [0, 1] : [1, 0],
            opacity: fadeIn ? [0, 1] : [1, 0],
            duration: 500,
            easing: "easeInOutQuad",
        });
    };

    /**
     * Select or deselect a user
     */
    const toggleUserSelection = (user: any) => {
        const isSelected = selectedUsers.some(
            (selectedUser) => selectedUser.user_id === user.user_id
        );

        if (isSelected) {
            animateCheckIcon(user.user_id, false);

            anime({
                targets: `.selected-user-bubble[data-userid="${user.user_id}"]`,
                opacity: [1, 0],
                translateY: [0, 20],
                duration: 500,
                easing: "easeInOutExpo",
                complete: () => {
                    const updatedUsers = selectedUsers.filter(
                        (selectedUser) => selectedUser.user_id !== user.user_id
                    );
                    setSelectedUsers(updatedUsers);
                },
            });
        } else {
            anime({
                targets: ".selected-user-bubble",
                opacity: [0, 1],
                translateY: [20, 0],
                duration: 500,
                easing: "easeInOutExpo",
                complete: () => {
                    setSelectedUsers([user]);
                    animateCheckIcon(user.user_id, true);
                },
            });
        }
    };
    /**
     * funcion para animar el icono de check
     */
    useEffect(() => {
        selectedUsers.forEach((user) => {
            animateCheckIcon(user.user_id, true);
        });
    }, [selectedUsers]);

    /**
     * funcion para crear un ususario SS
     */

    const onCreateSS = () => {
        const userId = selectedUsers.length > 0 ? selectedUsers[0].user_id : "";
        setUserId(userId);
        escudoWebAPI
            .getRoleUser(userId)
            .then((res: any) => {
                setRoleId(res.data.data[0].rol_id);
                setShowModal(true);
            })
            .catch(({ feedback }: any) => {
                presetAlert({
                    header: "Error",
                    message:
                        "Lo sentimos, estamos experimentando problemas en este momento. Por favor, inténtelo de nuevo más tarde.",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "OK",
                            role: "confirm",
                        },
                    ],
                });
            });
    };

    const handleCancel = () => {
        setShowModal(false);
    };
    const handleConfirm = () => {
        escudoWebAPI
            .createSuperShield(RoleId)
            .then((res: any) => {
                setShowModal(false);
                presetAlert({
                    header: "Super Administrador Creado",
                    message: "Se ha registrado un nuevo super administrador.",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "OK",
                            role: "confirm",
                            handler: () => {
                                history.goBack();
                            },
                        },
                    ],
                });
            })
            .catch(({ feedback }: any) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    /**
     * Render selected users as bubbles
     */
    const renderSelectedUsers = () => {
        return (
            <div style={{ minHeight: "80px" }}>
                {selectedUsers.map((user) => (
                    <>
                        <div className="ion-text-center ion-margin-bottom">
                            <IonLabel className="selected-title">
                                Super Administrador seleccionado
                            </IonLabel>
                        </div>
                        <IonList key={user.user_id}>
                            <IonChip
                                mode="ios"
                                data-userid={user.user_id}
                                className="selected-user-bubble"
                                onClick={() => toggleUserSelection(user)}>
                                <IonLabel>
                                    {user.name} {user.last_name}
                                </IonLabel>
                                <IonIcon
                                    icon={closeCircleOutline}
                                    onClick={() => toggleUserSelection(user)}
                                />
                            </IonChip>
                        </IonList>
                    </>
                ))}
            </div>
        );
    };
    return (
        <IonPage className="Create-user-shield-userexist-page">
            <ToolBar title="Convertir super Administrador"></ToolBar>
            <IonContent className="ion-padding">
                <IonCard className="contacts">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonCardHeader className="contacts-header">
                                    <IonCardTitle className="ion-text-center">
                                        {searchError && (
                                            <div className="search-error-message ion-padding-top">
                                                <IonText className="search-error-text">
                                                    {searchError}
                                                </IonText>
                                            </div>
                                        )}
                                        <IonText className="Header-title">
                                            Convertir a Super Administrador
                                        </IonText>
                                    </IonCardTitle>
                                    <IonSearchbar
                                        placeholder="Busca un miembro"
                                        debounce={2000}
                                        animated={true}
                                        mode="ios"
                                        clearIcon={trashBin}
                                        onIonInput={(ev) => {
                                            const searchValue =
                                                ev.target?.value?.trim() ?? "";
                                            setSearchInputValue(searchValue);
                                            if (searchValue !== "") {
                                                handleSearchContacts(ev);
                                            } else {
                                                setResults([]);
                                            }
                                        }}
                                        className="search-custome"></IonSearchbar>
                                </IonCardHeader>
                            </IonCol>
                            <IonCol size="12">
                                <IonCardContent>
                                    <IonList>{renderSelectedUsers()}</IonList>
                                    <IonList>
                                        <IonListHeader className="contacts-header">
                                            <IonLabel>
                                                Lista de usuarios
                                            </IonLabel>
                                        </IonListHeader>
                                        {debounceLoading ? (
                                            <IonList>
                                                <IonItem>
                                                    <IonThumbnail slot="start">
                                                        <IonSkeletonText
                                                            animated={
                                                                true
                                                            }></IonSkeletonText>
                                                    </IonThumbnail>
                                                    <IonLabel>
                                                        <h3>
                                                            <IonSkeletonText
                                                                animated={true}
                                                                style={{
                                                                    width: "80%",
                                                                }}></IonSkeletonText>
                                                        </h3>
                                                        <p>
                                                            <IonSkeletonText
                                                                animated={true}
                                                                style={{
                                                                    width: "60%",
                                                                }}></IonSkeletonText>
                                                        </p>
                                                        <p>
                                                            <IonSkeletonText
                                                                animated={true}
                                                                style={{
                                                                    width: "30%",
                                                                }}></IonSkeletonText>
                                                        </p>
                                                    </IonLabel>
                                                </IonItem>
                                            </IonList>
                                        ) : !debounceLoading ? (
                                            results.data &&
                                            results.data.data.length > 0 ? (
                                                results.data.data.map(
                                                    (user: any) => (
                                                        <div key={user.user_id}>
                                                            <IonItem
                                                                onClick={() =>
                                                                    onShowAddMemberbubble(
                                                                        user
                                                                    )
                                                                }>
                                                                <IonThumbnail slot="start">
                                                                    <IonIcon
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                        }}
                                                                        icon={
                                                                            AdminIcon
                                                                        }></IonIcon>
                                                                </IonThumbnail>
                                                                <IonLabel>
                                                                    <h3>
                                                                        {
                                                                            user.name
                                                                        }{" "}
                                                                        {
                                                                            user.last_name
                                                                        }{" "}
                                                                        {
                                                                            user.seccond_last_name
                                                                        }
                                                                    </h3>
                                                                    <p>
                                                                        {
                                                                            user.cellphone
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {
                                                                            user.email
                                                                        }
                                                                    </p>
                                                                </IonLabel>
                                                                {selectedUsers.some(
                                                                    (
                                                                        selectedUser
                                                                    ) =>
                                                                        selectedUser.user_id ===
                                                                        user.user_id
                                                                ) && (
                                                                    <IonIcon
                                                                        src={
                                                                            check
                                                                        }
                                                                        color="success"
                                                                        slot="end"
                                                                        className={`check-icon-animation-${user.user_id}`}
                                                                    />
                                                                )}
                                                            </IonItem>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <div className="show-mesasage ion-padding-top">
                                                    <IonText>
                                                        {searchInputValue.trim() ===
                                                        ""
                                                            ? "Comienza a buscar Super Administrador"
                                                            : "No se encontraron resultados"}
                                                    </IonText>
                                                </div>
                                            )
                                        ) : null}
                                    </IonList>
                                </IonCardContent>
                                <IonRow className="ion-margin-top">
                                    <IonCol
                                        size="12"
                                        className="ion-text-center">
                                        <IonButton
                                            onClick={onCreateSS}
                                            className="Create-user-shield-button"
                                            disabled={
                                                !(selectedUsers.length > 0)
                                            }
                                            mode="ios">
                                            Convertir
                                        </IonButton>
                                        <ConfirmationModal
                                            isOpen={showModal}
                                            message="¿Estás seguro de que deseas realizar esta acción? Esta acción puede tener consecuencias importantes."
                                            onCancel={handleCancel}
                                            onConfirm={handleConfirm}
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>
                <div className="footer-pattern"></div>
            </IonContent>
        </IonPage>
    );
};

export default CreateSuperShields;
