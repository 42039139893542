import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonPage,
    IonRow,
    IonIcon,
    IonText,
    useIonLoading,
} from "@ionic/react";
import lock from "../../../../assets/icons/lock-closed-outline.svg";
import ToolBar from "../../../../components/ToolBar";

import lockOut from "../../../../assets/icons/lock-open-outline.svg";
import Twoarrows from "../../../../assets/icons/TwoArrows.svg";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import ModalUpdatePass from "./Modal/ModalUpdatePass";
import "./changePass.scss";
import { useDispatch } from "react-redux";
import { useFeedback } from "../../../../hooks";
import { updateUserPassword } from "../../../../redux/slices/auth";

const ChangePassc: React.FC = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    const dispatch = useDispatch();
    const [present, dismiss] = useIonLoading();
    const feedbackApp = useFeedback();

    /**
     * local state
     */
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPass, setNewPass] = useState("");
    const [oldPassword, setOldPassword] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    /**
     * cerrar el modal y dirigir al perfil
     */
    const closeModal = () => {
        setIsModalOpen(false);
        history.goBack();
    };

    /**
     * Realziar el cambio de contraseña
     */
    const OnHandleChangePass = () => {
        present({
            message: "Por favor espere",
        });

        dispatch<any>(
            updateUserPassword({
                old_password: oldPassword,
                password: newPass,
                password_confirmation: confirmPassword,
            })
        )
            .unwrap()
            .then(() => {
                setIsModalOpen(true);
            })
            .catch(({ feedback }: any) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    return (
        <IonPage className="Update-pass-page">
            <ModalUpdatePass isOpen={isModalOpen} closeModal={closeModal} />
            <ToolBar title="Actualiza" />
            <IonContent className="Update-pass-content">
                <IonGrid>
                    <IonRow>
                        <IonCol
                            size="12"
                            className="ion-text-center ion-padding-top"
                        >
                            <IonIcon className="Update-Icon" src={lock}>
                                {" "}
                            </IonIcon>
                            <IonIcon
                                className="Update-Icon-arrow"
                                src={Twoarrows}
                            >
                                {" "}
                            </IonIcon>
                            <IonIcon className="Update-Icon" src={lockOut}>
                                {" "}
                            </IonIcon>
                        </IonCol>
                        <IonCol
                            size="12"
                            className="ion-text-center ion-padding-top"
                        >
                            <IonText className="ion-padding-top Update-pass-Title">
                                Actualizar Contraseña
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className=" ion-padding" size="12">
                            <IonText className="Update-pass-Text">
                                En esta pantalla, puedes cambiar tu contraseña.
                                Por favor, sigue los pasos a continuación.
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none" className="passUpdate">
                                <IonInput
                                    name="passLeasted"
                                    labelPlacement="stacked"
                                    placeholder="•••••••••••••"
                                    type={showPasswordOld ? "text" : "password"}
                                    required
                                    maxlength={100}
                                    counter={true}
                                    value={oldPassword}
                                    onIonInput={(e) =>
                                        setOldPassword(e.detail.value!)
                                    }
                                >
                                    <div className="text-label" slot="label">
                                        Ingresa tu contraseña antigua
                                    </div>
                                </IonInput>
                                <IonIcon
                                    className="icon"
                                    slot="end"
                                    icon={
                                        showPasswordOld
                                            ? eyeOutline
                                            : eyeOffOutline
                                    }
                                    onClick={() =>
                                        setShowPasswordOld(!showPasswordOld)
                                    }
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none" className="passUpdate">
                                <IonInput
                                    maxlength={100}
                                    counter={true}
                                    name="passNew"
                                    labelPlacement="stacked"
                                    placeholder="•••••••••••••"
                                    type={showPasswordNew ? "text" : "password"}
                                    required
                                    value={newPass}
                                    onIonInput={(e) =>
                                        setNewPass(e.detail.value!)
                                    }
                                >
                                    <div className="text-label" slot="label">
                                        Ingresa tu nueva contraseña
                                    </div>
                                </IonInput>
                                <IonIcon
                                    className="icon"
                                    slot="end"
                                    icon={
                                        showPasswordNew
                                            ? eyeOutline
                                            : eyeOffOutline
                                    }
                                    onClick={() =>
                                        setShowPasswordNew(!showPasswordNew)
                                    }
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none" className="passUpdate">
                                <IonInput
                                    maxlength={100}
                                    counter={true}
                                    name="Rememberpass"
                                    labelPlacement="stacked"
                                    placeholder="•••••••••••••"
                                    type={
                                        showPasswordConfirm
                                            ? "text"
                                            : "password"
                                    }
                                    required
                                    onIonInput={(e) =>
                                        setConfirmPassword(e.detail.value!)
                                    }
                                    value={confirmPassword}
                                >
                                    <div className="text-label" slot="label">
                                        Confirma tu nueva contraseña
                                    </div>
                                </IonInput>
                                <IonIcon
                                    className="icon"
                                    slot="end"
                                    icon={
                                        showPasswordConfirm
                                            ? eyeOutline
                                            : eyeOffOutline
                                    }
                                    onClick={() =>
                                        setShowPasswordConfirm(
                                            !showPasswordConfirm
                                        )
                                    }
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <div className="button-register ion-justify-content-center">
                        <IonButton
                            className="update-button"
                            onClick={OnHandleChangePass}
                            disabled={
                                newPass !== confirmPassword ||
                                newPass === "" ||
                                confirmPassword === "" ||
                                oldPassword === ""
                            }
                        >
                            Cambiar contraseña
                        </IonButton>
                    </div>
                </IonGrid>
                <div className="footerUpdate"></div>
            </IonContent>
        </IonPage>
    );
};

export default ChangePassc;
