import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";

const Modal: React.FC<any> = ({ isOpen, setOpen }) => {
    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === "confirm") {
            //setMessage(`Hello, ${ev.detail.data}!`);
        }
    }

    return (
        <IonModal isOpen={isOpen} onWillDismiss={(ev) => setOpen(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => setOpen(false)}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Welcome</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true}>Confirm</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>sdfsdfsdfsf</IonItem>
            </IonContent>
        </IonModal>
    );
};

export default Modal;
