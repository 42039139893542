import {
    IonCard,
    IonCardContent,
    IonContent,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow,
    IonText,
    IonTextarea,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useState } from "react";
import ToolBar from "../../../../components/ToolBar";
import { useHistory, useParams } from "react-router";
import { selectViewModel } from "./selectors";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useFeedback } from "../../../../hooks";
import Feedback from "../../../../services/feedback";
import { arrowForward } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { CreateDescriptionToIncident } from "../../../../redux/slices/app";
import "./styles.scss";

const CompleteIncidente: React.FC = () => {
    /**
     * hooks
     */

    const history = useHistory();
    const feedbackApp = useFeedback();
    const { id } = useParams<any>();
    const viewModel = useSelector(selectViewModel(id));
    const [present, dismiss] = useIonLoading();
    const [presetAlert] = useIonAlert();
    const dispatch = useDispatch();

    /**
     * Estado local
     */

    const [description, setDescription] = useState("");

    /**
     * calculacion de datos
     */
    const catalogIncident = viewModel.catalogIncident;
    const incident = viewModel.incident;
    const createAt = DateTime.fromSQL(incident.created_at).setLocale("es");
    const horaEnFormato12H = createAt.toFormat("h:mm a");

    /**
     * cambios en la entrada de inputs
     */
    const onChangeInput = ({ detail }: any) => {
        setDescription(detail.value);
    };

    let title = null;
    let darkcolor = "#FFF";
    let color = catalogIncident.color;

    switch (catalogIncident.catalog_incident_id) {
        case 1:
            darkcolor = "#D64280";
            title = catalogIncident.title.split(" ")[1];
            break;
        case 2:
            darkcolor = "#322FCE";
            title = "de" && catalogIncident.title;
            break;
        case 3:
            darkcolor = "#F39912";
            title = "de" && catalogIncident.title;
            break;
        case 4:
            darkcolor = "#73BA3C";
            title = "de" && catalogIncident.title;
            break;
        case 5:
            darkcolor = "#DF6921";
            title = "de" && catalogIncident.title;
            break;
        default:
            darkcolor = "#8400E6";
            title = "de" && catalogIncident.title;
            break;
    }
    color =
        "linear-gradient(260deg, " + color + " 8.9%, " + darkcolor + " 94.19%)";

    /**
     * Guardar la descripcion
     */
    const onSaveDescription = () => {
        present({
            message: "Por favor espera",
        });

        dispatch<any>(
            CreateDescriptionToIncident({
                incidentId: id,
                description: description,
            })
        )
            .then(() => {
                presetAlert({
                    header: "La ayuda va en camino",
                    message: "El proceso a terminado correctamente",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                            handler: () => {
                                history.goBack();
                            },
                        },
                    ],
                });
            })
            .catch((err: any) => {
                let message = Feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    return (
        <IonPage className="complete-alert-page">
            <ToolBar title="Reporte"></ToolBar>

            <IonContent className="ion-padding page-incident">
                <div>
                    <IonCard
                        style={{ "--background": color }}
                        className="incident-desc">
                        <IonCardContent className="ion-text-center">
                            <div>
                                <IonText className="title1">
                                    ¡ Se ha enviado una{" "}
                                </IonText>
                                <IonText className="title2">
                                    Emergencia{" "}
                                </IonText>
                                <IonText className="title3">{title} </IonText>
                                <IonText className="title4">
                                    a tus vecinos!
                                </IonText>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <div className="alert-div">
                        <div className="card-image flex-column ion-justify-content-center ion-align-items-center">
                            <IonIcon
                                src={
                                    "assets/icon/" +
                                    catalogIncident.icon +
                                    ".svg"
                                }
                            />
                        </div>
                    </div>
                </div>

                <IonCard className="date-card">
                    <IonCardContent className="ion-text-center">
                        <IonGrid className="grid-text">
                            <IonRow>
                                <IonText className="date-text-description">
                                    {createAt.toLocaleString({
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                    })}
                                </IonText>
                            </IonRow>
                            <IonRow className="row-time">
                                <IonText
                                    className="time-text"
                                    style={{
                                        color: catalogIncident.color,
                                    }}>
                                    {horaEnFormato12H}
                                </IonText>
                            </IonRow>
                            <IonText className="folio-text">FOLIO:</IonText>
                            <IonText className="incident-folio">
                                {incident.invoice}
                            </IonText>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <div className="ion-text-center describe">
                    <IonText>Describe el Incidente</IonText>
                </div>

                <IonCard className="card-description">
                    <IonTextarea
                        label="Descripción"
                        labelPlacement="floating"
                        placeholder="* Describe el incidente, de esa manera tus vecinos podrán auxiliarte o pedir ayuda inmediata..."
                        counter={true}
                        maxlength={500}
                        counterFormatter={(inputLength, maxLength) =>
                            `${inputLength}/${maxLength}`
                        }
                        value={description}
                        onIonInput={onChangeInput}
                        rows={4}
                        className="text-area-description "></IonTextarea>

                    <IonCard
                        onClick={onSaveDescription}
                        disabled={description === ""}
                        className="card-description">
                        <div className="direction">
                            <div
                                className="circle"
                                style={{
                                    background: catalogIncident.color,
                                }}>
                                <IonIcon icon={arrowForward}></IonIcon>
                            </div>
                        </div>
                    </IonCard>
                </IonCard>
                <div className="footer"></div>
            </IonContent>
        </IonPage>
    );
};

export default CompleteIncidente;
