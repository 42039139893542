import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { selectAllCatalogIncidents } from "../catalogincidents";


/**
 * create adapter for the incident entity
 */
const incidentAdapter = createEntityAdapter<any>({
    selectId: (incident) => incident.incident_id,
    sortComparer: (incidentA, incidentB) => incidentA.incident_id - incidentB.incident_id,
})

/**
 * incidents slice definitions
 */
export const incidentsSlice = createSlice({
    name: "incidents",
    initialState: incidentAdapter.getInitialState(),
    reducers: {
        addManyIncidents: incidentAdapter.addMany,
        addOneIncident: incidentAdapter.addOne,
        upsertOneIncident: incidentAdapter.upsertOne,
        upsertManyIncidents: incidentAdapter.upsertMany,
        setAllIncidents: incidentAdapter.setAll
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return incidentAdapter.getInitialState()
        })
    }
})

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManyIncidents, addOneIncident, upsertOneIncident, upsertManyIncidents,
    setAllIncidents
} = incidentsSlice.actions

/**
 * export reducer autegenerated by toolkit
 */
export default incidentsSlice.reducer

//////////////////// SELECTORES //////////////////

/**
 * global selectors
 */
const globalSelectors = incidentAdapter.getSelectors((store: any) => store.entities.incidents)

export const selectAllIncidents = (store: any) => globalSelectors.selectAll(store)
export const selectIncidentById = (incidentId: any) => (store: any) => globalSelectors.selectById(store, incidentId)
export const selectIncidentsIds = (store: any) => globalSelectors.selectIds(store)
export const selectIncidentsEntities = (store: any) => globalSelectors.selectEntities(store)

/**
 * select for recover one incident by id
 */
export const selectTotalIncidentsById = (userId: number) => createSelector(
    selectAllIncidents, 
    (allIncidnets) => {
        return allIncidnets.filter(incident => incident.user_id == userId).length
    }
)

/**
 * selector para recuperar todos los incidents con catalogo
 */
export const selectIncidentsWithCatalog = createSelector(
    selectAllIncidents,
    selectAllCatalogIncidents,
    (allIncidnets, allCatalog) => {
        return allIncidnets.map(incident => ({incident, catalog: allCatalog.find(catalog => incident.catalog_incident_id == catalog.catalog_incident_id)}))
    }
)

/**
 * selector para recupera incidentes por grupo
 */
export const selectIncidentsByGroup = (groupId: number) => createSelector(
    selectAllIncidents,
    (incidents) => incidents.filter((incident: any)=> incident.group_id == groupId) 
)

/**
 * selector para recupera incidentes con catalogo por grupo
 */
export const selectIncidentsWithCatalogByGroup = (groupId: number) => createSelector(
    selectIncidentsByGroup(groupId),
    selectAllCatalogIncidents,
    (incidents, catalog) => {
        return incidents.map((incident: any) => {
            return {
                incident,
                catalog: catalog.find((catalog => catalog.catalog_incident_id == incident.catalog_incident_id))
            }
        })
    }
)
