import { createSelector } from "reselect";
import { selectMeGroups } from "../../../../../../redux/slices/entities/groups";
import { selectAllMembreshipsActived } from "../../../../../../redux/slices/entities/memberships";
import { MEMBERSHIPS } from "../../../../../../constants";

// Selector para obtener los grupos con su creador
export const selectGroupsWithCreator = createSelector(
    [selectMeGroups, selectAllMembreshipsActived],
    (groups, memberships) => {
        return groups.map((group: any) => {
            const creatorMembership = memberships.find(
                (membership: any) =>
                    membership.groupId === group.id &&
                    membership.type === MEMBERSHIPS.CREATOR
            );
            return {
                ...group,
                creator: [creatorMembership] ?? null,
            };
        });
    }
);
