import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, useLocation } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";

import React from "react";

/* Theme variables */
import "../theme/variables.css";

/* components */
import Login from "./login";
import Register from "./register";
import Tabs from "./tabs";
import FeedbackProvider from "../providers/FeedbackProvider";
import { useNotificationService } from "../hooks";
import PermissionProvider from "../providers/Permissions/PermissionProvider";
import usePermission from "../hooks/usePermission";

const RoutesMain: React.FC = () => {
    /**
     * Hooks
     */
    useNotificationService();
    usePermission();

    return (
        <>
            <FeedbackProvider>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <PermissionProvider>
                            <Route path="/login" component={Login} />
                            <Route path="/register" component={Register} />
                            <Route path="/app">
                                <Tabs loggedIn={false} />
                            </Route>
                            <Redirect exact path="/" to="/login" />
                        </PermissionProvider>
                    </IonRouterOutlet>
                </IonReactRouter>
            </FeedbackProvider>
        </>
    );
};

export default RoutesMain;
