import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

/**
 * create adapter for the incident entity
 */
const dependenciesAdapter = createEntityAdapter<any>({
    selectId: (dependencies) => dependencies.catalog_dependency_id,
    sortComparer: (dependenciesA, dependenciesB) =>
        dependenciesA.catalog_dependency_id -
        dependenciesB.catalog_dependency_id,
});

/**
 * incidents slice definitions
 */
export const dependenciesSlice = createSlice({
    name: "dependencies",
    initialState: dependenciesAdapter.getInitialState(),
    reducers: {
        addManydependencies: dependenciesAdapter.addMany,
        addOnedependencies: dependenciesAdapter.addOne,
        setAlldependencies: dependenciesAdapter.setAll,
        upsertOnedependencies: dependenciesAdapter.upsertOne,
        upsertManydependencies: dependenciesAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return dependenciesAdapter.getInitialState();
        });
    },
});

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManydependencies,
    addOnedependencies,
    upsertOnedependencies,
    upsertManydependencies,
    setAlldependencies,
} = dependenciesSlice.actions;

/**
 * export reducer autegenerated by toolkit
 */
export default dependenciesSlice.reducer;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = dependenciesAdapter.getSelectors(
    (state: any) => state.entities.dependencies
);

/**
 * select for recover all dependencies  entities
 */
export const selectdependencies = (state: any) =>
    globalizedSelectors.selectEntities(state);

/**
 * select for recover all dependencies
 */
export const selectAlldependencies = (state: any) =>
    globalizedSelectors.selectAll(state);

/**
 * select for recover one dependencies by id
 */
export const selectdependenciesById =
    (catalog_dependency_id: any) => (state: any) =>
        globalizedSelectors.selectById(state, catalog_dependency_id);
