import { useEffect, useState } from "react";
import useFeedback from "../hooks/useFeedback";
import { IonAlert } from "@ionic/react";

/**
 * Componente encargado de mostrar informacion al usuario
 *
 * @param {*} open
 *
 * @returns
 */
const UserFeedback = () => {
    ///////////// SHARED STATE ///////////////////

    const feedback = useFeedback();
    const [showAlert, setShowAlert] = useState(false);

    //////////// LOCAL STATE /////////////////////

    const closeFeedback = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        feedback.closeFeedback();
    };

    useEffect(() => {
        setShowAlert(feedback.open);
    }, [feedback.open]);

    const handleAlertClose = () => {
        setShowAlert(false);
        closeFeedback();
    };
    /**
     *  <Snackbar
                open={feedback.open}
                autoHideDuration={6000}
                onClose={closeFeedback}
                message={feedback?.title}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5 }}
                            onClick={closeFeedback}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            />
     */
    return (
        <IonAlert
            isOpen={showAlert}
            onDidDismiss={handleAlertClose}
            header={"Información Importante"}
            message={feedback?.title}
            buttons={[
                {
                    text: "Aceptar",
                    handler: handleAlertClose,
                },
            ]}
        />
    );
};

export default UserFeedback;
