import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonSkeletonText,
    IonText,
    IonThumbnail,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ToolBar from "../../../../../../components/ToolBar";
import { useHistory, useParams } from "react-router";
import "./style.scss";
import {
    chevronDownOutline,
    chevronUpOutline,
    closeCircleOutline,
    trashBin,
} from "ionicons/icons";
import escudoWebAPI from "../../../../../../services/escudoWebAPI";
import { useFeedback } from "../../../../../../hooks";
import { useDispatch } from "react-redux";
import * as _ from "lodash-es";
import AdminIcon from "../../../../../../assets/icons/Admin-icon.svg";
import Another_dependenci from "../../../../../../assets/icons/Another_dependenci.svg";
import municipality_dependence from "../../../../../../assets/icons/municipality_dependence.svg";
import State_dependence from "../../../../../../assets/icons/State_dependence.svg";
import anime from "animejs";
import check from "../../../../../../assets/icons/check.svg";
import feedback from "../../../../../../services/feedback";
import { addOnegroup } from "../../../../../../redux/slices/entities/groups";
import { upsertManyUsers } from "../../../../../../redux/slices/entities/users";
import { upsertManyMembreships } from "../../../../../../redux/slices/entities/memberships";

const GrupUserExist: React.FC = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    const [present, dismiss] = useIonLoading();
    const [presetAlert] = useIonAlert();
    const feedbackApp = useFeedback();
    const dispatch = useDispatch();

    /**
     * local state
     */

    const [results, setResults] = useState<any>([]);
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [Dependencies, setAllDependencies] = useState([]);
    const [filteredDependencies, setFilteredDependencies] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [nameGroup, setNameGroup] = useState("");
    const [selectedTypeDependency, setSelectedTypeDependency] = useState(null);
    const [selectedDependency, setselectedDependency] = useState<number>();
    const [debounceLoading, setDebounceLoading] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [searchError, setSearchError] = useState<string | null>(null);
    const [showFirstSelect, setShowFirstSelect] = useState(false);

    /**
     * filtrado de los contactos
     */
    const handleSearchContacts = (ev: any) => {
        const query = (ev.detail.value as string).toLowerCase();
        if (query.length < 3) {
            setSearchError("Ingrese al menos 3 caracteres");
            return;
        }
        setSearchError(null);
        setDebounceLoading(true);
        setTimeout(() => {
            escudoWebAPI
                .SearchUsers(query)
                .then((dataFromApi) => setResults(dataFromApi))
                .catch(() => setResults([]))
                .finally(() => {
                    setDebounceLoading(false);
                });
        }, 1000);
    };
    /**
     * instrucciones del selec de los tipos de las dependencias
     */
    const customAlertOptions = {
        header: "Selecciona el tipo de dependencia",
        subHeader: "Elige un tipo de dependencia",
        translucent: true,
    };

    const customAlertOptions2 = {
        header: "Selecciona la dependencia a la que perteneces",
        subHeader: "Elige una dependencia",
        translucent: true,
    };

    /**
     * agregar una burbuja
     */
    const onShowAddMemberbubble = (user: any) => {
        toggleUserSelection(user);
    };

    /**
     * animacion de la burbuja
     */
    const animateCheckIcon = (user_id: string, fadeIn: boolean) => {
        anime({
            targets: `.check-icon-animation-${user_id}`,
            scale: fadeIn ? [0, 1] : [1, 0],
            opacity: fadeIn ? [0, 1] : [1, 0],
            duration: 500,
            easing: "easeInOutQuad",
        });
    };
    /**
     * animacion del select
     */
    useEffect(() => {
        if (showFirstSelect) {
            anime({
                targets: ".ion-select-animation",
                opacity: [0, 1],
                translateY: [20, 0],
                duration: 700,
                easing: "easeInOutExpo",
            });
        }
    }, [showFirstSelect]);
    /**
     * Select or deselect a user
     */
    const toggleUserSelection = (user: any) => {
        const isSelected = selectedUsers.some(
            (selectedUser) => selectedUser.user_id === user.user_id
        );

        if (isSelected) {
            animateCheckIcon(user.user_id, false);

            anime({
                targets: `.selected-user-bubble[data-userid="${user.user_id}"]`,
                opacity: [1, 0],
                translateY: [0, 20],
                duration: 500,
                easing: "easeInOutExpo",
                complete: () => {
                    const updatedUsers = selectedUsers.filter(
                        (selectedUser) => selectedUser.user_id !== user.user_id
                    );
                    setSelectedUsers(updatedUsers);
                },
            });
        } else {
            anime({
                targets: ".selected-user-bubble",
                opacity: [0, 1],
                translateY: [20, 0],
                duration: 500,
                easing: "easeInOutExpo",
                complete: () => {
                    setSelectedUsers([user]);
                    animateCheckIcon(user.user_id, true);
                },
            });
        }
    };
    /**
     * funcion para animar el icono de check
     */
    useEffect(() => {
        selectedUsers.forEach((user) => {
            animateCheckIcon(user.user_id, true);
        });
    }, [selectedUsers]);

    /**
     * funcion para crear un grupo
     */
    const onCreateGroup = async () => {
        present({
            message: "Por favor espere",
        });
        const userId = selectedUsers.length > 0 ? selectedUsers[0].user_id : "";

        storeGroup({
            name: nameGroup,
            group_type: 1,
            dependency: selectedDependency,
            userId,
        })
            .then((data: any) => {
                dispatch(addOnegroup(data.group));
                dispatch(upsertManyMembreships(data.memberships));
                dispatch(upsertManyUsers(data.members));

                presetAlert({
                    header: "Grupo creado",
                    message: "El grupo fue creado correctamente",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            role: "confirm",
                            handler: () => {
                                history.goBack();
                            },
                        },
                    ],
                });
            })
            .catch((err: any) => {
                let message = feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    const storeGroup = async (data: any) => {
        const groupCreated = await escudoWebAPI
            .createGroupWithUserExist(
                data.userId,
                data.name,
                data.group_type,
                data.dependency
            )
            .then((res: any) => res.data.data);

        const memberships = await escudoWebAPI
            .getMembeshipsByGroup(groupCreated.group_id)
            .then((res: any) => res.data.data);

        const members = await escudoWebAPI
            .getMembersByGroup(groupCreated.group_id)
            .then((res: any) => res.data.data);
        return {
            group: groupCreated,
            memberships,
            members,
        };
    };
    /**
     * Accion para recuperar todas las dependencias
     */
    useEffect(() => {
        const getDependencies = async () => {
            const Dependencies = await escudoWebAPI
                .getAllDependenci()
                .then((res: any) => res.data.data);

            Dependencies.sort((a: any, b: any) => a.name.localeCompare(b.name));

            setAllDependencies(Dependencies);
        };

        getDependencies();
    }, []);
    /**
     * Accion de la seleccion de la dependencia
     */
    const HandleSelectTypeDependenci = (event: CustomEvent) => {
        const selectedType = event.detail.value;

        if (selectedType === "3") {
            setShowFirstSelect(false);
            setselectedDependency(48);
        } else {
            setShowFirstSelect(true);
            if (Dependencies && Dependencies.length > 0) {
                const filteredData = Dependencies.filter(
                    (item: any) => item.type === parseInt(selectedType, 10)
                );
                setSelectedTypeDependency(
                    filteredData.length > 0 ? filteredData[0] : null
                );

                setFilteredDependencies(filteredData);
            }
        }
    };
    const handleSelectDependency = (event: CustomEvent) => {
        setselectedDependency(event.detail.value);
    };
    /**
     * Render selected users as bubbles
     */
    const renderSelectedUsers = () => {
        return (
            <div style={{ minHeight: "80px" }}>
                {selectedUsers.map((user) => (
                    <IonList key={user.user_id}>
                        <IonListHeader className="contacts-header">
                            Administrador seleccionado
                        </IonListHeader>
                        <IonChip
                            mode="ios"
                            data-userid={user.user_id}
                            className="selected-user-bubble"
                            onClick={() => toggleUserSelection(user)}>
                            <IonLabel>
                                {user.name} {user.last_name}
                            </IonLabel>
                            <IonIcon
                                icon={closeCircleOutline}
                                onClick={() => toggleUserSelection(user)}
                            />
                        </IonChip>
                    </IonList>
                ))}
            </div>
        );
    };
    return (
        <IonPage className="Create-group-userexist-page">
            <ToolBar title="Nuevo Grupo"></ToolBar>
            <IonContent className="ion-padding">
                <IonCard className="contacts ion-margin-top">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    <IonInput
                                        name="name"
                                        maxlength={50}
                                        className="inputs-create-group"
                                        labelPlacement="stacked"
                                        placeholder="Nombre del grupo"
                                        value={nameGroup}
                                        required
                                        onIonInput={(e: any) =>
                                            setNameGroup(e.detail.value)
                                        }
                                        counter={true}>
                                        <div
                                            className="inputs-create-group-text"
                                            slot="label">
                                            Nombre del grupo
                                            <IonText color="danger">*</IonText>
                                        </div>
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="text-label-dif">
                                Tipo de dependencia a la que pertenece{" "}
                                <IonText color="danger">*</IonText>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem
                                    lines="none"
                                    className="icon-dependenci">
                                    <IonIcon
                                        src={
                                            selectedOption === "1"
                                                ? State_dependence
                                                : selectedOption === "2"
                                                ? municipality_dependence
                                                : Another_dependenci
                                        }
                                        slot="start"
                                    />
                                    <IonSelect
                                        className="select-dependencia"
                                        interfaceOptions={customAlertOptions}
                                        interface="alert"
                                        mode="md"
                                        placeholder="Selecciona el tipo de dependencia"
                                        toggleIcon={chevronDownOutline}
                                        expandedIcon={chevronUpOutline}
                                        onIonChange={
                                            HandleSelectTypeDependenci
                                        }>
                                        <IonSelectOption value="1">
                                            Estatal
                                        </IonSelectOption>
                                        <IonSelectOption value="2">
                                            Municipal
                                        </IonSelectOption>
                                        <IonSelectOption value="3">
                                            Independiente
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        {showFirstSelect === true && (
                            <IonRow className="ion-select-animation">
                                <>
                                    <IonCol
                                        size="12"
                                        className="text-label-dif">
                                        Nombre de su dependencia
                                        <IonText color="danger">*</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonSelect
                                            className="Select-dependency"
                                            interfaceOptions={
                                                customAlertOptions2
                                            }
                                            interface="alert"
                                            mode="md"
                                            placeholder="Selecciona una dependencia"
                                            toggleIcon={chevronDownOutline}
                                            expandedIcon={chevronUpOutline}
                                            disabled={!selectedTypeDependency}
                                            onIonChange={
                                                handleSelectDependency
                                            }>
                                            {filteredDependencies.map(
                                                (item: any) => (
                                                    <IonSelectOption
                                                        key={
                                                            item.catalog_dependency_id
                                                        }
                                                        value={
                                                            item.catalog_dependency_id
                                                        }>
                                                        {item.name}
                                                    </IonSelectOption>
                                                )
                                            )}
                                        </IonSelect>
                                    </IonCol>
                                </>
                            </IonRow>
                        )}
                    </IonGrid>
                </IonCard>
                <IonCard className="contacts ion-margin-top">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonCardHeader className="contacts-header">
                                    <IonCardTitle className="ion-text-center">
                                        {searchError && (
                                            <div className="search-error-message ion-padding-top">
                                                <IonText className="search-error-text">
                                                    {searchError}
                                                </IonText>
                                            </div>
                                        )}
                                        <IonText className="Header-title">
                                            Agregar Administrador
                                        </IonText>
                                    </IonCardTitle>
                                    <IonSearchbar
                                        placeholder="Busca un miembro"
                                        debounce={2000}
                                        animated={true}
                                        mode="ios"
                                        clearIcon={trashBin}
                                        onIonInput={(ev) => {
                                            const searchValue =
                                                ev.target?.value?.trim() ?? "";
                                            setSearchInputValue(searchValue);
                                            if (searchValue !== "") {
                                                handleSearchContacts(ev);
                                            } else {
                                                setResults([]);
                                            }
                                        }}
                                        className="search-custome"></IonSearchbar>
                                </IonCardHeader>
                            </IonCol>
                            <IonCol size="12">
                                <IonCardContent>
                                    <IonList>{renderSelectedUsers()}</IonList>
                                    <IonList>
                                        <IonListHeader className="contacts-header">
                                            Lista de usuarios
                                        </IonListHeader>
                                        {debounceLoading ? (
                                            <IonList>
                                                <IonItem>
                                                    <IonThumbnail slot="start">
                                                        <IonSkeletonText
                                                            animated={
                                                                true
                                                            }></IonSkeletonText>
                                                    </IonThumbnail>
                                                    <IonLabel>
                                                        <h3>
                                                            <IonSkeletonText
                                                                animated={true}
                                                                style={{
                                                                    width: "80%",
                                                                }}></IonSkeletonText>
                                                        </h3>
                                                        <p>
                                                            <IonSkeletonText
                                                                animated={true}
                                                                style={{
                                                                    width: "60%",
                                                                }}></IonSkeletonText>
                                                        </p>
                                                        <p>
                                                            <IonSkeletonText
                                                                animated={true}
                                                                style={{
                                                                    width: "30%",
                                                                }}></IonSkeletonText>
                                                        </p>
                                                    </IonLabel>
                                                </IonItem>
                                            </IonList>
                                        ) : !debounceLoading ? (
                                            results.data &&
                                            results.data.data.length > 0 ? (
                                                results.data.data.map(
                                                    (user: any) => (
                                                        <div key={user.user_id}>
                                                            <IonItem
                                                                onClick={() =>
                                                                    onShowAddMemberbubble(
                                                                        user
                                                                    )
                                                                }>
                                                                <IonThumbnail slot="start">
                                                                    <IonIcon
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                        }}
                                                                        icon={
                                                                            AdminIcon
                                                                        }></IonIcon>
                                                                </IonThumbnail>
                                                                <IonLabel>
                                                                    <h3>
                                                                        {
                                                                            user.name
                                                                        }{" "}
                                                                        {
                                                                            user.last_name
                                                                        }{" "}
                                                                        {
                                                                            user.seccond_last_name
                                                                        }
                                                                    </h3>
                                                                    <p>
                                                                        {
                                                                            user.cellphone
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {
                                                                            user.email
                                                                        }
                                                                    </p>
                                                                </IonLabel>
                                                                {selectedUsers.some(
                                                                    (
                                                                        selectedUser
                                                                    ) =>
                                                                        selectedUser.user_id ===
                                                                        user.user_id
                                                                ) && (
                                                                    <IonIcon
                                                                        src={
                                                                            check
                                                                        }
                                                                        color="success"
                                                                        slot="end"
                                                                        className={`check-icon-animation-${user.user_id}`}
                                                                    />
                                                                )}
                                                            </IonItem>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <div className="show-mesasage ion-padding-top">
                                                    <IonText>
                                                        {searchInputValue.trim() ===
                                                        ""
                                                            ? "Comienza a buscar tu administrador"
                                                            : "No se encontraron resultados"}
                                                    </IonText>
                                                </div>
                                            )
                                        ) : null}
                                    </IonList>
                                </IonCardContent>
                                <IonRow className="ion-margin-top">
                                    <IonCol
                                        size="12"
                                        className="ion-text-center">
                                        <IonButton
                                            onClick={onCreateGroup}
                                            className="create-group-button"
                                            disabled={
                                                !(
                                                    selectedUsers.length > 0 &&
                                                    selectedDependency &&
                                                    nameGroup
                                                )
                                            }
                                            mode="ios">
                                            Guardar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>
                <div className="footer-pattern"></div>
            </IonContent>
        </IonPage>
    );
};

export default GrupUserExist;
