import { IonSpinner, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import "./styleGate.scss";
import { useAppInfo } from "../../hooks";

/**
 * Componente encargado de mostra una animacion
 * en caso se que aun no se hayan cargado todo los
 * datos en redux
 *
 * @param {*} param0
 *
 * @returns
 */
const AnimatedGate = ({ children, bootstrapped }) => {
    const [loaded, setLoaded] = useState(false);

    const { mode, version } = useAppInfo();

    useEffect(() => {
        if (bootstrapped) {
            setTimeout(() => {
                setLoaded(true);
            }, 1000);
        }
    }, [bootstrapped]);

    return (
        <>
            {!loaded ? (
                <div className="reload-container centered-content">
                    <div className="spinner-reload">
                        <IonSpinner
                            className="spinner=reload"
                            name="circular"></IonSpinner>
                    </div>
                    <div className="version-reload ">
                        <IonText>
                            {mode} v {version}
                        </IonText>
                        <br></br>
                        <IonText className="terms-reload">
                            Términos de uso y Acuerdo de confidencialidad
                        </IonText>
                    </div>
                </div>
            ) : (
                children
            )}
        </>
    );
};

export default AnimatedGate;
