import { createSelector } from "@reduxjs/toolkit";
import { selectAllCatalogIncidents } from "../../../../redux/slices/entities/catalogincidents";
import { selectIncidentById } from "../../../../redux/slices/entities/incidents";

/**
 * selector para recuperar el modelo de vista
 */
export const selectViewModel = (incidentId: number) => createSelector(
    selectIncidentWithCatalog(incidentId), 
    (incidentWithCatalog) => {

        return {
            ...incidentWithCatalog
        }
    }
)

/**
 * Selector para recuperar un incidente con su catalogo
 */
export const selectIncidentWithCatalog = (incidentId: number) => createSelector(
    selectIncidentById(incidentId),
    selectAllCatalogIncidents,
    (incident, allCatalogInidents) => {
        const catalogIncident = allCatalogInidents.find((catalog: any) => catalog.catalog_incident_id == incident.catalog_incident_id)

        return {
            incident,
            catalogIncident
        }
    }
)