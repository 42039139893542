import {
    IonButton,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonCol,
    IonRow,
    IonPage,
    useIonLoading,
    IonText,
    IonRadioGroup,
    IonRadio,
    IonGrid,
    IonSelect,
    IonSelectOption,
    IonPopover,
    IonDatetime,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import {
    calendarOutline,
    mailOutline,
    chevronDownOutline,
    chevronUpOutline,
} from "ionicons/icons";
import { useDispatch } from "react-redux";
import {
    selectAuthenticatedUserId,
    updateUser,
} from "../../../../redux/slices/auth";
import { useFeedback } from "../../../../hooks";
import { DateTime } from "luxon";
import "./Edit.scss";
import MapComponent from "../../../register/pages/MapComponent";
import { useHistory } from "react-router";
import escudoWebAPI from "../../../../services/escudoWebAPI";
import marketInput from "../../../../assets/icons/market-input.svg";
import { State } from "../../../../models/state";
import { Municipality } from "../../../../models/municipality";
import { City } from "../../../../models/city";
import { selectUsersById } from "../../../../redux/slices/entities/users";
import { useSelector } from "react-redux";
import LocationPermissionModal from "../../../register/pages/Modal";
import UpdateModal from "./pages/ModalUpdate";
import ToolBar from "../../../../components/ToolBar";

const EditAccount: React.FC = () => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    const [present, disminss] = useIonLoading(); // Loading state management
    const feedbackApp = useFeedback(); // Feedback component
    const history = useHistory(); // Navigation history

    /**
     * Local state
     */

    const userId = useSelector(selectAuthenticatedUserId);
    const userInfo = useSelector(selectUsersById(userId));

    let initialDate = DateTime.fromSQL(userInfo?.date_birth).toFormat(
        "yyyy-MM-dd"
    );

    /**
     *
     * User variabilities
     *
     */
    let [user, setUser] = useState({
        name: userInfo.name,
        last_name: userInfo.last_name,
        second_last_name: userInfo.second_last_name ?? "",
        email: userInfo.email ?? "",
        inside_number: userInfo.inside_number ?? "",
        outside_number: userInfo.outside_number,
        street: userInfo.street,
        zipcode: userInfo.zipcode,
        date_birth: initialDate,
        gender: userInfo.gender ?? "",
        latitude: userInfo.latitude,
        longitude: userInfo.longitude,
        colony: userInfo.colony,
        address_city_id: userInfo.address_city_id,
    });

    /**
     * State variables
     */
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedGender, setSelectedGender] = useState<string>("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showUpdateModal, setUpdateModal] = useState(false);

    /**
     * Estado para los listados de ciudades
     */
    const [states, setAllStates] = useState<any>([]);
    const [allMunicipalities, setAllMunicipalities] = useState<any>([]);
    const [allCities, setAllCities] = useState<any>([]);

    /**
     * Estado para las localidades seleccionadas
     */

    const [selectedState, setSelectedState] = useState<State | null>(null);
    const [selectedMunicipality, setSelectedMunicipality] =
        useState<Municipality | null>(null);
    const [selectedCities, setSelectedCities] = useState<City | null>(null);

    /**
     * Fetchs and set states, municipalities, and cities
     * These `useEffect` blocks are responsible for fetching and updating the lists of states, municipalities,
     * and cities from the API. Each block serves a specific purpose:
     * - The first `useEffect` fetches all states and sets them in the `states` state variable.
     * - The second `useEffect` watches for changes in the `selectedState` state variable. When a state is selected,
     *   it fetches the associated municipalities and updates the `allMunicipalities` state.
     * - The third `useEffect` is similar to the second but focuses on cities within a selected municipality,
     *   updating the `allCities` state.
     * These blocks enable dynamic location selection and updates based on user choices.
     */

    /**
     * Recuperacion de los estados
     */

    useEffect(() => {
        if (selectedState !== null) {
            const getMunicipalities = async () => {
                const municipalities = await escudoWebAPI
                    .getMunicipalitiesByState(selectedState.state_id)
                    .then((res: any) => res.data.data);
                setAllMunicipalities(municipalities);
            };

            getMunicipalities();
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedMunicipality !== null) {
            const getCitys = async () => {
                const cities = await escudoWebAPI
                    .getCityByMunicipalite(selectedMunicipality.municipality_id)
                    .then((res: any) => res.data.data);
                setAllCities(cities);
            };

            getCitys();
        }
    }, [selectedMunicipality]);

    useEffect(() => {
        const GetInfoLocationUser = async () => {
            const currentCity = await escudoWebAPI
                .getCitysById(userInfo.address_city_id)
                .then((res: any) => res.data.data);

            const currentMunicypality = await escudoWebAPI
                .getMunicipalityByCityId(userInfo.address_city_id)
                .then((res: any) => res.data.data);

            const currentState = await escudoWebAPI
                .getstatesByMunicipalite(currentMunicypality.municipality_id)
                .then((res: any) => res.data.data);

            const allState = await escudoWebAPI
                .getAllStates()
                .then((res: any) => res.data.data);

            /**
             * Primero agregamos los recursos del usuario
             * al estado para estar seguros de que le aparescan mientras
             * se ejecutan los otros dos efectos de municipios y ciudades
             */
            //setAllMunicipalities([currentMunicypality]);
            //setAllCities([currentCity]);
            setAllStates(allState);

            setSelectedState(currentState);
            setSelectedMunicipality(currentMunicypality);
            setSelectedCities(currentCity);
        };

        GetInfoLocationUser();
    }, []);

    /**
     * Input change handlers
     * These functions handle various aspects of user input and selection during the registration process:
     * - 'onHandlerInputChange' updates the user data when form inputs change.
     * - 'handleRadioChange' manages the selected gender and updates the user data accordingly.
     * - 'handleRadioTerms' updates the selected terms and conditions.
     * - 'handleDateChange' formats and updates the user's date of birth.
     * - 'handleStateChange' handles the change of the selected state, affecting city and municipality selections.
     * - 'passwordsMatch' is a state variable that tracks whether the entered passwords match.
     * These functions play a crucial role in maintaining and updating user data and registration choices.
     */
    const onHandlerInputChange = (customeEvent: CustomEvent) => {
        let { event, value } = customeEvent.detail;

        let name = event.target.name;

        if (name == "zipcode" && name.length > 5) {
            value = value.slice(0, 5);
        }

        setUser({ ...user, [name]: value });
    };

    const handleRadioChange = (customeEvent: CustomEvent) => {
        const selectedValue = customeEvent.detail.value;
        setSelectedGender(selectedValue);

        setUser({ ...user, gender: selectedValue });
    };

    /**
     * maneja el cambio de la fecha de nacimiento
     */
    const handleDateChange = (customEvent: CustomEvent) => {
        const selectedDate = customEvent.detail.value;

        if (DateTime.fromISO(selectedDate).isValid) {
            const formattedDate =
                DateTime.fromISO(selectedDate).toFormat("yyyy-MM-dd");

            setUser({ ...user, date_birth: formattedDate });
        }
    };

    /**
     * Maneja la seleccion de un estado
     */
    const handleStateChange = (CustomeEvent: CustomEvent) => {
        const newState = CustomeEvent.detail.value;

        setSelectedState(newState);
        setSelectedMunicipality(null);
    };

    /**
     * Manejjo de la selecion del municipo
     */
    const onHandlerMunicipalityChange = (e: CustomEvent) => {
        setSelectedMunicipality(e.detail.value);
        setSelectedCities(null);
    };

    /**
     * Manejo de la selecion de una ciudad
     */
    const onHandlerCityChange = (e: CustomEvent) => {
        const selectedCity = e.detail.value;

        setSelectedCities(selectedCity);

        setUser({ ...user, address_city_id: selectedCity.city_id });
    };

    /**
     * Input validation functions
     * These functions are responsible for validating various user input fields during the registration process.
     * They check for the validity of user data, and when necessary, provide error messages:
     * - 'isNameValid' checks if the name field is not empty.
     * - 'isLastNameValid' checks if the last name field is not empty.
     * - 'isCellphoneValid' checks if the cellphone field is not empty.
     * - 'isDateBirthValid' checks if the date of birth field is not empty.
     * - 'isGenderValid' checks if the gender field is not empty.
     * - 'isPasswordValid' checks password validity and matches if entered passwords are identical.
     * - 'validatePasswords' updates the 'passwordsMatch' state variable based on password matching.
     * These functions ensure that user-provided data meets necessary requirements.
     */

    const isNameValid = () => ({
        isValid: user.name !== "",
        message: "El nombre es requerido",
    });

    const isLastNameValid = () => ({
        isValid: user.last_name !== "",
        message: "El apellido paterno es requerido",
    });

    const isDateBirthValid = () => ({
        isValid: user.date_birth !== "",
        message: "El año de nacimiento es requerido",
    });

    /**
     * validacion para el correo
     */
    const isEmailValid = () => {
        var mailformat =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const isEmail = mailformat.test(user.email);

        return {
            isValid: (user.email !== "" && isEmail) || user.email === "",
            message: "Campo requerido o incorrecto",
        };
    };

    const isStreetValid = () => ({
        isValid: user.street !== "",
        message: "La calle del usuario es requerida"
    })

    const isExteriorNumberValid = () => ({
        isValid: user.outside_number !== "",
        message: "El número exterior es requerido"
    })

    const isColonyValid = () => ({
        isValid: user.colony !== "",
        message: "La colonia del usuario es requerida"
    })

    const isZipCode = () => ({
        isValid: user.zipcode !== "",
        message: "El código postal es obligatorio"
    })

    const isCityValid = () => ({
       isValid: user.address_city_id !== null,
       message: "La ciudad del usuario es requerida"
    })


    /**
     * Step and overall registration validation
     * These functions evaluate the validity of user data at different stages of the registration process:
     * - 'isStepOneValid' checks if the data for the first step of registration is valid. It validates name,
     *   last name, cellphone, date of birth, and gender.
     * - 'isRegisterValid' checks if all data for completing the registration is valid. It includes validations for
     *   name, last name, cellphone, date of birth, gender, password, and terms acceptance.
     * These functions ensure that user data meets the necessary criteria for a successful registration.
     */

    const isStepOneValid = () => {
        return (
            isNameValid().isValid &&
            isLastNameValid().isValid &&
            isDateBirthValid().isValid &&
            isEmailValid().isValid
        );
    };
    const isRegisterValid = () => {
        return (
            isNameValid().isValid &&
            isLastNameValid().isValid &&
            isDateBirthValid().isValid  &&
            isStreetValid().isValid &&
            isExteriorNumberValid().isValid &&
            isColonyValid().isValid &&
            isZipCode().isValid &&
            isCityValid().isValid
        );
    };

    /**
     * Handler for the "Next" and "Back" button
     * These functions manage the navigation between registration steps. 'onBackButton' checks if the current step is 1,
     * and if so, it navigates back to the previous page using the history. 'handleNext' advances the registration process
     * to the next step by updating the 'currentStep' state.
     */

    const onBackButton = () => {
        if (currentStep === 1) {
            history.goBack();
        } else {
            setCurrentStep(1);
        }
    };

    const handleNext = () => {
        setCurrentStep(2);
    };

    /**
     * User registration
     * This function handles the user registration process. It logs the user data, displays a loading message,
     * dispatches the registration action, and shows feedback or errors as needed. Finally, it dismisses the loading
     * message after a short delay.
     */

    const onUpdaterUser = () => {
        present({
            message: "Por favor espere",
        });

        dispatch<any>(updateUser({ userId: userId, userData: user }))
            .unwrap()
            .then((response: any) => {
                setUpdateModal(true);
            })
            .catch(({ feedback }: any) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            })
            .then(() => {
                setTimeout(() => {
                    disminss();
                }, 50);
            });
    };

    /**
     * Cambia la ubicacion del mapa
     */
    const onChangeLocation = ({ latitude, longitude }: any) => {
        setUser((user: any) => {
            return {
                ...user,
                latitude,
                longitude,
            };
        });
    };

    return (
        <IonPage className="edit-page">
            <LocationPermissionModal
                isOpen={isModalOpen}
                closeModal={() => history.goBack()}
            />

            <ToolBar title="Editar" />

            <IonContent className="edit-page-content ">
                <div className="form-inner-container  ion-padding-horizontal">
                    <div className="titleForm">
                        {currentStep === 1 ? "Datos Personales" : "Ubicación"}
                    </div>

                    {currentStep === 1 && (
                        <div className="seccion1">
                            <IonGrid>
                                {/*names seccion*/}
                                <IonRow>
                                    <IonCol size="12">
                                        <IonInput
                                            name="name"
                                            maxlength={50}
                                            className="names"
                                            labelPlacement="stacked"
                                            placeholder="Nombre(s)"
                                            value={user.name}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Nombre
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*surnames seccion*/}
                                <IonRow>
                                    <IonCol size="6">
                                        <IonInput
                                            name="last_name"
                                            className="surnames"
                                            labelPlacement="stacked"
                                            placeholder="Tu apellido"
                                            maxlength={50}
                                            value={user.last_name}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Apellido Paterno{" "}
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonInput
                                            maxlength={50}
                                            className="surnames"
                                            name="second_last_name"
                                            labelPlacement="stacked"
                                            placeholder="Tu apellido"
                                            value={user.second_last_name}
                                            onIonInput={onHandlerInputChange}
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Apellido Materno
                                                <IonText color="medium">
                                                    (Opcional)
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*gender seccion*/}
                                <IonRow className="gender-seccion">
                                    <IonText className="genderTitle">
                                        Género{" "}
                                        <IonText color="medium">
                                          (Opcional)
                                        </IonText>                                    </IonText>
                                    <IonCol size="12">
                                        <IonRadioGroup
                                            value={
                                                selectedGender || user.gender
                                            }
                                            onIonChange={handleRadioChange}
                                            className="gender-Buttons flex-row ion-justify-content-between ion-align-items.center"
                                        >
                                            <IonRadio
                                                mode="md"
                                                value="1"
                                                className="circle-radio ion-padding-top"
                                            >
                                                <p className="gender">
                                                    Masculino
                                                </p>
                                            </IonRadio>
                                            <IonRadio
                                                mode="md"
                                                value="2"
                                                className="circle-radio ion-padding-top"
                                            >
                                                <p className="gender">
                                                    Femenino
                                                </p>
                                            </IonRadio>
                                            <IonRadio
                                                mode="md"
                                                value="3"
                                                className="circle-radio ion-padding-top"
                                            >
                                                <p className="gender">Otro</p>
                                            </IonRadio>
                                        </IonRadioGroup>
                                    </IonCol>
                                </IonRow>
                                {/*born day seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonItem
                                            className="bornDay"
                                            id="auto-trigger"
                                        >
                                            <IonInput
                                                name="date_birth"
                                                labelPlacement="stacked"
                                                placeholder="DD/MM/YYYY"
                                                onIonInput={
                                                    onHandlerInputChange
                                                }
                                                value={user.date_birth}
                                                required
                                            >
                                                <div
                                                    className="text-label"
                                                    slot="label"
                                                >
                                                    Fecha de Nacimiento{" "}
                                                    <IonText color="danger">
                                                        *
                                                    </IonText>
                                                </div>
                                                <IonPopover
                                                    trigger="auto-trigger"
                                                    side="top"
                                                    alignment="center"
                                                    keepContentsMounted={true}
                                                >
                                                    <IonDatetime
                                                        size="cover"
                                                        presentation="date"
                                                        preferWheel={true}
                                                        showDefaultButtons={
                                                            true
                                                        }
                                                        doneText="Aceptar"
                                                        cancelText="Cancelar"
                                                        onIonChange={
                                                            handleDateChange
                                                        }
                                                        value={user.date_birth}
                                                    ></IonDatetime>
                                                </IonPopover>
                                            </IonInput>

                                            <IonIcon
                                                className="icon-state"
                                                slot="end"
                                                icon={calendarOutline}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/* email seccion  */}
                                <IonRow>
                                    <IonCol>
                                        <IonItem className="email">
                                            <IonInput
                                                name="email"
                                                labelPlacement="stacked"
                                                placeholder="tumail@gmail.com"
                                                type="email"
                                                value={user.email}
                                                maxlength={50}
                                                onIonInput={
                                                    onHandlerInputChange
                                                }
                                            >
                                                <div
                                                    className="text-label"
                                                    slot="label"
                                                >
                                                    E-mail{" "}
                                                    <IonText color="medium">
                                                        (opcional)
                                                    </IonText>
                                                </div>
                                            </IonInput>
                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={mailOutline}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/*Button Next seccion*/}
                                <div className="button-edit ion-justify-content-center">
                                    <IonButton
                                        className="nextButton"
                                        onClick={handleNext}
                                        disabled={!isStepOneValid()}
                                    >
                                        Siguiente
                                    </IonButton>
                                </div>
                            </IonGrid>
                        </div>
                    )}

                    {/*seccond seccion*/}
                    {currentStep === 2 && (
                        <div className="seccion2">
                            <IonGrid>
                                {/*street seccion*/}
                                <IonRow>
                                    <IonCol className="street">
                                        <IonInput
                                            name="street"
                                            labelPlacement="stacked"
                                            placeholder="Nombre de la calle"
                                            value={user.street}
                                            type="text"
                                            maxlength={50}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Calle
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*numbers seccion*/}
                                <IonRow>
                                    <IonCol size="6">
                                        <IonInput
                                            maxlength={10}
                                            type="text"
                                            className="numbers"
                                            name="outside_number"
                                            label-placement="stacked"
                                            placeholder="Número"
                                            value={user.outside_number}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Núm. Exterior
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonInput
                                            className="numbers"
                                            name="inside_number"
                                            labelPlacement="stacked"
                                            placeholder="Número"
                                            maxlength={10}
                                            type="text"
                                            value={user.inside_number}
                                            onIonInput={onHandlerInputChange}
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Núm. Interior{" "}
                                                <IonText color="medium">
                                                    (opcional)
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*colony seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonInput
                                            type="text"
                                            className="numbers"
                                            name="colony"
                                            maxlength={50}
                                            labelPlacement="stacked"
                                            placeholder="Colonia o Fraccionamiento"
                                            value={user.colony}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Colonia
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*CP seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonInput
                                            type="number"
                                            className="numbers"
                                            name="zipcode"
                                            maxlength={5}
                                            labelPlacement="stacked"
                                            placeholder="C.P"
                                            value={user.zipcode}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Código Postal{" "}
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>

                                {/*state seccion*/}
                                <IonRow className="states-seccion">
                                    <IonCol
                                        size="12"
                                        className="text-label-dif"
                                    >
                                        Estado{" "}
                                        <IonText color="danger">*</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonItem className="states">
                                            <IonIcon
                                                slot="start"
                                                src={marketInput}
                                            />

                                            <IonSelect
                                                className="states-select"
                                                name="address_city_id"
                                                toggleIcon={chevronDownOutline}
                                                expandedIcon={chevronUpOutline}
                                                interface="action-sheet"
                                                placeholder={
                                                    "selecciona un Estado"
                                                }
                                                onIonChange={handleStateChange}
                                                compareWith={(
                                                    o1: State,
                                                    o2: State
                                                ) => {
                                                    return o1 && o2
                                                        ? o1.state_id ===
                                                              o2.state_id
                                                        : o1 === o2;
                                                }}
                                                mode="ios"
                                                value={selectedState}
                                            >
                                                {states
                                                    .sort(
                                                        (a: State, b: State) =>
                                                            a.title.localeCompare(
                                                                b.title
                                                            )
                                                    )
                                                    .map((state: State) => (
                                                        <IonSelectOption
                                                            className="seccions"
                                                            key={state.state_id}
                                                            value={state}
                                                        >
                                                            {state.title}
                                                        </IonSelectOption>
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/*municipalite and city seccion*/}
                                <IonRow>
                                    <IonCol
                                        size="6"
                                        className="ion-padding-top"
                                    >
                                        <div className="text-label-dif">
                                            Municipio
                                            <IonText color="danger"> *</IonText>
                                        </div>
                                        <IonSelect
                                            className="select"
                                            interface="action-sheet"
                                            toggleIcon={chevronDownOutline}
                                            expandedIcon={chevronUpOutline}
                                            placeholder={
                                                "selecciona un municipio"
                                            }
                                            onIonChange={
                                                onHandlerMunicipalityChange
                                            }
                                            mode="ios"
                                            compareWith={(
                                                o1: Municipality,
                                                o2: Municipality
                                            ) => {
                                                return o1 && o2
                                                    ? o1.municipality_id ===
                                                          o2.municipality_id
                                                    : o1 === o2;
                                            }}
                                            value={selectedMunicipality}
                                        >
                                            {allMunicipalities
                                                .sort(
                                                    (
                                                        a: Municipality,
                                                        b: Municipality
                                                    ) =>
                                                        a.title.localeCompare(
                                                            b.title
                                                        )
                                                )
                                                .map(
                                                    (
                                                        municipality: Municipality
                                                    ) => (
                                                        <IonSelectOption
                                                            key={
                                                                municipality.municipality_id
                                                            }
                                                            value={municipality}
                                                        >
                                                            {municipality.title}
                                                        </IonSelectOption>
                                                    )
                                                )}
                                        </IonSelect>
                                    </IonCol>

                                    {/*city seccion*/}
                                    <IonCol
                                        size="6"
                                        className="ion-padding-top"
                                    >
                                        <div className="text-label-dif">
                                            Ciudad
                                            <IonText color="danger"> *</IonText>
                                        </div>
                                        <IonSelect
                                            className="select"
                                            interface="action-sheet"
                                            toggleIcon={chevronDownOutline}
                                            expandedIcon={chevronUpOutline}
                                            placeholder={
                                                "selecciona una ciudad"
                                            }
                                            compareWith={(
                                                o1: City,
                                                o2: City
                                            ) => {
                                                return o1 && o2
                                                    ? o1.city_id === o2.city_id
                                                    : o1 === o2;
                                            }}
                                            value={selectedCities}
                                            onIonChange={onHandlerCityChange}
                                            mode="ios"
                                        >
                                            {allCities
                                                .sort((a: City, b: City) =>
                                                    a.title.localeCompare(
                                                        b.title
                                                    )
                                                )
                                                .map((city: City) => (
                                                    <IonSelectOption
                                                        key={city.city_id}
                                                        value={city}
                                                    >
                                                        {city.title}
                                                    </IonSelectOption>
                                                ))}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                {/*map seccion*/}
                                <IonRow>
                                    <IonCol
                                        size="12"
                                        className="mapText ion-padding-top"
                                    >
                                        <IonText className="mapText ion-padding-top">
                                            Ubicación Geográficamente de tu
                                            domicilio
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <MapComponent
                                            latitude={user.latitude}
                                            longitude={user.longitude}
                                            onChangeLocation={onChangeLocation}
                                        />
                                    </IonCol>
                                </IonRow>
                                {/*confirm button icon */}
                                <IonRow>
                                    <div className="button-register ion-justify-content-center">
                                        <IonButton
                                            className="nextButton"
                                            onClick={() => setCurrentStep(1)}
                                        >
                                            Atrás
                                        </IonButton>
                                        <IonButton
                                            className="finishButton"
                                            onClick={onUpdaterUser}
                                            disabled={!isRegisterValid()}
                                        >
                                            Actualizar
                                        </IonButton>
                                    </div>
                                </IonRow>
                            </IonGrid>
                        </div>
                    )}
                </div>
                <div className="footerEdit"></div>
                <UpdateModal
                    isOpen={showUpdateModal}
                    closeModal={() => history.replace("/app/profile")}
                />
            </IonContent>
        </IonPage>
    );
};
export default EditAccount;
