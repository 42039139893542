import {
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    RefresherEventDetail,
    useIonLoading,
    IonImg,
    IonLabel,
    IonButton,
} from "@ionic/react";
import React, { useEffect, useRef } from "react";
import AlertButton from "../../../../components/alerts/AlertButton";
import "./styles.scss";
import { useDispatch } from "react-redux";
import {
    selectAuthenticatedUserId,
    selectDependencyUser,
    selectGobUser,
} from "../../../../redux/slices/auth";
import { useSelector } from "react-redux";
import { selectAllCatalogIncidents } from "../../../../redux/slices/entities/catalogincidents";
import {
    Createincident,
    appSync,
    invalidate,
    selectAppStatusServer,
} from "../../../../redux/slices/app";
import { selectMeGroupsForIncidents } from "../../../../redux/slices/entities/groups";
import { Geolocation } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core";
import HomeToolBar from "../../../../components/HomeToolBar";
import { useHistory } from "react-router";
import Feedback from "../../../../services/feedback";
import { useAlertIncident, useFeedback } from "../../../../hooks";
import Shield from "./../../../../assets/icons/icon.svg";
import { DateTime } from "luxon";
import usePermission from "../../../../hooks/usePermission";
import PanicButton from "../../../../components/PanicButton/PanicButton";
import { selectMeActivedMemberships } from "../../../../redux/slices/entities/memberships";
import { MEMBERSHIPS } from "../../../../constants";
import { PushNotifications } from "@capacitor/push-notifications";

const Alerts: React.FC = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const history = useHistory();
    const feedbackApp = useFeedback();
    const [present, dismiss] = useIonLoading();
    const { setShowPermissionModal, LocationColor, NotificationColor } =
        usePermission();

    const { isAlertActive, complete, isAlertCurrentByStore, userAlertCurrent } =
        useAlertIncident();
    const audioRef = useRef<HTMLAudioElement | null>(null);
    /**
     * global state
     */
    const categories = useSelector(selectAllCatalogIncidents);
    const userId = useSelector(selectAuthenticatedUserId);
    const groups: any = useSelector(selectMeGroupsForIncidents);
    const syncStatus = useSelector(selectAppStatusServer);
    const isGovernor = useSelector(selectGobUser);
    const isDependency = useSelector(selectDependencyUser);
    const myMemberships = useSelector(selectMeActivedMemberships);

    const groupId = groups?.[0]?.group_id ?? null;

    /**
     * create new incident
     */
    const onHalderIncidents = async (eventData: any) => {
        const { id } = eventData;

        const locationPermissions = await Geolocation.checkPermissions();
        if (Capacitor.getPlatform() === "web") {
            if (locationPermissions.location == "denied") {
                setShowPermissionModal(true);

                return;
            }
        } else {
            const notificationResult =
                await PushNotifications.requestPermissions();

            if (notificationResult.receive !== "granted") {
                if (notificationResult.receive === "denied") {
                    setShowPermissionModal(true);

                    return;
                }
            }
            if (locationPermissions.location !== "granted") {
                let permisionRequest = await Geolocation.requestPermissions();

                let { location } = permisionRequest;

                if (location === "denied") {
                    setShowPermissionModal(true);

                    return;
                }
            }
        }
        present({
            message: "Creando incidente por favor espere",
        });

        const position = await Geolocation.getCurrentPosition();
        let { latitude, longitude } = position.coords;

        dispatch<any>(
            Createincident({
                incidentId: id,
                userId: userId,
                group_id: groupId,
                latitude: latitude,
                longitude: longitude,
            })
        )
            .then((action: any) => {
                const incident = action.payload.incident;
                history.push(`/app/alerts/${incident.incident_id}/complete`);
            })
            .catch((err: any) => {
                let message = Feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };

    /**
     * regresa los datos de la aplicación
     */
    function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        setTimeout(() => {
            event.detail.complete();
        }, 2000);

        dispatch<any>(invalidate({}));
        dispatch<any>(appSync());
    }

    /**
     * detiene el sonido de la alarma en android
     */
    const onStopAlert = async () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        complete();
    };

    /**
     * reproduce el sonido de la alarma en ios cuando la app este abierta
     */

    useEffect(() => {
        const playAlertSound = () => {
            const audio = new Audio(
                `https://webservice.escudo-vecinal.com.mx/storage/brand/sounds/` +
                    isAlertCurrentByStore?.sound +
                    `.wav`
            );
            audio.volume = 1;
            audio.play();
            audioRef.current = audio;
        };

        if (isAlertActive && Capacitor.getPlatform() === "ios") {
            playAlertSound();
        }
    }, [isAlertActive, isAlertCurrentByStore?.sound]);

    const navigateToIncident = (incidentData: any) => {
        const { latitude, longitude } = incidentData.incident;
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
        window.open(googleMapsUrl, "_blank");
    };

    return (
        <IonPage className="alert-page">
            <HomeToolBar title="Reportes"></HomeToolBar>
            <IonContent className="ion-padding alerts">
                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>

                {isAlertActive && (
                    <IonCard
                        className="flex-column ion-justify-content-center ion-align-items-center stop-alert"
                        onClick={onStopAlert}>
                        <IonGrid className="flex-column ion-justify-content-center ion-align-items-center grid-shield">
                            <IonRow className="row-grid-shield-title">
                                <IonCol className="flex-row ion-justify-content-center ion-align-items-center col-shield-text">
                                    TU VECINO NECESITA AYUDA
                                </IonCol>
                            </IonRow>
                            <IonRow className="row-grid-shield">
                                <IonCol
                                    className="col-incident"
                                    size="12"
                                    style={{
                                        background:
                                            isAlertCurrentByStore
                                                .category_incident.color,
                                    }}>
                                    <IonText className="folio-incident">
                                        {isAlertCurrentByStore.incident.invoice}
                                        &nbsp;
                                    </IonText>
                                    <IonText className="name-user">
                                        • {userAlertCurrent?.name}{" "}
                                        {userAlertCurrent?.last_name}
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row-incident row-description">
                                <IonCol
                                    size="12"
                                    className="flex-row ion-justify-content-center ion-align-items-center">
                                    <IonIcon
                                        className="icon-catalog"
                                        src={
                                            "assets/icon/" +
                                            isAlertCurrentByStore
                                                .category_incident.icon +
                                            ".svg"
                                        }
                                    />
                                </IonCol>
                                <IonCol size="12" className="ion-text-center">
                                    <IonLabel className="date-incident">
                                        {DateTime.fromSQL(
                                            isAlertCurrentByStore.incident
                                                .created_at
                                        )
                                            .setLocale("es")
                                            .toLocaleString(DateTime.DATE_MED)}
                                    </IonLabel>
                                    <IonLabel className="time-incident">
                                        {" "}
                                        •{" "}
                                        {DateTime.fromSQL(
                                            isAlertCurrentByStore.incident
                                                .created_at
                                        )
                                            .setLocale("es")
                                            .toLocaleString({
                                                hour: "numeric",
                                                minute: "2-digit",
                                                hour12: true,
                                            })}
                                    </IonLabel>
                                    <div>
                                        <IonLabel
                                            className="type-incident"
                                            style={{
                                                color: isAlertCurrentByStore
                                                    .category_incident.color,
                                            }}>
                                            {
                                                isAlertCurrentByStore
                                                    .category_incident.title
                                            }
                                        </IonLabel>
                                    </div>
                                    <div>
                                        <IonLabel className="address-incident">
                                            {
                                                isAlertCurrentByStore.incident
                                                    ?.address
                                            }
                                        </IonLabel>
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row-grid-shield-button">
                                <IonCol
                                    size="12"
                                    className="flex-row ion-justify-content-center ion-align-items-center image-text flashing-image">
                                    <IonImg
                                        src={Shield}
                                        className="image-shield"></IonImg>
                                    &nbsp; DETENER
                                </IonCol>
                            </IonRow>
                            <IonRow className="row-grid-go-help">
                                <IonCol
                                    size="12"
                                    className="flex-row ion-justify-content-center ion-align-items-center">
                                    <IonButton
                                        onClick={() =>
                                            navigateToIncident(
                                                isAlertCurrentByStore
                                            )
                                        }>
                                        Ir al Auxilio
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCard>
                )}

                {syncStatus === "fulfilled" && !isAlertActive && (
                    <>
                        {isGovernor || isDependency || groups.length === 0 ? (
                            <div className="box-notice ion-text-center">
                                <IonText className="box-notice-title">
                                    {" "}
                                    ¿Botones Inactivos?{" "}
                                </IonText>
                                <div>
                                    <IonText className="box-notice-title-subtitle">
                                        {" "}
                                        espera a que se te asigne a un{" "}
                                    </IonText>
                                    <IonText className="box-notice-title-subtitle-bold">
                                        grupo vecinal{" "}
                                    </IonText>
                                </div>
                            </div>
                        ) : (
                            <div className="box-notice ion-text-center">
                                <IonText className="box-notice-title">
                                    ¿Cual es tu Emergencia?{" "}
                                </IonText>
                                <div>
                                    <IonText className="box-notice-title-subtitle">
                                        {" "}
                                        presione una opción por{" "}
                                    </IonText>
                                    <IonText className="box-notice-title-subtitle-bold">
                                        {" "}
                                        4{" "}
                                    </IonText>

                                    <IonText className="box-notice-title-subtitle">
                                        {" "}
                                        segundos{" "}
                                    </IonText>
                                </div>
                            </div>
                        )}

                        <IonGrid class="custom">
                            <IonRow className="ion-justify-content-center">
                                {categories.map((category) => (
                                    <IonCol
                                        className="text-align-center ion-no-padding col-z-index"
                                        size="6"
                                        key={category.catalog_incident_id}>
                                        <AlertButton
                                            disabled={
                                                isGovernor ||
                                                isDependency ||
                                                groups.length === 0
                                            }
                                            title={category.title}
                                            color={category.color}
                                            id={category.catalog_incident_id}
                                            icon={category.icon}
                                            onAlert={onHalderIncidents}
                                        />
                                    </IonCol>
                                ))}
                            </IonRow>
                        </IonGrid>

                        {/* {myMemberships[0].type == MEMBERSHIPS.ADMINISTRATOR && (
                            <PanicButton />
                        )} */}

                        <div className="footer"></div>
                    </>
                )}
                {syncStatus === "pending" && (
                    <div className="ion-margin-top loading-container">
                        Cargando...
                    </div>
                )}
            </IonContent>
        </IonPage>
    );
};

export default Alerts;
