import {
    createAnimation,
    IonCard,
    IonIcon,
    IonText,
    createGesture,
    Animation,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonButton,
    IonContent,
    IonItem,
    IonLabel,
    IonAccordion,
    IonAccordionGroup,
    IonGrid,
    IonRow,
    IonCol,
    IonTabButton,
    IonFab,
    IonFabButton,
    useIonLoading,
    useIonActionSheet,
    IonPage,
    IonCardSubtitle,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonFooter,
} from "@ionic/react";
import "./PanicButton.scss";
import React, { useEffect, useRef, useState } from "react";
import {
    alertCircle,
    arrowBack,
    business,
    call,
    carSport,
    chevronDownOutline,
    chevronUpOutline,
    headset,
    informationCircle,
    medical,
    medkit,
    removeOutline,
    shield,
    shieldHalf,
} from "ionicons/icons";
import arrowBackButton from "../../assets/icons/arrow-back-button.svg";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { selectAuthenticatedUserId } from "../../redux/slices/auth";
import { useSelector } from "react-redux";
import { useFeedback } from "../../hooks";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CreateEmergenncyIncident } from "../../redux/slices/app";
import Feedback from "../../services/feedback";
import { text } from "@fortawesome/fontawesome-svg-core";
import { Background } from "../../plugins/Background";
import InfoModal from "./components/InfoModal";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

const PanicButton: React.FC<any> = () => {
    /**
     * Local state
     */
    const [progress, setProgress] = useState(0);
    const [isPressed, setIsPressed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const modal = useRef<HTMLIonModalElement>(null);
    const page = useRef(null);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [isIncidentCompleted, setIsIncidentCompleted] = useState(false);

    const [presentingElement, setPresentingElement] =
        useState<HTMLElement | null>(null);
    const [Actionsheet] = useIonActionSheet();

    useEffect(() => {
        setPresentingElement(page.current);
    }, []);

    /**
     * Hooks
     */
    const [Alertpresent, Alertdismiss] = useIonLoading();
    const history = useHistory();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    const userId = useSelector(selectAuthenticatedUserId);

    /*
     * Funcion para mostrar el modal
     */

    function dismiss() {
        modal.current?.dismiss();
    }

    function present() {
        modal.current?.present();
    }

    function canDismiss() {
        return new Promise<boolean>((resolve, reject) => {
            if (isIncidentCompleted) {
                resolve(true);
                return;
            }

            Actionsheet({
                header: "Estas seguro de cancelar el incidente",
                buttons: [
                    {
                        text: "Si",
                        role: "confirm",

                        handler: () => {
                            setStep(1);
                            resolve(true);
                            formik.resetForm();
                        },
                    },
                    {
                        text: "No",
                        role: "cancel",
                        handler: () => {
                            resolve(false);
                        },
                    },
                ],
                mode: "ios",
            });
        });
    }
    const accordionData = [
        {
            id: 1,
            value: "first",
            color: "medical",
            icon: medkit,
            label: "Medico",
            class: "header-one icon-color-custom",
            subcategoryClass: "one",
            content: [
                {
                    title: "ACCIDENTES",
                    content: [
                        {
                            id: 10101,
                            label: "ACCIDENTE ACUÁTICO CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10102,
                            label: "ACCIDENTE DE AERONAVE CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10103,
                            label: "ACCIDENTE DE MOTOCICLETA CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10104,
                            label: "ACCIDENTE DE VEHÍCULO AUTOMOTOR CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10105,
                            label: "ACCIDENTE FERROVIARIO CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10106,
                            label: "ACCIDENTE FERROVIARIO CON PERSONAS FALLECIDAS",
                        },
                        {
                            id: 10107,
                            label: "ACCIDENTE MULTIPLE CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10108,
                            label: "ACCIDENTE MULTIPLE CON PERSONAS FALLECIDAS",
                        },
                        {
                            id: 10109,
                            label: "ACCIDENTE DE TRANSPORTE DE PASAJEROS CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10110,
                            label: "ACCIDENTE DE TRANSPORTE DE PASAJEROS CON PERSONAS FALLECIDAS",
                        },
                        {
                            id: 10111,
                            label: "ACCIDENTE DE MOTOCICLETA CON PERSONAS FALLECIDAS",
                        },
                        {
                            id: 10112,
                            label: "ACCIDENTE DE VEHÍCULO AUTOMOTOR CON PERSONAS FALLECIDAS",
                        },
                        {
                            id: 10113,
                            label: "ACCIDENTE DE AERONAVE CON PERSONAS FALLECIDAS",
                        },
                        {
                            id: 10114,
                            label: "ACCIDENTE DE EMBARCACIONES CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10115,
                            label: "ACCIDENTE DE EMBARCACIONES CON PERSONAS FALLECIDAS",
                        },
                        {
                            id: 10116,
                            label: "ATROPELLAMIENTO CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10117,
                            label: "ACCIDENTE ACUÁTICO CON PERSONA FALLECIDA",
                        },
                        {
                            id: 10118,
                            label: "ACCIDENTES CON MATERIALES PELIGROSOS",
                        },
                        {
                            id: 10119,
                            label: "ACCIDENTES CON MATERIALES RADIOACTIVOS",
                        },
                        {
                            id: 10120,
                            label: "ACCIDENTES CON RIESGO BIOLÓGICO INFECTO CONTAGIOSO",
                        },
                        {
                            id: 10121,
                            label: "OTROS ACCIDENTES CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10122,
                            label: "ACCIDENTE DE AUTOTRANSPORTE DE CARGA CON PERSONAS LESIONADAS",
                        },
                        {
                            id: 10123,
                            label: "ACCIDENTE DE AUTOTRANSPORTE DE CARGA CON PERSONAS FALLECIDAS",
                        },
                        {
                            id: 10124,
                            label: "ATROPELLAMIENTO CON PERSONAS FALLECIDAS",
                        },
                        {
                            id: 10125,
                            label: "PERSONAS FALLECIDAS POR ELECTROCUCION",
                        },
                        {
                            id: 10126,
                            label: "PERSONAS FALLECIDAS POR QUEMADURAS",
                        },
                        {
                            id: 10127,
                            label: "ACCIDENTE DE VEHÍCULOS DE TRACCIÓN HUMANA CON LESIONADOS",
                        },
                    ],
                },
                {
                    title: "TRAUMÁTICOS",
                    content: [
                        { id: 10201, label: "AHOGAMIENTO" },
                        { id: 10202, label: "AMPUTACIÓN" },
                        { id: 10203, label: "ASFIXIA" },
                        { id: 10204, label: "PERSONA LESIONADA POR CAÍDA" },
                        {
                            id: 10205,
                            label: "PERSONA LESIONADA POR CORRIENTE ELÉCTRICA",
                        },
                        {
                            id: 10206,
                            label: "PERSONA CON FRACTURA/TRAUMATISMO EN EXTREMIDADES",
                        },
                        { id: 10207, label: "HEMORRAGIA" },
                        {
                            id: 10208,
                            label: "PERSONA LESIONADA POR ARMA BLANCA",
                        },
                        {
                            id: 10209,
                            label: "PERSONA LESIONADA POR PROYECTIL DE ARMA DE FUEGO",
                        },
                        { id: 10210, label: "MORDEDURA DE ANIMAL" },
                        {
                            id: 10211,
                            label: "PERSONA LESIONADA POR QUEMADURAS",
                        },
                        { id: 10212, label: "TRAUMATISMO MULTIPLE " },
                        { id: 10213, label: "TRAUMATISMO DE CRÁNEO" },
                        {
                            id: 10214,
                            label: "TRAUMATISMO DE TÓRAX (PECHO Y ESPALDA)",
                        },
                        { id: 10215, label: "TRAUMATISMO ABDOMINAL" },
                        {
                            id: 10216,
                            label: "TRAUMATISMO GENITAL Y/O URINARIO",
                        },
                        {
                            id: 10217,
                            label: "CONGELAMIENTO/PERSONA LESIONADA POR CONDICIONES AMBIENTALES",
                        },
                        { id: 10218, label: "HIPOTERMIA" },
                        {
                            id: 10219,
                            label: "PERSONA FALLECIDA DE CAUSA TRAUMÁTICA",
                        },
                        {
                            id: 10220,
                            label: "OTROS INCIDENTES MEDICOS TRAUMÁTICOS",
                        },
                        { id: 10221, label: "ATRAGANTAMIENTO" },
                        {
                            id: 10222,
                            label: "PERSONA LESIONADA POR OBJETO CONTUNDENTE",
                        },
                        { id: 10223, label: "PERSONA FALLECIDA POR ASFIXIA" },
                        { id: 10224, label: "DESHIDRATACIÓN" },
                        {
                            id: 10225,
                            label: "PERSONA LESIONADA DURANTE ALTERACIÓN DEL ORDEN PUBLICO O POR RIÑA",
                        },
                        {
                            id: 10226,
                            label: "PERSONAS FALLECIDAS POR PROYECTIL DE ARMA DE FUEGO",
                        },
                        {
                            id: 10227,
                            label: "PERSONAS FALLECIDAS POR ARMA BLANCA",
                        },
                        {
                            id: 10228,
                            label: "PERSONA FALLECIDA POR AHOGAMIENTO",
                        },
                        { id: 10229, label: "PERSONA CON GOLPE DE CALOR" },
                        { id: 10230, label: "TENTATIVA DE SUICIDIO" },
                    ],
                },
                {
                    title: "CLINICOS",
                    content: [
                        { id: 10301, label: "TRABAJO DE PARTO" },
                        { id: 10302, label: "EMERGENCIA OBSTÉTRICA" },
                        { id: 10303, label: "URGENCIA EN PACIENTE EMBARAZADA" },
                        {
                            id: 10304,
                            label: "URGENCIA NEUROLÓGICA (EVENTO CEREBRAL VASCULAR)",
                        },
                        {
                            id: 10305,
                            label: "DIFICULTAD RESPIRATORIA/URGENCIA RESPIRATORIA",
                        },
                        { id: 10306, label: "INTOXICACIÓN ETÍLICA" },
                        { id: 10307, label: "CONVULSIONES" },
                        { id: 10308, label: "PERSONA INCONSCIENTE" },
                        {
                            id: 10309,
                            label: "MORDEDURA O PICADURA POR ANIMAL DE PONZOÑA",
                        },
                        { id: 10310, label: "URGENCIA POR ENFERMEDAD GENERAL" },
                        {
                            id: 10311,
                            label: "DOLOR ABDOMINAL/URGENCIA ABDOMINAL",
                        },
                        { id: 10312, label: "DESCOMPENSACIÓN DE LA DIABETES" },
                        { id: 10313, label: "PARO CARDIORESPIRATORIO" },
                        { id: 10314, label: "INFARTO/URGENCIA CARDIOLOGICA" },
                        {
                            id: 10315,
                            label: "INTOXICACIÓN/SOBREDOSIS/ENVENENAMIENTO POR SUSTANCIAS",
                        },
                        {
                            id: 10316,
                            label: "OTROS INCIDENTES MEDICOS CLÍNICOS",
                        },
                        { id: 10317, label: "PERSONA FALLECIDA" },
                        {
                            id: 10318,
                            label: "PERSONA EN CRISIS/APOYO PSICOLÓGICO",
                        },
                        { id: 10319, label: "EPIDEMIAS" },
                        {
                            id: 10320,
                            label: "PERSONA FALLECIDA DE CAUSA PATOLÓGICA",
                        },
                        {
                            id: 10321,
                            label: "PERSONA TIRADA EN LA VIA PUBLICA CON HUELLAS DE VIOLENCIA",
                        },
                    ],
                },
            ],
        },
        {
            id: 2,
            value: "seccond",
            color: "proteccion",
            icon: shieldHalf,
            label: "Proteccion",
            content: [
                {
                    title: "AUXILIO",
                    content: [
                        {
                            id: 20101,
                            label: "ACCIDENTE FERROVIARIO SIN PERSONAS LESIONADAS",
                        },
                        {
                            id: 20102,
                            label: "ALMACENAMIENTO ILÍCITO DE SUSTANCIAS PELIGROSAS",
                        },
                        { id: 20103, label: "ANIMAL PELIGROSO" },
                        { id: 20104, label: "ANIMAL SUELTO" },
                        { id: 20105, label: "FUGAS Y DERRAMES EN ESCUELA" },
                        {
                            id: 20106,
                            label: "DESBORDAMIENTO DE CUERPO DE AGUA",
                        },
                        { id: 20107, label: "EXPLOSION" },
                        {
                            id: 20108,
                            label: "FUGAS Y DERRAMES DE SUSTANCIAS QUÍMICAS",
                        },
                        {
                            id: 20109,
                            label: "HUNDIMIENTOS/AGRIETAMIENTOS/INESTABILIDAD DEL TERRENO/ LADERA",
                        },
                        { id: 20110, label: "GASES TÓXICOS" },
                        { id: 20111, label: "OLORES FÉTIDOS" },
                        {
                            id: 20112,
                            label: "MATERIALES PELIGROSOS O RADIOACTIVOS (EXPOSICIÓN)",
                        },
                        {
                            id: 20113,
                            label: "CAÍDA O INCLINACIÓN PELIGROSA DE ANUNCIO O ESPECTACULAR",
                        },
                        {
                            id: 20114,
                            label: "TRANSPORTE NEGLIGENTE O CLANDESTINO DE SUSTANCIAS PELIGROSAS",
                        },
                        { id: 20115, label: "FUGA DE GAS" },
                    ],
                },
                {
                    title: "MEDIO AMBIENTE",
                    content: [
                        {
                            id: 20201,
                            label: "CONTAMINACIÓN DE SUELO AIRE Y AGUA",
                        },
                        { id: 20202, label: "DERRUMBES Y DESLAVES" },
                        { id: 20203, label: "ENJAMBRE DE ABEJAS" },
                        { id: 20204, label: "ERUPCIÓN O EMISIONES VOLCÁNICAS" },
                        {
                            id: 20205,
                            label: "FRENTES FRÍOS BAJAS TEMPERATURAS NEVADAS Y HELADAS",
                        },
                        { id: 20206, label: "HURACANES" },
                        { id: 20207, label: "INUNDACIONES" },
                        { id: 20208, label: "PLAGAS" },
                        { id: 20209, label: "SISMO" },
                        { id: 20210, label: "ÁRBOL CAÍDO O POR CAER" },
                        { id: 20211, label: "TORMENTAS DE GRANIZO/ NIEVE" },
                        { id: 20212, label: "TORNADOS" },
                        { id: 20213, label: "TSUNAMI" },
                        { id: 20214, label: "TORMENTA DE ARENA O POLVO" },
                    ],
                },
                {
                    title: "INCENDIOS",
                    content: [
                        { id: 20301, label: "INCENDIO DE CASA HABITACIÓN" },
                        { id: 20302, label: "INCENDIO EN ESCUELA" },
                        { id: 20303, label: "INCENDIO DE VEHÍCULO" },
                        { id: 20304, label: "INCENDIO EN COMERCIO" },
                        { id: 20305, label: "INCENDIO DE EDIFICIO" },
                        { id: 20306, label: "INCENDIO A BORDO DE EMBARCACIÓN" },
                        { id: 20307, label: "INCENDIO FORESTAL" },
                        { id: 20308, label: "QUEMA URBANA" },
                        { id: 20309, label: "QUEMA AGROPECUARIA" },
                        { id: 20310, label: "INCENDIO DE FABRICA O INDUSTRIA" },
                        { id: 20311, label: "OTROS INCENDIOS" },
                        { id: 20312, label: "INCENDIO DE RESIDUOS/BASURA" },
                    ],
                },
                {
                    title: "RESCATES",
                    content: [
                        {
                            id: 20401,
                            label: "ACCIDENTE O HUNDIMIENTO DE EMBARCACIÓN SIN PERSONAS LESIONADAS",
                        },
                        { id: 20402, label: "PERSONA ATRAPADA O PRENSADA" },
                        { id: 20403, label: "RESCATE ANIMAL" },
                        { id: 20404, label: "OTROS RESCATES" },
                        {
                            id: 20405,
                            label: "RESCATE DE FLORA O FAUNA SUJETAS A PROTECCIÓN (TRAFICO DE ESPECIES)",
                        },
                        { id: 20406, label: "CONTINGENCIA HUMANO-COCODRILOS" },
                        { id: 20407, label: "PROTECCIÓN DE TORTUGAS" },
                        { id: 20408, label: "CAÍDA EN LUGAR PROFUNDO" },
                        { id: 20409, label: "RESCATE POR FENÓMENO NATURAL" },
                        {
                            id: 20410,
                            label: "RESCATE DE PERSONA NO LOCALIZADA EN ZONA DE DIFÍCIL ACCESO",
                        },
                    ],
                },
            ],
        },
        {
            id: 3,
            value: "third",
            color: "security",
            icon: carSport,
            label: "Seguridad",
            content: [
                {
                    title: "ABANDONO",
                    content: [
                        { id: 30101, label: "VEHÍCULO ABANDONADO" },
                        { id: 30102, label: "OBJETO SOSPECHOSO O PELIGROSO" },
                        { id: 30103, label: "PERSONA TIRADA EN VIA PUBLICA" },
                    ],
                },
                {
                    title: "ACTOS RELACIONADOS CON ARMAS/EXPLOSIVOS",
                    content: [
                        { id: 30201, label: "DETONACIÓN DE EXPLOSIVOS" },
                        { id: 30202, label: "DETONACIÓN POR ARMA DE FUEGO" },
                        { id: 30203, label: "PERSONA ARMADA EN ESCUELA" },
                        {
                            id: 30204,
                            label: "PORTACIÓN DE ARMAS DE FUEGO O CARTUCHOS",
                        },
                        {
                            id: 30205,
                            label: "DETONACIÓN DE COHETES O FUEGOS ARTIFICIALES",
                        },
                        {
                            id: 30206,
                            label: "DETONACIÓN DE ARMA DE FUEGO EN ESCUELA",
                        },
                        { id: 30207, label: "TRAFICO DE ARMAS O EXPLOSIVOS" },
                        {
                            id: 30208,
                            label: "ACCIDENTE CON COHETES O FUEGOS ARTIFICIALES",
                        },
                        { id: 30209, label: "PORTACIÓN DE ARMA BLANCA" },
                    ],
                },
                {
                    title: "ACTOS RELACIONADOS CON LAS VÍAS DE COMUNICACIÓN",
                    content: [
                        { id: 30301, label: "AERONAVE SOSPECHOSA" },
                        {
                            id: 30302,
                            label: "ARRANCONES O CARRERAS DE VEHÍCULOS",
                        },
                        {
                            id: 30303,
                            label: "BLOQUEO O CORTES DE VIAS DE COMUNICACIÓN",
                        },
                        { id: 30304, label: "CIRCULAR EN SENTIDO CONTRARIO" },
                        { id: 30305, label: "VEHÍCULO A EXCESO DE VELOCIDAD" },
                        { id: 30306, label: "VEHÍCULO EN HUIDA" },
                        { id: 30307, label: "VEHÍCULO SOSPECHOSO" },
                        { id: 30308, label: "VEHÍCULO DESCOMPUESTO" },
                        {
                            id: 30309,
                            label: "ACCIDENTE DE TRANSITO SIN PERSONAS LESIONADAS",
                        },
                        {
                            id: 30310,
                            label: "OTRAS FALTAS AL REGLAMENTO DE TRANSITO",
                        },
                        { id: 30311, label: "JUGADORES EN VIA PUBLICA" },
                        {
                            id: 30312,
                            label: "ACCIDENTE DE MOTOCICLETA SIN PERSONAS LESIONADAS",
                        },
                        {
                            id: 30313,
                            label: "ACCIDENTE DE AERONAVE SIN PERSONAS LESIONADAS",
                        },
                        {
                            id: 30314,
                            label: "ACCIDENTE DE AUTOTRANSPORTE DE CARGA SIN PERSONAS LESIONADAS",
                        },
                        {
                            id: 30315,
                            label: "ACCIDENTE DE AUTOTRANSPORTE DE PASAJEROS SIN PERSONAS LESIONADAS",
                        },
                        {
                            id: 30316,
                            label: "ROBO U OBSTRUCCIÓN CASETA DE PEAJE",
                        },
                    ],
                },
                {
                    title: "ACTOS RELACIONADOS CON EL PATRIMONIO",
                    content: [
                        {
                            id: 30401,
                            label: "OTRAS ALARMAS DE EMERGENCIAS ACTIVADAS",
                        },
                        {
                            id: 30402,
                            label: "BOTÓN DE ALERTA/ ALARMA DE EMERGENCIA ACTIVADO",
                        },
                        {
                            id: 30403,
                            label: "CRISTALAZO O ROBO AL INTERIOR DE VEHÍCULO",
                        },
                        { id: 30404, label: "DAÑOS A PROPIEDAD AJENA" },
                        { id: 30405, label: "DESPOJO" },
                        {
                            id: 30406,
                            label: "EXTORSION O INTENTO DE EXTORSION (NO TELEFONICA)",
                        },
                        { id: 30407, label: "EXTORSION TELEFONICA" },
                        { id: 30408, label: "ACTIVACIÓN DE ALARMA EN ESCUELA" },
                        { id: 30409, label: "ROBO DE COMBUSTIBLE" },
                        { id: 30410, label: "ROBO A CAJERO AUTOMÁTICO" },
                        { id: 30411, label: "ROBO DE AUTOPARTES O ACCESORIOS" },
                        {
                            id: 30412,
                            label: "ROBO DE GANADO O PRODUCTOS AGROPECUARIOS",
                        },
                        {
                            id: 30413,
                            label: "ROBO A CASA HABITACIÓN CON VIOLENCIA",
                        },
                        {
                            id: 30414,
                            label: "ROBO A CASA HABITACIÓN SIN VIOLENCIA",
                        },
                        { id: 30415, label: "ROBO A ESCUELA CON VIOLENCIA" },
                        { id: 30416, label: "ROBO A ESCUELA SIN VIOLENCIA" },
                        { id: 30417, label: "ROBO A GASOLINERA CON VIOLENCIA" },
                        { id: 30418, label: "ROBO A GASOLINERA SIN VIOLENCIA" },
                        { id: 30419, label: "ROBO A NEGOCIO CON VIOLENCIA" },
                        { id: 30420, label: "ROBO A NEGOCIO SIN VIOLENCIA" },
                        { id: 30421, label: "ROBO A TRANSEÚNTE CON VIOLENCIA" },
                        { id: 30422, label: "ROBO A TRANSEÚNTE SIN VIOLENCIA" },
                        {
                            id: 30423,
                            label: "ROBO EN TRANSPORTE COLECTIVO PUBLICO O PRIVADO CON VIOLENCIA",
                        },
                        {
                            id: 30424,
                            label: "ROBO EN TRANSPORTE COLECTIVO PUBLICO O PRIVADO SIN VIOLENCIA",
                        },
                        {
                            id: 30425,
                            label: "ROBO EN TRANSPORTE PUBLICO INDIVIDUAL CON VIOLENCIA",
                        },
                        {
                            id: 30426,
                            label: "ROBO EN TRANSPORTE PUBLICO INDIVIDUAL SIN VIOLENCIA",
                        },
                        {
                            id: 30427,
                            label: "ROBO A TRANSPORTISTA O VEHÍCULO COMERCIAL CON VIOLENCIA",
                        },
                        {
                            id: 30428,
                            label: "ROBO A TRANSPORTISTA O VEHÍCULO COMERCIAL SIN VIOLENCIA",
                        },
                        {
                            id: 30429,
                            label: "ROBO DE VEHÍCULO PARTICULAR CON VIOLENCIA",
                        },
                        {
                            id: 30430,
                            label: "ROBO DE VEHÍCULO PARTICULAR SIN VIOLENCIA",
                        },
                        { id: 30431, label: "ROBO EN CARRETERA CON VIOLENCIA" },
                        { id: 30432, label: "ROBO EN CARRETERA SIN VIOLENCIA" },
                        { id: 30433, label: "ROBO A BANCO CON VIOLENCIA" },
                        { id: 30434, label: "ROBO A BANCO SIN VIOLENCIA" },
                        {
                            id: 30435,
                            label: "ROBO A CASA DE CAMBIO CON VIOLENCIA",
                        },
                        {
                            id: 30436,
                            label: "ROBO A CASA DE CAMBIO SIN VIOLENCIA",
                        },
                        {
                            id: 30437,
                            label: "ROBO A EMPRESA DE TRASLADO DE VALORES CON VIOLENCIA",
                        },
                        {
                            id: 30438,
                            label: "ROBO A EMPRESA DE TRASLADO DE VALORES SIN VIOLENCIA",
                        },
                        { id: 30439, label: "ROBO A FERROCARRIL" },
                        { id: 30440, label: "ROBO DE PLACA" },
                        {
                            id: 30441,
                            label: "ROBO A TRANSPORTE ESCOLAR CON VIOLENCIA",
                        },
                        {
                            id: 30442,
                            label: "ROBO A TRANSPORTE ESCOLAR SIN VIOLENCIA",
                        },
                        {
                            id: 30443,
                            label: "ROBO A EMBARCACIONES Y PIRATERÍA",
                        },
                        {
                            id: 30444,
                            label: "TRANSPORTE ILEGAL DE COMBUSTIBLE",
                        },
                        {
                            id: 30445,
                            label: "ROBO A TEMPLO RELIGIOSO SIN VIOLENCIA",
                        },
                        {
                            id: 30446,
                            label: "OTROS ACTOS RELACIONADOS CON EL PATRIMONIO",
                        },
                        {
                            id: 30447,
                            label: "ROBO A TEMPLO RELIGIOSO CON VIOLENCIA",
                        },
                        { id: 30448, label: "SECUESTRO VIRTUAL" },
                        {
                            id: 30449,
                            label: "TOMA CLANDESTINA DE DUCTOS (HUACHICOLEO)",
                        },
                        { id: 30450, label: "DINERO FALSO" },
                        { id: 30451, label: "PERSONA NEGÁNDOSE A PAGAR" },
                        {
                            id: 30452,
                            label: "ROBO CON VIOLENCIA A CONDUCTOR DE VEHÍCULO PARTICULAR",
                        },
                        {
                            id: 30453,
                            label: "ROBO SIN VIOLENCIA A CONDUCTOR DE VEHÍCULO PARTICULAR",
                        },
                        {
                            id: 30454,
                            label: "ROBO DE MATERIAL PELIGROSO O RADIOACTIVO",
                        },
                        {
                            id: 30455,
                            label: "BLOQUEO DE ENTRADA SALIDA DE DOMICILIO",
                        },
                        { id: 30456, label: "ALARMA DE BANCO" },
                        {
                            id: 30457,
                            label: "DAÑOS A DISPOSITIVOS DE MONITOREO Y ALERTAMIENTO DE SEGURIDAD PUBLICA",
                        },
                        {
                            id: 30458,
                            label: "ROBO A CUENTA HABIENTE Y/O TARJETA HABIENTE",
                        },
                        { id: 30459, label: "ROBO DE ANIMALES" },
                        { id: 30460, label: "ROBO A REPARTIDOR CON VIOLENCIA" },
                        { id: 30461, label: "ROBO A REPARTIDOR SIN VIOLENCIA" },
                        {
                            id: 30462,
                            label: "ROBO DE MOTOCICLETA CON VIOLENCIA",
                        },
                        {
                            id: 30463,
                            label: "ROBO DE MOTOCICLETA SIN VIOLENCIA",
                        },
                        {
                            id: 30464,
                            label: "ROBO O EXTRACCIÓN ILÍCITA DE MATERIAL MINERAL",
                        },
                        { id: 30465, label: "DETECCIÓN DE PLACA SOBREPUESTA" },
                        {
                            id: 30466,
                            label: "DETECCIÓN DE VEHÍCULO CON REPORTE DE ROBO",
                        },
                    ],
                },
                {
                    title: "ACTOS RELACIONADOS CON LA FAMILIA",
                    content: [
                        { id: 30501, label: "ABANDONO DE PERSONA" },
                        { id: 30502, label: "VIOLENCIA DE PAREJA" },
                        { id: 30503, label: "VIOLENCIA FAMILIAR" },
                        {
                            id: 30504,
                            label: "OTROS ACTOS RELACIONADOS CON LA FAMILIA",
                        },
                        {
                            id: 30505,
                            label: "MALTRATO DE NIÑAS NIÑOS Y ADOLESCENTES",
                        },
                        {
                            id: 30506,
                            label: "MALTRATO DE PERSONAS ADULTAS MAYORES",
                        },
                    ],
                },
                {
                    title: "ACTOS RELACIONADOS CON LA LIBERTAD PERSONAL",
                    content: [
                        {
                            id: 30601,
                            label: "NIÑA, NIÑO O ADOLESCENTE EXTRAVIADO",
                        },
                        {
                            id: 30602,
                            label: "PERSONA NO LOCALIZADA A DESAPARECIDA",
                        },
                        { id: 30603, label: "PRIVACIÓN DE LA LIBERTAD" },
                        { id: 30604, label: "REHENES" },
                        {
                            id: 30605,
                            label: "ROBO DE NIÑAS, NIÑOS O ADOLESCENTES",
                        },
                        { id: 30606, label: "PERSONA DETENIDA" },
                        {
                            id: 30607,
                            label: "SUSTRACCIÓN, RETENCIÓN U OCULTAMIENTO DE NIÑAS, NIÑOS, ADOLESCENTES O PERSONAS INCAPACES",
                        },
                        {
                            id: 30608,
                            label: "TRAFICO DE NIÑAS, NIÑOS Y ADOLESCENTES",
                        },
                        {
                            id: 30609,
                            label: "OTROS ACTOS RELACIONADOS CON LA LIBERTAD PERSONAL",
                        },
                        {
                            id: 30610,
                            label: "TENTATIVA DE PRIVACIÓN DE LA LIBERTAD",
                        },
                        { id: 30611, label: "NOTIFICACIÓN DE CIBER INCIDENTE" },
                        { id: 30612, label: "NOTICIAS FALSAS   " },
                        { id: 30613, label: "FRAUDE ELECTRÓNICO" },
                        { id: 30614, label: "SEXTORSIÓN" },
                        { id: 30615, label: "ACOSO CIBERNÉTICO" },
                        { id: 30616, label: "PERSONA DESORIENTADA" },
                    ],
                },
                {
                    title: "ACTOS RELACIONADOS CON LA LIBERTAD Y SEGURIDAD SEXUAL",
                    content: [
                        { id: 30701, label: "ABUSO SEXUAL" },
                        { id: 30702, label: "ACOSO U HOSTIGAMIENTO SEXUAL" },
                        {
                            id: 30703,
                            label: "AFECTACIÓN A LA INTIMIDAD SEXUAL",
                        },
                        { id: 30704, label: "ESTUPRO" },
                        {
                            id: 30705,
                            label: "EXPLOTACIÓN DE NIÑAS, NIÑOS Y ADOLESCENTES",
                        },
                        {
                            id: 30706,
                            label: "TRATA DE NIÑAS, NIÑOS Y ADOLESCENTES",
                        },
                        { id: 30707, label: "VIOLACIÓN" },
                        {
                            id: 30708,
                            label: "OTROS ACTOS RELACIONADOS CON LA LIBERTAD Y LA SEGURIDAD SEXUAL",
                        },
                        { id: 30709, label: "TRATA DE PERSONAS" },
                        {
                            id: 30710,
                            label: "TRAFICO DE PERSONAS/ INDOCUMENTADAS",
                        },
                        {
                            id: 30711,
                            label: "CORRUPCIÓN DE PERSONAS MENORES DE EDAD O INCAPACES",
                        },
                        { id: 30712, label: "VIOLENCIA DIGITAL O MEDIÁTICA" },
                        { id: 30713, label: "PORNOGRAFÍA INFANTIL" },
                        {
                            id: 30714,
                            label: "EXPLOTACIÓN DE PERSONAS INCAPACITADAS O DISCAPACITADAS",
                        },
                    ],
                },
                {
                    title: "ACTOS RELACIONADOS CON LA SEGURIDAD COLECTIVA",
                    content: [
                        {
                            id: 30801,
                            label: "ACTOS DE COMERCIALIZACIÓN ILEGAL DE SANGRE, ÓRGANOS Y TEJIDOS HUMANOS",
                        },
                        {
                            id: 30802,
                            label: "ASOCIACIÓN DELICTUOSA O PANDILLERISMO",
                        },
                        {
                            id: 30803,
                            label: "ENFRENTAMIENTO DE GRUPOS ARMADOS",
                        },
                        { id: 30804, label: "TERRORISMO O ATENTADO" },
                        { id: 30805, label: "AMENAZA DE BOMBA" },
                        {
                            id: 30806,
                            label: "OTROS ACTOS RELACIONADOS CON LA SEGURIDAD COLECTIVA",
                        },
                        { id: 30807, label: "MOTÍN" },
                        {
                            id: 30808,
                            label: "VENTA CLANDESTINA DE PIROTECNIA, COHETES O FUEGOS ARTIFICIALES",
                        },
                        { id: 30809, label: "VENTA ILEGAL DE COMBUSTIBLE" },
                        { id: 30810, label: "AMENAZA DE BOMBA EN ESCUELA" },
                        {
                            id: 30811,
                            label: "AMENAZA A CIVILES, LOCALIDADES, CORPORACIONES O PERSONAS SERVIDORAS PUBLICAS (PINTA O NARCOMANTA)",
                        },
                    ],
                },
                {
                    title: "ACTOS RELACIONADOS CON LA VIDA Y LA INTEGRIDAD PERSONAL",
                    content: [
                        {
                            id: 30901,
                            label: "OTROS ACTOS RELACIONADOS CON LA VIDA Y LA INTEGRIDAD PERSONAL",
                        },
                        {
                            id: 30902,
                            label: "ACCIDENTE DE TRANSITO CON MUERTOS",
                        },
                        { id: 30903, label: "VIOLENCIA CONTRA LAS MUJERES" },
                        { id: 30904, label: "PERSONA SOSPECHOSA" },
                        { id: 30905, label: "AMENAZA DE SUICIDIO" },
                        { id: 30906, label: "HOMICIDIO" },
                        { id: 30907, label: "PERSONA AGRESIVA" },
                        { id: 30908, label: "SUICIDIO" },
                        { id: 30909, label: "AGRESIÓN FÍSICA" },
                        { id: 30910, label: "ACOSO ESCOLAR (BULLYING)" },
                        {
                            id: 30911,
                            label: "ACTOS RELACIONADOS CON MIGRANTES",
                        },
                        {
                            id: 30912,
                            label: "RESCATE DE MIGRANTES (EXTRANJEROS INDOCUMENTADOS)",
                        },
                    ],
                },
                {
                    title: "ACTOS RELACIONADOS CON OTROS BIENES JURÍDICOS",
                    content: [
                        { id: 31001, label: "ALLANAMIENTO DE MORADA" },
                        { id: 31002, label: "AMENAZA" },
                        {
                            id: 31003,
                            label: "DAÑO A BIENES PÚBLICOS, INSTITUCIONES, MONUMENTOS, ENTRE OTROS",
                        },
                        {
                            id: 31004,
                            label: "DESCARGA DE DESECHOS SIN PERMISOS",
                        },
                        { id: 31005, label: "ELECTORALES" },
                        { id: 31006, label: "FUGA DE REOS" },
                        { id: 31007, label: "NARCOMENUDEO" },
                        { id: 31008, label: "TOMA DE EDIFICIO PÚBLICO" },
                        {
                            id: 31009,
                            label: "TOMA DE INSTALACIONES EDUCATIVAS CON VIOLENCIA",
                        },
                        { id: 31010, label: "TALA ILEGAL" },
                        { id: 31011, label: "TRÁFICO DE MADERA" },
                        {
                            id: 31012,
                            label: "TRAFICO Y/O VENTA CLANDESTINA DE ANIMALES",
                        },
                        {
                            id: 31013,
                            label: "TRÁFICO DE DROGAS Y ESTUPEFACIENTES EN LA MAR",
                        },
                        {
                            id: 31014,
                            label: "TRÁFICO DE DROGAS Y ESTUPEFACIENTES EN VÍA PÚBLICA",
                        },
                        {
                            id: 31015,
                            label: "OTROS ACTOS RELACIONADOS CON OTROS BIENES JURÍDICOS",
                        },
                    ],
                },
                {
                    title: "DISTURBIOS Y ALTERACIÓN AL ORDEN PÚBLICO",
                    content: [
                        {
                            id: 31101,
                            label: "ALTERACIÓN DEL ORDEN PUBLICO POR PERSONA ALCOHOLIZADA",
                        },
                        {
                            id: 31102,
                            label: "ALTERACIÓN DEL ORDEN PUBLICO POR PERSONA DROGADA",
                        },
                        { id: 31103, label: "CONDUCTOR EBRIO/DROGADO" },
                        {
                            id: 31104,
                            label: "CONSUMO DE ALCOHOL EN VIA PUBLICA",
                        },
                        {
                            id: 31105,
                            label: "CONSUMO DE DROGAS EN VIA PUBLICA",
                        },
                        { id: 31106, label: "CONSUMO DE ALCOHOL EN ESCUELA" },
                        { id: 31107, label: "CONSUMO DE DROGAS EN ESCUELA" },
                        { id: 31108, label: "GRAFITIS" },
                        {
                            id: 31109,
                            label: "MANIFESTACIÓN CON DISTURBIOS O BLOQUEOS",
                        },
                        { id: 31110, label: "MITIN" },
                        { id: 31111, label: "PELEA CLANDESTINA CON ANIMALES" },
                        { id: 31112, label: "RIÑA" },
                        { id: 31113, label: "PERSONA EXHIBICIONISTA" },
                        {
                            id: 31114,
                            label: "OTROS TIPOS DE ALTERACIÓN AL ORDEN PUBLICO",
                        },
                        {
                            id: 31115,
                            label: "VENTA ILEGAL DE BEBIDAS ALCOHÓLICAS",
                        },
                    ],
                },
                {
                    title: "HALLAZOS",
                    content: [
                        {
                            id: 31201,
                            label: "HALLAZGO DE CADAVER/RESTOS HUMANOS",
                        },
                        { id: 31202, label: "HALLAZGO DE ARMA" },
                        { id: 31203, label: "HALLAZGO DE VEHÍCULO ROBADO" },
                    ],
                },
            ],
        },
        {
            id: 4,
            value: "four",
            color: "service",
            icon: business,
            label: "Servicios Publicos",
            label2: "Servicio",
            content: [
                {
                    title: "INFRAESTRUCTURA",
                    content: [
                        { id: 40101, label: "BARDA CAÍDA O POR CAER" },
                        { id: 40102, label: "POSTE CAÍDO O POR CAER" },
                        { id: 40103, label: "FALLA DE ALUMBRADO PUBLICO" },
                        { id: 40104, label: "FALLAS DE SEMÁFORO" },
                        { id: 40105, label: "ALCANTARILLA SIN TAPA" },
                        { id: 40106, label: "CABLES COLGANDO O CAÍDOS" },
                        { id: 40107, label: "CORTO CIRCUITO" },
                        {
                            id: 40108,
                            label: "GRAVA SUELTA O MATERIAL ESPARCIDO",
                        },
                        {
                            id: 40109,
                            label: "AFECTACIÓN DE LOS SERVICIOS BÁSICOS O DE INFRAESTRUCTURA ESTRATÉGICA",
                        },
                        { id: 40110, label: "VIALIDAD EN MAL ESTADO" },
                        { id: 40111, label: "FUGA DE AGUA" },
                        {
                            id: 40112,
                            label: "CAÍDA DE TECHO, CASA, EDIFICIO O CONSTRUCCIÓN",
                        },
                        {
                            id: 40113,
                            label: "DAÑO ESTRUCTURAL Y/O AGRIETAMIENTOS DE EDIFICACIONES",
                        },
                        { id: 40114, label: "ALCANTARILLA OBSTRUIDA" },
                        { id: 40115, label: "ANIMAL MUERTO EN VIA PUBLICA" },
                    ],
                },
            ],
        },
        {
            id: 5,
            value: "five",
            color: "asistence",
            icon: headset,
            label: "Asistencia",
            content: [
                {
                    title: "PROTECCIÓN",
                    content: [
                        {
                            id: 50101,
                            label: "CONCENTRACIÓN PACIFICA DE PERSONAS",
                        },
                        { id: 50102, label: "TENTATIVA DE ROBO" },
                        { id: 50103, label: "EXTRAVÍO DE PLACA" },
                        { id: 50104, label: "FRAUDE  " },
                        { id: 50105, label: "RUIDO EXCESIVO" },
                        {
                            id: 50106,
                            label: "USURPACIÓN DE IDENTIDAD/FUNCIONES",
                        },
                        { id: 50107, label: "ABUSO DE CONFIANZA" },
                        { id: 50108, label: "ABUSO DE AUTORIDAD" },
                        { id: 50109, label: "INGRESO A HOSPITAL" },
                        { id: 50110, label: "INTENTO DE FRAUDE" },
                    ],
                },

                {
                    title: "NOTIFICACIÓN DE HALLAZGO",
                    content: [
                        { id: 50201, label: "RESTOS HUMANOS" },
                        { id: 50202, label: "DE ARMA" },
                        { id: 50203, label: "VEHÍCULO RECUPERADO" },
                    ],
                },
                {
                    title: "APOYO",
                    content: [
                        { id: 50301, label: "APOYO A LA POBLACIÓN" },
                        { id: 50302, label: "PERSONA LOCALIZADA" },
                        { id: 50303, label: "MALTRATO DE ANIMALES" },
                        { id: 50304, label: "PERSONA EN SITUACIÓN DE CALLE" },
                        { id: 50305, label: "SOLICITUD DE RONDIN" },
                        {
                            id: 50306,
                            label: "QUEJA CONTRA SERVIDORES PÚBLICOS",
                        },
                    ],
                },
            ],
        },
        {
            id: 6,
            value: "six",
            color: "other",
            icon: alertCircle,
            label: "Otros servicios",
            content: [
                {
                    title: "OTROS SERVICIOS PÚBLICOS",
                    content: [
                        { id: 60101, label: "ALCANTARILLA OBSTRUIDA" },
                        { id: 60102, label: "ANIMAL MUERTO" },
                        {
                            id: 60103,
                            label: "SOLICITUD DE OTROS SERVICIOS PÚBLICOS",
                        },
                    ],
                },
            ],
        },
    ];

    const customAlertOptions = {
        header: "Incidentes",
        subHeader: "Selecciona un incidente",
    };
    /**
     * Accion para crear los incidentes en el 911
     */

    const validationSchema = Yup.object({
        subcategory: Yup.number().required("Por favor selecciona un incidente"),
    });

    const formik = useFormik({
        initialValues: {
            subcategory: null,
            label: "",
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            Alertpresent({ message: "Creando incidente, por favor espere" });

            const position = await Geolocation.getCurrentPosition();
            const { latitude, longitude } = position.coords;

            dispatch<any>(
                CreateEmergenncyIncident({
                    userId: userId,
                    data: {
                        catalog_incident_id: values.subcategory,
                        latitude: latitude,
                        longitude: longitude,
                        description: values.label,
                        media: 1,
                    },
                })
            )
                .unwrap()
                .then(() => {
                    Alertdismiss();
                    setShowModal(false);
                    setIsIncidentCompleted(true);
                    setStep(1);
                    formik.resetForm();
                    feedbackApp.showFeedback({
                        title: "Incidente creado con exito ",
                    });
                })
                .catch(({ feedback }: any) => {
                    setStep(1);

                    setShowModal(false);
                    setIsIncidentCompleted(true);
                    formik.resetForm();
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                })
                .then(() => {
                    setTimeout(() => {
                        Alertdismiss();
                    }, 50);
                });
        },
    });

    /**
     * Animacion del botton de panico
     */

    useEffect(() => {
        let interval: any;
        if (isPressed) {
            interval = setInterval(() => {
                setProgress((prev) => {
                    if (prev >= 100) {
                        clearInterval(interval);
                        present();
                        setProgress(0);
                        return 100;
                    }
                    return prev + 1;
                });
            }, 15);
        } else {
            setIsIncidentCompleted(false);
            setProgress(0);
        }

        return () => clearInterval(interval);
    }, [isPressed]);

    const [selectedColor, setSelectedColor] = useState("");
    const [step, setStep] = useState(1);
    const [SelectedIncident, setSelectedIncident] = useState<any>([]);

    const handleContentClick = (
        color: string,
        label: string,
        item: any,
        value: string,
        icon: any
    ) => {
        setSelectedColor(color);

        setSelectedIncident({
            label: label,
            value: value,
            item: item,
            icon: icon,
        });
        setStep(2);
        setShowModal(true);
        present();
    };

    const handleBack = () => {
        setStep(1);
        formik.resetForm();
    };

    return (
        <>
            <IonFab className="PanicButton">
                <div
                    className="progress-circle"
                    style={{
                        background: `conic-gradient(#FC71AC ${progress}%, #8200e600 ${progress}%) `, // Cambia el ángulo inicial
                    }}>
                    <IonIcon
                        icon={call}
                        style={{
                            position: "absolute",
                            fontSize: "4rem",
                            left: "54px",
                            top: "5px",
                            transform: "rotate(190deg)",
                            zIndex: 1,
                            color: "#5A4689",
                        }}></IonIcon>

                    <IonFabButton
                        mode="ios"
                        onTouchStart={() => setIsPressed(true)}
                        onTouchEnd={() => setIsPressed(false)}
                        onMouseDown={() => setIsPressed(true)}
                        onMouseUp={() => setIsPressed(false)}>
                        <IonLabel
                            style={{
                                fontsWeight: "bold",
                                fontSize: "2rem",
                            }}>
                            911
                        </IonLabel>
                    </IonFabButton>
                </div>
            </IonFab>

            <IonModal
                className="modal-911"
                ref={modal}
                backdropDismiss={false}
                mode="ios"
                isOpen={showModal}
                canDismiss={canDismiss}
                presentingElement={presentingElement!}>
                {step === 1 ? (
                    <>
                        <IonHeader className="ion-text-center ion-flex Incidents-tool-bar ion-no-border">
                            <IonToolbar className="header">
                                <IonTitle className="title">
                                    CATEGORIA DE INCIDENTES
                                </IonTitle>
                            </IonToolbar>

                            <IonToolbar className="subtitle ion-align-items-center ion-justify-content-center">
                                <IonTitle className="title">
                                    ELIGE EL TIPO DE EMERGENCIA
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>{" "}
                        <IonContent className="ion-padding">
                            <IonAccordionGroup expand="inset">
                                {accordionData.map(
                                    ({
                                        value,
                                        color,
                                        icon,
                                        label,
                                        content,
                                    }) => (
                                        <IonAccordion key={value} value={value}>
                                            <IonItem
                                                lines="none"
                                                slot="header"
                                                color={color}>
                                                <IonIcon
                                                    className="Icon-incidents"
                                                    src={icon}
                                                />
                                                <IonLabel className="label-incidents">
                                                    {label}
                                                </IonLabel>
                                            </IonItem>

                                            {content.map((item, index) => (
                                                <div
                                                    slot="content"
                                                    key={index}
                                                    className={`ion-padding content-${color}`}
                                                    onClick={() => {
                                                        handleContentClick(
                                                            color,
                                                            label,
                                                            item,
                                                            value,
                                                            icon
                                                        );
                                                    }}>
                                                    <strong>
                                                        {item.title}
                                                    </strong>
                                                </div>
                                            ))}
                                        </IonAccordion>
                                    )
                                )}
                            </IonAccordionGroup>
                        </IonContent>
                        <IonFooter>
                            <IonItem
                                mode="md"
                                button
                                lines="none"
                                onClick={() => setShowInfoModal(true)}>
                                <IonIcon
                                    style={{ color: "red" }}
                                    src={informationCircle}
                                />
                                <IonLabel style={{ color: "red" }}>
                                    Artículo 406 BIS
                                </IonLabel>
                            </IonItem>
                            <IonButtons
                                style={{
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#f3effa",
                                }}>
                                <IonButton
                                    style={{
                                        height: "50px",
                                        width: "100%",
                                        backgroundColor: "#5A4689",
                                        color: "white",
                                        fontweight: "bold",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() => {
                                        dismiss();
                                    }}>
                                    Cerrar
                                </IonButton>
                            </IonButtons>
                        </IonFooter>
                    </>
                ) : (
                    <>
                        <IonHeader className="ion-text-center ion-flex ion-no-border">
                            <IonToolbar className={`content-${selectedColor}`}>
                                <IonButtons slot="start">
                                    <IonButton
                                        onClick={() => {
                                            handleBack();
                                        }}>
                                        <IonIcon icon={arrowBack} />
                                    </IonButton>
                                </IonButtons>
                                <IonButtons slot="end">
                                    <IonButton>
                                        <IonIcon src={SelectedIncident.icon} />
                                    </IonButton>
                                </IonButtons>
                                <IonTitle className="title-category">
                                    {SelectedIncident.label}
                                </IonTitle>
                            </IonToolbar>

                            <IonToolbar
                                className={`subtitle-${selectedColor} ion-align-items-center ion-justify-content-center`}>
                                <IonTitle className="title">
                                    {SelectedIncident.item.title}
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent className="ion-padding-top">
                            <IonItem className="ion-padding-horizontal">
                                <IonSelect
                                    interface="alert"
                                    interfaceOptions={{
                                        ...customAlertOptions,
                                        cssClass: `custom-alert-class ${SelectedIncident.value}`,
                                    }}
                                    okText="Confirmar"
                                    cancelText="Cancelar"
                                    mode="md"
                                    name="subcategory"
                                    labelPlacement="stacked"
                                    placeholder="Selecciona un incidente"
                                    toggleIcon={chevronDownOutline}
                                    expandedIcon={chevronUpOutline}
                                    value={formik.values.subcategory}
                                    onIonChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    onIonBlur={formik.handleBlur}>
                                    <div slot="label">
                                        Incidente{" "}
                                        {formik.touched.subcategory &&
                                        formik.errors.subcategory ? (
                                            <IonText
                                                className="ion-text-left"
                                                style={{
                                                    color: "#d11818",
                                                    fontSize: "90%",
                                                    fontWeight: "800",
                                                }}>
                                                ({formik.errors.subcategory})
                                            </IonText>
                                        ) : null}
                                    </div>
                                    {SelectedIncident.item.content.map(
                                        (category: any) => (
                                            <IonSelectOption
                                                key={category.id}
                                                value={category.id}>
                                                {category.label}
                                            </IonSelectOption>
                                        )
                                    )}
                                </IonSelect>
                            </IonItem>

                            <IonTextarea
                                mode="ios"
                                label="Descripción"
                                labelPlacement="stacked"
                                placeholder="Ingresa una descripción..."
                                counter={true}
                                className="ion-padding-top  ion-padding-horizontal"
                                maxlength={200}
                                rows={8}
                                style={{ overflow: "hidden" }}
                                name="label"
                                value={formik.values.label}
                                onInput={(e) => {
                                    formik.handleChange(e);
                                }}
                                onIonBlur={formik.handleBlur}
                                helperText="(Opcional)"
                            />
                        </IonContent>

                        <IonFooter>
                            <IonItem
                                mode="md"
                                button
                                lines="none"
                                onClick={() => setShowInfoModal(true)}>
                                <IonIcon
                                    style={{ color: "red" }}
                                    src={informationCircle}
                                />
                                <IonLabel style={{ color: "red" }}>
                                    Artículo 406 BIS
                                </IonLabel>
                            </IonItem>

                            <IonButtons
                                style={{
                                    display: "flex",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#f3effa",
                                }}>
                                <IonButton
                                    style={{
                                        height: "50px",
                                        width: "100%",
                                        backgroundColor: "#5A4689",
                                        color: "white",
                                        fontWeight: "bold",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() => {
                                        dismiss();
                                    }}>
                                    Cerrar
                                </IonButton>

                                <IonButton
                                    style={{
                                        height: "50px",
                                        width: "100%",
                                        backgroundColor: "#32D29D",
                                        color: "white",
                                        fontWeight: "bold",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() => formik.handleSubmit()}
                                    disabled={
                                        formik.isSubmitting ||
                                        !formik.isValid ||
                                        formik.values.subcategory === null
                                    }>
                                    {" "}
                                    Enviar
                                </IonButton>
                            </IonButtons>
                        </IonFooter>
                    </>
                )}
            </IonModal>

            <InfoModal
                isOpen={showInfoModal}
                onClose={() => setShowInfoModal(false)}
            />
        </>
    );
};

export default PanicButton;
