// LocationPermissionModal.tsx
import React from "react";
import { IonModal, IonButton } from "@ionic/react";
import "./ModalPermision.scss";

interface LocationPermissionModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const LocationPermissionModal: React.FC<LocationPermissionModalProps> = ({
  isOpen,
  closeModal,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      className="ModalPermision"
    >
      <div className="wrapper">
        <p className="TitlePermision">Advertencia</p>
        <p className="textPermision">
          <span>
            Denegó el acceso a los permisos de ubicación o el GPS se encuntra desactivado, 
            los cuales son requeridos para realizar esta acción
          </span>
        </p>
        <IonButton className="buttonGoBack" onClick={closeModal}>
          regresar
        </IonButton>
      </div>
    </IonModal>
  );
};

export default LocationPermissionModal;
