import { IonButton, IonModal, IonTitle } from "@ionic/react";
import React, { useState } from "react";
import "./style.scss";

interface ConfirmationModalProps {
    isOpen: boolean;
    message: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    message,
    onCancel,
    onConfirm,
}) => {
    return (
        <IonModal
            backdropDismiss={false}
            className="ModalCreate-user-shield"
            isOpen={isOpen}>
            <div className="wrapper ion-padding">
                <p className="TitleModalCreate-user-shield">¡Atención!</p>
                <p className="textModalCreate-user-shield ion-margin-bottom">
                    <span>{message}</span>
                </p>
                <div className="buttonContainer">
                    <IonButton className="buttonClose" onClick={onCancel}>
                        Cancelar
                    </IonButton>
                    <IonButton className="buttonConfirm" onClick={onConfirm}>
                        Confirmar
                    </IonButton>
                </div>
            </div>
        </IonModal>
    );
};
export default ConfirmationModal;
