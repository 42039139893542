import {
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import {
    selectActivedMembershipsByUser,
    selectAllMembreships,
    selectMeMemberships,
    selectMembershipsByUser,
} from "../memberships";
import { MEMBERSHIPS } from "../../../../constants";

/**
 * create adapter for the group entity
 */
const groupAdapter = createEntityAdapter<any>({
    selectId: (group) => group.group_id,
    sortComparer: (groupA, groupB) => groupA.group_id - groupB.group_id,
});

/**
 * groups slice definitions
 */
export const groupsSlice = createSlice({
    name: "groups",
    initialState: groupAdapter.getInitialState(),
    reducers: {
        addManygroups: groupAdapter.addMany,
        addOnegroup: groupAdapter.addOne,
        upsertOnegroup: groupAdapter.upsertOne,
        upsertManygroups: groupAdapter.upsertMany,
        updateOneGroup: groupAdapter.updateOne,
        setAllGroups: groupAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return groupAdapter.getInitialState();
        });
    },
});

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManygroups,
    addOnegroup,
    upsertOnegroup,
    upsertManygroups,
    setAllGroups,
    updateOneGroup,
} = groupsSlice.actions;

/**
 * export reducer autegenerated by toolkit
 */
export default groupsSlice.reducer;

//////////////////// SELECTORES //////////////////

/**
 * global selectors
 */
const globalSelectors = groupAdapter.getSelectors(
    (store: any) => store.entities.groups
);

export const selectAllgroups = (store: any) => globalSelectors.selectAll(store);
export const selectgroupsById = (groupId: any) => (store: any) =>
    globalSelectors.selectById(store, groupId);
export const selectGroupsIds = (store: any) => globalSelectors.selectIds(store);
export const selectgroupsEntities = (store: any) =>
    globalSelectors.selectEntities(store);

/**
 * selector para recuperar lo grupos activos de un usuario
 */
export const selectActivedGroupsByUser = (userId: any) =>
    createSelector(
        [selectActivedMembershipsByUser(userId), selectAllgroups],
        (memberships, allGroups) =>
            allGroups.filter((group) =>
                memberships.find((member) => member.group_id == group.group_id)
            )
    );

/**
 * selector para determinar si un usuarios tiene un grupo activo
 */
export const selectUserHasActivedGroups = (userId: any) =>
    createSelector(
        [selectActivedGroupsByUser(userId)],
        (groups) => groups.length > 0
    );

//////////////////////// USUARIO AUTENTICADO  ///////////////////

/**
 * selector para recuperar los grupos activos con membrecias del usuario autenticado
 */
export const selectMeGroupsWithMembership = createSelector(
    [selectMeMemberships, selectAllgroups],
    (meMemberships: any, allGroups) => {
        return allGroups
            .map((group) => ({
                group,
                memberships: meMemberships.filter(
                    (member: any) =>
                        member.group_id == group.group_id && member.active
                ),
            }))
            .filter((data: any) => data.memberships.length > 0);
    }
);

/**
 * selector para recuperar los grupos activos con membrecias del usuario autenticado
 */
export const selectMeGroupsWithMemberships = createSelector(
    [selectAllMembreships, selectAllgroups],
    (meMemberships: any, allGroups) => {
        return allGroups
            .map((group) => ({
                group,
                memberships: meMemberships.filter(
                    (member: any) =>
                        member.group_id == group.group_id && member.active
                ),
            }))
            .filter((data: any) => data.memberships.length > 0);
    }
);

/**
 * selector para recuperar los grupos activos del usuario autenticado para la creacion incidentes
 */
export const selectMeGroupsForIncidents = createSelector(
    [selectMeGroupsWithMembership],
    (groupMemberships: any) => {
        return groupMemberships
            .filter(
                ({ memberships }: any) =>
                    memberships.filter(
                        (member: any) =>
                            member.type == MEMBERSHIPS.GUEST ||
                            member.type == MEMBERSHIPS.ADMINISTRATOR
                    ).length > 0
            )
            .map((groupsMembership: any) => groupsMembership.group);
    }
);

/**
 * Selector para recuperar los grupos del usuario autenticado para administrar
 */
export const selectMeGroupsForManage = createSelector(
    [selectMeGroupsWithMembership],
    (groupMemberships: any) => {
        return groupMemberships
            .filter(({ memberships }: any) =>
                memberships.filter(
                    (member: any) => member.type == MEMBERSHIPS.CREATOR
                )
            )
            .map((groupsMembership: any) => groupsMembership.group);
    }
);

/**
 * Selector para recuperar todos los grupos del usuario autenticado
 */
export const selectMeGroups = createSelector(
    [selectMeGroupsWithMembership],
    (groupMemberships: any) => {
        return groupMemberships.map(
            (groupsMembership: any) => groupsMembership.group
        );
    }
);

/**
 * selector que determina si el usuario autenticado cuanta con algun grupo activo
 */
export const selectHaveIGroups = createSelector(
    selectMeGroups,
    (meGroups) => meGroups.length > 0
);

/**
 * Selector para determinar si el usuario autenticado tiene grupos activos
 */
export const selectHaveGroupsForIncidents = createSelector(
    selectMeGroupsForIncidents,
    (groups) => groups.length > 0
);
