import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";


/**
 * create adapter for the municipalities entity
 */
const municipalitiesAdapter = createEntityAdapter<any>({
    selectId: (municipalities) => municipalities.municipalities_id,
    sortComparer: (municipalitiesA, municipalitiesB) => municipalitiesA.municipalities_id - municipalitiesB.municipalities_id,
})

/**
 * municipalitiess slice definitions
 */
export const municipalitiessSlice = createSlice({
    name: "municipalitiess",
    initialState: municipalitiesAdapter.getInitialState(),
    reducers: {
        addManymunicipalitiess: municipalitiesAdapter.addMany,
        addOnemunicipalities: municipalitiesAdapter.addOne,
        upsertOnemunicipalities: municipalitiesAdapter.upsertOne,
        upsertManymunicipalitiess: municipalitiesAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return municipalitiesAdapter.getInitialState()
        })
    }
})

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManymunicipalitiess, addOnemunicipalities, upsertOnemunicipalities, upsertManymunicipalitiess
} = municipalitiessSlice.actions

/**
 * export reducer autegenerated by toolkit
 */
export default municipalitiessSlice.reducer

//////////////////// SELECTORES //////////////////

const globalizedSelectors = municipalitiesAdapter.getSelectors((state: any) => state.entities.settings)

/** 
 * select for recover all municipalitiess entities
 */
export const selectmunicipalitiessEntities = (state: any) => globalizedSelectors.selectEntities(state)

/**
 * select for recover all municipalitiess 
 */
export const selectAllmunicipalities = (state: any) => globalizedSelectors.selectAll(state)

/**
 * select for recover one municipalities by id
 */
export const selectmunicipalitiesById = (id: any) => (state :any ) => globalizedSelectors.selectById(state, id)
