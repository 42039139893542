import { createSelector } from "@reduxjs/toolkit";
import {
    selectAllIncidents,
    selectIncidentsWithCatalog,
} from "../../../../redux/slices/entities/incidents";
import { selectAllUsers } from "../../../../redux/slices/entities/users";
import { selectAuthenticatedUserId } from "../../../../redux/slices/auth";
import { selectAllnotices } from "../../../../redux/slices/entities/notices";
import { selectAllNotifications } from "../../../../redux/slices/entities/notifications";
import { DateTime } from "luxon";

const selectMeIncidentsWithCatalog = createSelector(
    selectAuthenticatedUserId,
    selectIncidentsWithCatalog,
    (userId, incidentsWithCatalog) => {
        return incidentsWithCatalog.filter((incidentWithCatalog) => {
            return incidentWithCatalog.incident;
        });
    }
);
/**
 * Selector para la vista
 */
export const selectViewModel = createSelector(
    selectIncidentsWithCatalog,
    selectAllnotices,
    selectAllNotifications,
    (incidentsWithCatalog, allNotices, allNotifications) => {
        const myNews = allNotices
            .map((notice) => {
                const notification = allNotifications.find(
                    (notification) =>
                        notification.notice_id == notice.notice_id
                );

                const incidentCatalog = incidentsWithCatalog.find(
                    (incidentCatalog: any) =>
                        incidentCatalog.incident.notice_id == notice.notice_id
                );
                return {
                    notice,
                    notification,
                    ...incidentCatalog,
                };
            })
            .sort((a: any, b: any) => {
                let aDate = DateTime.fromSQL(a.notice.created_at);
                let bDate = DateTime.fromSQL(b.notice.created_at);

                return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
            });

        return myNews;
    }
);

/**
 * Selector para recuperar el total de noticias no leidas
 */
export const selectTotalNotAwareNotices = createSelector(
    selectViewModel, 
    (allNotices) => allNotices.filter(
        (item) => item.notification.aware === false
    ).length
)
