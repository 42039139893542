import { IonIcon, IonItem, IonLabel, IonNote, IonText } from "@ionic/react"
import React from "react"
import { MEMBERSHIPS } from "../../../../../../../constants"
import userProfile from '../../../../../../../assets/icons/user-profile.svg'
import { ellipsisVertical } from 'ionicons/icons';
import removeMembership from "./../../../../../../../assets/icons/remove-membership.svg"

const MemberGroupItem: React.FC<any> = ({data, onChangeMembership, me, showMembershipOptions, onRemoveMeMembership}) => {
   
    let {user, membership} = data

    const onClickOptions = () => {
        if (me) {
            onRemoveMeMembership(data)
        } else {
            onChangeMembership(data)
        }
    }

    return (
        <IonItem detail={false} lines={'full'} className='members-item' >
            {
                me && (
                    <IonIcon
                        className="incident-icon-bottom-sheet"
                        aria-hidden="true"
                        src={userProfile}
                        slot="start"
                    ></IonIcon>
                )
            }

            <IonLabel className='user-name'>
                <IonNote color="medium" className="ion-text-wrap">
                    {
                        membership.type == MEMBERSHIPS.GUEST && "Invitado"
                    }
                    {
                        membership.type == MEMBERSHIPS.ADMINISTRATOR && "Administrador"
                    }
                    {
                        membership.type == MEMBERSHIPS.CREATOR && "Creador"
                    }
                </IonNote>

                
                <br />
                <IonText>{user.name} {user.last_name}</IonText>
                <br />
                <IonNote color="medium" className="ion-text-wrap">
                    {user.cellphone}
                </IonNote>
            </IonLabel>
            {
                showMembershipOptions && (
                    <IonIcon
                        onClick={onClickOptions}
                        className="point"
                        slot="end"
                        src={me ? removeMembership : ellipsisVertical}
                    ></IonIcon>
                )
            }
        </IonItem>
    )
}


export default MemberGroupItem