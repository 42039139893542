import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { selectMembershipsByGroup } from "../memberships";


/**
 * create adapter for the states entity
 */
const usersAdapter = createEntityAdapter<any>({
    selectId: (user) => user.user_id,
    sortComparer: (userA, userB) => userA.user_id - userB.user_id,
})

/**
 * states slice definitions
 */
export const statesSlice = createSlice({
    name: "users",
    initialState: usersAdapter.getInitialState(),
    reducers: {
        addManyUsers: usersAdapter.addMany,
        addOneUser: usersAdapter.addOne,
        upsertOneUser: usersAdapter.upsertOne,
        upsertManyUsers: usersAdapter.upsertMany,
        setAllUsers: usersAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addCase("app/clear", (state, action) => {
            return usersAdapter.getInitialState()
        })
    }
})

/**
 * reducer actions substraid from create reducer
 */
export const {
    addManyUsers, addOneUser, upsertOneUser, upsertManyUsers, setAllUsers
} = statesSlice.actions

/**
 * export reducer autegenerated by toolkit
 */
export default statesSlice.reducer

//////////////////// SELECTORES //////////////////

const globalizedSelectors = usersAdapter.getSelectors((state: any) => state.entities.users)

/** 
 * select for recover all users entities
 */
export const selectUsersEntities = (state: any) => globalizedSelectors.selectEntities(state)

/**
 * select for recover all users 
 */
export const selectAllUsers = (state: any) => globalizedSelectors.selectAll(state)

/**
 * select for recover one user by id
 */
export const selectUsersById = (id: any) => (state :any ) => globalizedSelectors.selectById(state, id)
